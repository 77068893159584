@import "../../assets/scss/variables.scss";

.Progress {
  .progress {
    width: 100%;
    height: 8px;
    background-color: $primary-bg;
    border-radius: 99px;
  }

  .fill-line {
    height: 8px;
    background-color: $primary-main;
    border-radius: 99px;
  }

  .progress-text {
    margin-top: 8px;
  }
}

.desktop-progress {
  position: absolute;
  bottom: 20px;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-progress {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .progress-text {
        font-size: 12px;
        font-weight: 500;
    }

    .progress {
        border: 1px solid $neutral-50;
    }
}
