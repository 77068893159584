// Neutral colors
$neutral-100: #0a0a0a;
$neutral-90: #424242;
$neutral-80: #616161;
$neutral-70: #757575;
$neutral-60: #9e9e9e;
$neutral-50: #c2c2c2;
$neutral-40: #e0e0e0;
$neutral-30: #ededed;
$neutral-20: #f5f5f5;
$neutral-10: #ffffff;

// Primary colors
$primary-main: #5e47d2;
$primary-bg: #f7f5ff;
$primary-pressed: #220f80;
$primary-hover: #694df9;
$primary-focus: #e2dcff;
$primary-border: #bcb0f5;

// Success colors
$success-main: #21725e;
$success-bg: #e7faf5;
$success-pressed: #164c3f;
$success-hover: #1b5f4e;
$success-focus: #c8ebe2;
$success-border: #b5d0c9;

// Warning colors
$warning-main: #e0ce2c;
$warning-bg: #fefadc;
$warning-pressed: #95891d;
$warning-hover: #bbac25;
$warning-focus: #fdf8cf;
$warning-border: #f5efb9;

// Danger colors
$danger-main: #a82525;
$danger-bg: #ffe8e8;
$danger-pressed: #541212;
$danger-hover: #8c1f1f;
$danger-focus: #ffdfdf;
$danger-border: #e2b6b6;

// Info colors
$info-main: #0023dd;
$info-bg: #ebeeff;
$info-pressed: #001793;
$info-hover: #001db8;
$info-focus: #e2e7ff;
$info-border: #aab6f4;

// Shadows
$shadow-8: 0px 8px 16px rgba(0, 0, 0, 0.08);
$shadow-6: 0px 6px 12px rgba(0, 0, 0, 0.1);
$shadow-4: 0px 4px 8px rgba(0, 0, 0, 0.1);
$shadow-2: 0px 2px 4px rgba(0, 0, 0, 0.12);
