@import "../../assets/scss/variables.scss";

.SelectTemplate {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #0a0a0a7e;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    width: fit-content;
    background-color: $neutral-10;
    padding: 30px;
    min-height: auto;
    display: flex;
    justify-content: space-around;
    gap: 20px;
    box-shadow: $shadow-8;

    @media (max-width: 1279px) {
        width: 90%;
      }
  }

  /* HIDE RADIO */
  [type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* IMAGE STYLES */
  [type="radio"] + img {
    cursor: pointer;
  }

  /* CHECKED STYLES */
  [type="radio"]:checked + img {
    outline: 4px solid $info-main;
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  span {
    display: block;
    margin-bottom: 15px;
    color: $neutral-100;
  }
}
