@font-face {
	font-family: 'Phosphor';
	src: url('../fonts/Phosphor.woff2') format('woff2'),
		url('../fonts/Phosphor.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

/*------------------------
	icons
-------------------------*/

.ph-activity-thin::before {
	content: '\ea02';
}

.ph-address-book-thin::before {
	content: '\ea03';
}

.ph-airplane-in-flight-thin::before {
	content: '\ea04';
}

.ph-airplane-landing-thin::before {
	content: '\ea05';
}

.ph-airplane-takeoff-thin::before {
	content: '\ea06';
}

.ph-airplane-thin::before {
	content: '\ea07';
}

.ph-airplane-tilt-thin::before {
	content: '\ea08';
}

.ph-airplay-thin::before {
	content: '\ea09';
}

.ph-alarm-thin::before {
	content: '\ea0a';
}

.ph-alien-thin::before {
	content: '\ea0b';
}

.ph-align-bottom-simple-thin::before {
	content: '\ea0c';
}

.ph-align-bottom-thin::before {
	content: '\ea0d';
}

.ph-align-center-horizontal-simple-thin::before {
	content: '\ea0e';
}

.ph-align-center-horizontal-thin::before {
	content: '\ea0f';
}

.ph-align-center-vertical-simple-thin::before {
	content: '\ea10';
}

.ph-align-center-vertical-thin::before {
	content: '\ea11';
}

.ph-align-left-simple-thin::before {
	content: '\ea12';
}

.ph-align-left-thin::before {
	content: '\ea13';
}

.ph-align-right-simple-thin::before {
	content: '\ea14';
}

.ph-align-right-thin::before {
	content: '\ea15';
}

.ph-align-top-simple-thin::before {
	content: '\ea16';
}

.ph-align-top-thin::before {
	content: '\ea17';
}

.ph-anchor-simple-thin::before {
	content: '\ea18';
}

.ph-anchor-thin::before {
	content: '\ea19';
}

.ph-android-logo-thin::before {
	content: '\ea1a';
}

.ph-angular-logo-thin::before {
	content: '\ea1b';
}

.ph-aperture-thin::before {
	content: '\ea1c';
}

.ph-app-store-logo-thin::before {
	content: '\ea1d';
}

.ph-app-window-thin::before {
	content: '\ea1e';
}

.ph-apple-logo-thin::before {
	content: '\ea1f';
}

.ph-apple-podcasts-logo-thin::before {
	content: '\ea20';
}

.ph-archive-box-thin::before {
	content: '\ea21';
}

.ph-archive-thin::before {
	content: '\ea22';
}

.ph-archive-tray-thin::before {
	content: '\ea23';
}

.ph-armchair-thin::before {
	content: '\ea24';
}

.ph-arrow-arc-left-thin::before {
	content: '\ea25';
}

.ph-arrow-arc-right-thin::before {
	content: '\ea26';
}

.ph-arrow-bend-double-up-left-thin::before {
	content: '\ea27';
}

.ph-arrow-bend-double-up-right-thin::before {
	content: '\ea28';
}

.ph-arrow-bend-down-left-thin::before {
	content: '\ea29';
}

.ph-arrow-bend-down-right-thin::before {
	content: '\ea2a';
}

.ph-arrow-bend-left-down-thin::before {
	content: '\ea2b';
}

.ph-arrow-bend-left-up-thin::before {
	content: '\ea2c';
}

.ph-arrow-bend-right-down-thin::before {
	content: '\ea2d';
}

.ph-arrow-bend-right-up-thin::before {
	content: '\ea2e';
}

.ph-arrow-bend-up-left-thin::before {
	content: '\ea2f';
}

.ph-arrow-bend-up-right-thin::before {
	content: '\ea30';
}

.ph-arrow-circle-down-left-thin::before {
	content: '\ea31';
}

.ph-arrow-circle-down-right-thin::before {
	content: '\ea32';
}

.ph-arrow-circle-down-thin::before {
	content: '\ea33';
}

.ph-arrow-circle-left-thin::before {
	content: '\ea34';
}

.ph-arrow-circle-right-thin::before {
	content: '\ea35';
}

.ph-arrow-circle-up-left-thin::before {
	content: '\ea36';
}

.ph-arrow-circle-up-right-thin::before {
	content: '\ea37';
}

.ph-arrow-circle-up-thin::before {
	content: '\ea38';
}

.ph-arrow-clockwise-thin::before {
	content: '\ea39';
}

.ph-arrow-counter-clockwise-thin::before {
	content: '\ea3a';
}

.ph-arrow-down-left-thin::before {
	content: '\ea3b';
}

.ph-arrow-down-right-thin::before {
	content: '\ea3c';
}

.ph-arrow-down-thin::before {
	content: '\ea3d';
}

.ph-arrow-elbow-down-left-thin::before {
	content: '\ea3e';
}

.ph-arrow-elbow-down-right-thin::before {
	content: '\ea3f';
}

.ph-arrow-elbow-left-down-thin::before {
	content: '\ea40';
}

.ph-arrow-elbow-left-thin::before {
	content: '\ea41';
}

.ph-arrow-elbow-left-up-thin::before {
	content: '\ea42';
}

.ph-arrow-elbow-right-down-thin::before {
	content: '\ea43';
}

.ph-arrow-elbow-right-thin::before {
	content: '\ea44';
}

.ph-arrow-elbow-right-up-thin::before {
	content: '\ea45';
}

.ph-arrow-elbow-up-left-thin::before {
	content: '\ea46';
}

.ph-arrow-elbow-up-right-thin::before {
	content: '\ea47';
}

.ph-arrow-fat-down-thin::before {
	content: '\ea48';
}

.ph-arrow-fat-left-thin::before {
	content: '\ea49';
}

.ph-arrow-fat-line-down-thin::before {
	content: '\ea4a';
}

.ph-arrow-fat-line-left-thin::before {
	content: '\ea4b';
}

.ph-arrow-fat-line-right-thin::before {
	content: '\ea4c';
}

.ph-arrow-fat-line-up-thin::before {
	content: '\ea4d';
}

.ph-arrow-fat-lines-down-thin::before {
	content: '\ea4e';
}

.ph-arrow-fat-lines-left-thin::before {
	content: '\ea4f';
}

.ph-arrow-fat-lines-right-thin::before {
	content: '\ea50';
}

.ph-arrow-fat-lines-up-thin::before {
	content: '\ea51';
}

.ph-arrow-fat-right-thin::before {
	content: '\ea52';
}

.ph-arrow-fat-up-thin::before {
	content: '\ea53';
}

.ph-arrow-left-thin::before {
	content: '\ea54';
}

.ph-arrow-line-down-left-thin::before {
	content: '\ea55';
}

.ph-arrow-line-down-right-thin::before {
	content: '\ea56';
}

.ph-arrow-line-down-thin::before {
	content: '\ea57';
}

.ph-arrow-line-left-thin::before {
	content: '\ea58';
}

.ph-arrow-line-right-thin::before {
	content: '\ea59';
}

.ph-arrow-line-up-left-thin::before {
	content: '\ea5a';
}

.ph-arrow-line-up-right-thin::before {
	content: '\ea5b';
}

.ph-arrow-line-up-thin::before {
	content: '\ea5c';
}

.ph-arrow-right-thin::before {
	content: '\ea5d';
}

.ph-arrow-square-down-left-thin::before {
	content: '\ea5e';
}

.ph-arrow-square-down-right-thin::before {
	content: '\ea5f';
}

.ph-arrow-square-down-thin::before {
	content: '\ea60';
}

.ph-arrow-square-in-thin::before {
	content: '\ea61';
}

.ph-arrow-square-left-thin::before {
	content: '\ea62';
}

.ph-arrow-square-out-thin::before {
	content: '\ea63';
}

.ph-arrow-square-right-thin::before {
	content: '\ea64';
}

.ph-arrow-square-up-left-thin::before {
	content: '\ea65';
}

.ph-arrow-square-up-right-thin::before {
	content: '\ea66';
}

.ph-arrow-square-up-thin::before {
	content: '\ea67';
}

.ph-arrow-u-down-left-thin::before {
	content: '\ea68';
}

.ph-arrow-u-down-right-thin::before {
	content: '\ea69';
}

.ph-arrow-u-left-down-thin::before {
	content: '\ea6a';
}

.ph-arrow-u-left-up-thin::before {
	content: '\ea6b';
}

.ph-arrow-u-right-down-thin::before {
	content: '\ea6c';
}

.ph-arrow-u-right-up-thin::before {
	content: '\ea6d';
}

.ph-arrow-u-up-left-thin::before {
	content: '\ea6e';
}

.ph-arrow-u-up-right-thin::before {
	content: '\ea6f';
}

.ph-arrow-up-left-thin::before {
	content: '\ea70';
}

.ph-arrow-up-right-thin::before {
	content: '\ea71';
}

.ph-arrow-up-thin::before {
	content: '\ea72';
}

.ph-arrows-clockwise-thin::before {
	content: '\ea73';
}

.ph-arrows-counter-clockwise-thin::before {
	content: '\ea74';
}

.ph-arrows-down-up-thin::before {
	content: '\ea75';
}

.ph-arrows-horizontal-thin::before {
	content: '\ea76';
}

.ph-arrows-in-cardinal-thin::before {
	content: '\ea77';
}

.ph-arrows-in-line-horizontal-thin::before {
	content: '\ea78';
}

.ph-arrows-in-line-vertical-thin::before {
	content: '\ea79';
}

.ph-arrows-in-simple-thin::before {
	content: '\ea7a';
}

.ph-arrows-in-thin::before {
	content: '\ea7b';
}

.ph-arrows-left-right-thin::before {
	content: '\ea7c';
}

.ph-arrows-out-cardinal-thin::before {
	content: '\ea7d';
}

.ph-arrows-out-line-horizontal-thin::before {
	content: '\ea7e';
}

.ph-arrows-out-line-vertical-thin::before {
	content: '\ea7f';
}

.ph-arrows-out-simple-thin::before {
	content: '\ea80';
}

.ph-arrows-out-thin::before {
	content: '\ea81';
}

.ph-arrows-vertical-thin::before {
	content: '\ea82';
}

.ph-article-medium-thin::before {
	content: '\ea83';
}

.ph-article-ny-times-thin::before {
	content: '\ea84';
}

.ph-article-thin::before {
	content: '\ea85';
}

.ph-asterisk-simple-thin::before {
	content: '\ea86';
}

.ph-asterisk-thin::before {
	content: '\ea87';
}

.ph-at-thin::before {
	content: '\ea88';
}

.ph-atom-thin::before {
	content: '\ea89';
}

.ph-baby-thin::before {
	content: '\ea8a';
}

.ph-backpack-thin::before {
	content: '\ea8b';
}

.ph-backspace-thin::before {
	content: '\ea8c';
}

.ph-bag-simple-thin::before {
	content: '\ea8d';
}

.ph-bag-thin::before {
	content: '\ea8e';
}

.ph-balloon-thin::before {
	content: '\ea8f';
}

.ph-bandaids-thin::before {
	content: '\ea90';
}

.ph-bank-thin::before {
	content: '\ea91';
}

.ph-barbell-thin::before {
	content: '\ea92';
}

.ph-barcode-thin::before {
	content: '\ea93';
}

.ph-barricade-thin::before {
	content: '\ea94';
}

.ph-baseball-thin::before {
	content: '\ea95';
}

.ph-basketball-thin::before {
	content: '\ea96';
}

.ph-bathtub-thin::before {
	content: '\ea97';
}

.ph-battery-charging-thin::before {
	content: '\ea98';
}

.ph-battery-charging-vertical-thin::before {
	content: '\ea99';
}

.ph-battery-empty-thin::before {
	content: '\ea9a';
}

.ph-battery-full-thin::before {
	content: '\ea9b';
}

.ph-battery-high-thin::before {
	content: '\ea9c';
}

.ph-battery-low-thin::before {
	content: '\ea9d';
}

.ph-battery-medium-thin::before {
	content: '\ea9e';
}

.ph-battery-plus-thin::before {
	content: '\ea9f';
}

.ph-battery-warning-thin::before {
	content: '\eaa0';
}

.ph-battery-warning-vertical-thin::before {
	content: '\eaa1';
}

.ph-bed-thin::before {
	content: '\eaa2';
}

.ph-beer-bottle-thin::before {
	content: '\eaa3';
}

.ph-behance-logo-thin::before {
	content: '\eaa4';
}

.ph-bell-ringing-thin::before {
	content: '\eaa5';
}

.ph-bell-simple-ringing-thin::before {
	content: '\eaa6';
}

.ph-bell-simple-slash-thin::before {
	content: '\eaa7';
}

.ph-bell-simple-thin::before {
	content: '\eaa8';
}

.ph-bell-simple-z-thin::before {
	content: '\eaa9';
}

.ph-bell-slash-thin::before {
	content: '\eaaa';
}

.ph-bell-thin::before {
	content: '\eaab';
}

.ph-bell-z-thin::before {
	content: '\eaac';
}

.ph-bezier-curve-thin::before {
	content: '\eaad';
}

.ph-bicycle-thin::before {
	content: '\eaae';
}

.ph-binoculars-thin::before {
	content: '\eaaf';
}

.ph-bird-thin::before {
	content: '\eab0';
}

.ph-bluetooth-connected-thin::before {
	content: '\eab1';
}

.ph-bluetooth-slash-thin::before {
	content: '\eab2';
}

.ph-bluetooth-thin::before {
	content: '\eab3';
}

.ph-bluetooth-x-thin::before {
	content: '\eab4';
}

.ph-boat-thin::before {
	content: '\eab5';
}

.ph-book-bookmark-thin::before {
	content: '\eab6';
}

.ph-book-open-thin::before {
	content: '\eab7';
}

.ph-book-thin::before {
	content: '\eab8';
}

.ph-bookmark-simple-thin::before {
	content: '\eab9';
}

.ph-bookmark-thin::before {
	content: '\eaba';
}

.ph-bookmarks-simple-thin::before {
	content: '\eabb';
}

.ph-bookmarks-thin::before {
	content: '\eabc';
}

.ph-books-thin::before {
	content: '\eabd';
}

.ph-bounding-box-thin::before {
	content: '\eabe';
}

.ph-brackets-angle-thin::before {
	content: '\eabf';
}

.ph-brackets-curly-thin::before {
	content: '\eac0';
}

.ph-brackets-round-thin::before {
	content: '\eac1';
}

.ph-brackets-square-thin::before {
	content: '\eac2';
}

.ph-brain-thin::before {
	content: '\eac3';
}

.ph-brandy-thin::before {
	content: '\eac4';
}

.ph-briefcase-metal-thin::before {
	content: '\eac5';
}

.ph-briefcase-thin::before {
	content: '\eac6';
}

.ph-broadcast-thin::before {
	content: '\eac7';
}

.ph-browser-thin::before {
	content: '\eac8';
}

.ph-browsers-thin::before {
	content: '\eac9';
}

.ph-bug-beetle-thin::before {
	content: '\eaca';
}

.ph-bug-droid-thin::before {
	content: '\eacb';
}

.ph-bug-thin::before {
	content: '\eacc';
}

.ph-buildings-thin::before {
	content: '\eacd';
}

.ph-bus-thin::before {
	content: '\eace';
}

.ph-butterfly-thin::before {
	content: '\eacf';
}

.ph-cactus-thin::before {
	content: '\ead0';
}

.ph-cake-thin::before {
	content: '\ead1';
}

.ph-calculator-thin::before {
	content: '\ead2';
}

.ph-calendar-blank-thin::before {
	content: '\ead3';
}

.ph-calendar-check-thin::before {
	content: '\ead4';
}

.ph-calendar-plus-thin::before {
	content: '\ead5';
}

.ph-calendar-thin::before {
	content: '\ead6';
}

.ph-calendar-x-thin::before {
	content: '\ead7';
}

.ph-camera-rotate-thin::before {
	content: '\ead8';
}

.ph-camera-slash-thin::before {
	content: '\ead9';
}

.ph-camera-thin::before {
	content: '\eada';
}

.ph-campfire-thin::before {
	content: '\eadb';
}

.ph-car-simple-thin::before {
	content: '\eadc';
}

.ph-car-thin::before {
	content: '\eadd';
}

.ph-cardholder-thin::before {
	content: '\eade';
}

.ph-cards-thin::before {
	content: '\eadf';
}

.ph-caret-circle-double-down-thin::before {
	content: '\eae0';
}

.ph-caret-circle-double-left-thin::before {
	content: '\eae1';
}

.ph-caret-circle-double-right-thin::before {
	content: '\eae2';
}

.ph-caret-circle-double-up-thin::before {
	content: '\eae3';
}

.ph-caret-circle-down-thin::before {
	content: '\eae4';
}

.ph-caret-circle-left-thin::before {
	content: '\eae5';
}

.ph-caret-circle-right-thin::before {
	content: '\eae6';
}

.ph-caret-circle-up-thin::before {
	content: '\eae7';
}

.ph-caret-double-down-thin::before {
	content: '\eae8';
}

.ph-caret-double-left-thin::before {
	content: '\eae9';
}

.ph-caret-double-right-thin::before {
	content: '\eaea';
}

.ph-caret-double-up-thin::before {
	content: '\eaeb';
}

.ph-caret-down-thin::before {
	content: '\eaec';
}

.ph-caret-left-thin::before {
	content: '\eaed';
}

.ph-caret-right-thin::before {
	content: '\eaee';
}

.ph-caret-up-thin::before {
	content: '\eaef';
}

.ph-cat-thin::before {
	content: '\eaf0';
}

.ph-cell-signal-full-thin::before {
	content: '\eaf1';
}

.ph-cell-signal-high-thin::before {
	content: '\eaf2';
}

.ph-cell-signal-low-thin::before {
	content: '\eaf3';
}

.ph-cell-signal-medium-thin::before {
	content: '\eaf4';
}

.ph-cell-signal-none-thin::before {
	content: '\eaf5';
}

.ph-cell-signal-slash-thin::before {
	content: '\eaf6';
}

.ph-cell-signal-x-thin::before {
	content: '\eaf7';
}

.ph-chalkboard-simple-thin::before {
	content: '\eaf8';
}

.ph-chalkboard-teacher-thin::before {
	content: '\eaf9';
}

.ph-chalkboard-thin::before {
	content: '\eafa';
}

.ph-chart-bar-horizontal-thin::before {
	content: '\eafb';
}

.ph-chart-bar-thin::before {
	content: '\eafc';
}

.ph-chart-line-thin::before {
	content: '\eafd';
}

.ph-chart-line-up-thin::before {
	content: '\eafe';
}

.ph-chart-pie-slice-thin::before {
	content: '\eaff';
}

.ph-chart-pie-thin::before {
	content: '\eb00';
}

.ph-chat-centered-dots-thin::before {
	content: '\eb01';
}

.ph-chat-centered-text-thin::before {
	content: '\eb02';
}

.ph-chat-centered-thin::before {
	content: '\eb03';
}

.ph-chat-circle-dots-thin::before {
	content: '\eb04';
}

.ph-chat-circle-text-thin::before {
	content: '\eb05';
}

.ph-chat-circle-thin::before {
	content: '\eb06';
}

.ph-chat-dots-thin::before {
	content: '\eb07';
}

.ph-chat-teardrop-dots-thin::before {
	content: '\eb08';
}

.ph-chat-teardrop-text-thin::before {
	content: '\eb09';
}

.ph-chat-teardrop-thin::before {
	content: '\eb0a';
}

.ph-chat-text-thin::before {
	content: '\eb0b';
}

.ph-chat-thin::before {
	content: '\eb0c';
}

.ph-chats-circle-thin::before {
	content: '\eb0d';
}

.ph-chats-teardrop-thin::before {
	content: '\eb0e';
}

.ph-chats-thin::before {
	content: '\eb0f';
}

.ph-check-circle-thin::before {
	content: '\eb10';
}

.ph-check-square-offset-thin::before {
	content: '\eb11';
}

.ph-check-square-thin::before {
	content: '\eb12';
}

.ph-check-thin::before {
	content: '\eb13';
}

.ph-checks-thin::before {
	content: '\eb14';
}

.ph-circle-dashed-thin::before {
	content: '\eb15';
}

.ph-circle-half-thin::before {
	content: '\eb16';
}

.ph-circle-half-tilt-thin::before {
	content: '\eb17';
}

.ph-circle-notch-thin::before {
	content: '\eb18';
}

.ph-circle-thin::before {
	content: '\eb19';
}

.ph-circle-wavy-check-thin::before {
	content: '\eb1a';
}

.ph-circle-wavy-question-thin::before {
	content: '\eb1b';
}

.ph-circle-wavy-thin::before {
	content: '\eb1c';
}

.ph-circle-wavy-warning-thin::before {
	content: '\eb1d';
}

.ph-circles-four-thin::before {
	content: '\eb1e';
}

.ph-circles-three-plus-thin::before {
	content: '\eb1f';
}

.ph-circles-three-thin::before {
	content: '\eb20';
}

.ph-clipboard-text-thin::before {
	content: '\eb21';
}

.ph-clipboard-thin::before {
	content: '\eb22';
}

.ph-clock-afternoon-thin::before {
	content: '\eb23';
}

.ph-clock-clockwise-thin::before {
	content: '\eb24';
}

.ph-clock-counter-clockwise-thin::before {
	content: '\eb25';
}

.ph-clock-thin::before {
	content: '\eb26';
}

.ph-closed-captioning-thin::before {
	content: '\eb27';
}

.ph-cloud-arrow-down-thin::before {
	content: '\eb28';
}

.ph-cloud-arrow-up-thin::before {
	content: '\eb29';
}

.ph-cloud-check-thin::before {
	content: '\eb2a';
}

.ph-cloud-fog-thin::before {
	content: '\eb2b';
}

.ph-cloud-lightning-thin::before {
	content: '\eb2c';
}

.ph-cloud-moon-thin::before {
	content: '\eb2d';
}

.ph-cloud-rain-thin::before {
	content: '\eb2e';
}

.ph-cloud-slash-thin::before {
	content: '\eb2f';
}

.ph-cloud-snow-thin::before {
	content: '\eb30';
}

.ph-cloud-sun-thin::before {
	content: '\eb31';
}

.ph-cloud-thin::before {
	content: '\eb32';
}

.ph-club-thin::before {
	content: '\eb33';
}

.ph-coat-hanger-thin::before {
	content: '\eb34';
}

.ph-code-simple-thin::before {
	content: '\eb35';
}

.ph-code-thin::before {
	content: '\eb36';
}

.ph-codepen-logo-thin::before {
	content: '\eb37';
}

.ph-codesandbox-logo-thin::before {
	content: '\eb38';
}

.ph-coffee-thin::before {
	content: '\eb39';
}

.ph-coin-thin::before {
	content: '\eb3a';
}

.ph-coin-vertical-thin::before {
	content: '\eb3b';
}

.ph-coins-thin::before {
	content: '\eb3c';
}

.ph-columns-thin::before {
	content: '\eb3d';
}

.ph-command-thin::before {
	content: '\eb3e';
}

.ph-compass-thin::before {
	content: '\eb3f';
}

.ph-computer-tower-thin::before {
	content: '\eb40';
}

.ph-confetti-thin::before {
	content: '\eb41';
}

.ph-cookie-thin::before {
	content: '\eb42';
}

.ph-cooking-pot-thin::before {
	content: '\eb43';
}

.ph-copy-simple-thin::before {
	content: '\eb44';
}

.ph-copy-thin::before {
	content: '\eb45';
}

.ph-copyleft-thin::before {
	content: '\eb46';
}

.ph-copyright-thin::before {
	content: '\eb47';
}

.ph-corners-in-thin::before {
	content: '\eb48';
}

.ph-corners-out-thin::before {
	content: '\eb49';
}

.ph-cpu-thin::before {
	content: '\eb4a';
}

.ph-credit-card-thin::before {
	content: '\eb4b';
}

.ph-crop-thin::before {
	content: '\eb4c';
}

.ph-crosshair-simple-thin::before {
	content: '\eb4d';
}

.ph-crosshair-thin::before {
	content: '\eb4e';
}

.ph-crown-simple-thin::before {
	content: '\eb4f';
}

.ph-crown-thin::before {
	content: '\eb50';
}

.ph-cube-thin::before {
	content: '\eb51';
}

.ph-currency-btc-thin::before {
	content: '\eb52';
}

.ph-currency-circle-dollar-thin::before {
	content: '\eb53';
}

.ph-currency-cny-thin::before {
	content: '\eb54';
}

.ph-currency-dollar-simple-thin::before {
	content: '\eb55';
}

.ph-currency-dollar-thin::before {
	content: '\eb56';
}

.ph-currency-eth-thin::before {
	content: '\eb57';
}

.ph-currency-eur-thin::before {
	content: '\eb58';
}

.ph-currency-gbp-thin::before {
	content: '\eb59';
}

.ph-currency-inr-thin::before {
	content: '\eb5a';
}

.ph-currency-jpy-thin::before {
	content: '\eb5b';
}

.ph-currency-krw-thin::before {
	content: '\eb5c';
}

.ph-currency-kzt-thin::before {
	content: '\eb5d';
}

.ph-currency-ngn-thin::before {
	content: '\eb5e';
}

.ph-currency-rub-thin::before {
	content: '\eb5f';
}

.ph-cursor-text-thin::before {
	content: '\eb60';
}

.ph-cursor-thin::before {
	content: '\eb61';
}

.ph-cylinder-thin::before {
	content: '\eb62';
}

.ph-database-thin::before {
	content: '\eb63';
}

.ph-desktop-thin::before {
	content: '\eb64';
}

.ph-desktop-tower-thin::before {
	content: '\eb65';
}

.ph-detective-thin::before {
	content: '\eb66';
}

.ph-device-mobile-camera-thin::before {
	content: '\eb67';
}

.ph-device-mobile-speaker-thin::before {
	content: '\eb68';
}

.ph-device-mobile-thin::before {
	content: '\eb69';
}

.ph-device-tablet-camera-thin::before {
	content: '\eb6a';
}

.ph-device-tablet-speaker-thin::before {
	content: '\eb6b';
}

.ph-device-tablet-thin::before {
	content: '\eb6c';
}

.ph-diamond-thin::before {
	content: '\eb6d';
}

.ph-diamonds-four-thin::before {
	content: '\eb6e';
}

.ph-dice-five-thin::before {
	content: '\eb6f';
}

.ph-dice-four-thin::before {
	content: '\eb70';
}

.ph-dice-one-thin::before {
	content: '\eb71';
}

.ph-dice-six-thin::before {
	content: '\eb72';
}

.ph-dice-three-thin::before {
	content: '\eb73';
}

.ph-dice-two-thin::before {
	content: '\eb74';
}

.ph-disc-thin::before {
	content: '\eb75';
}

.ph-discord-logo-thin::before {
	content: '\eb76';
}

.ph-divide-thin::before {
	content: '\eb77';
}

.ph-dog-thin::before {
	content: '\eb78';
}

.ph-door-thin::before {
	content: '\eb79';
}

.ph-dots-nine-thin::before {
	content: '\eb7a';
}

.ph-dots-six-thin::before {
	content: '\eb7b';
}

.ph-dots-six-vertical-thin::before {
	content: '\eb7c';
}

.ph-dots-three-circle-thin::before {
	content: '\eb7d';
}

.ph-dots-three-circle-vertical-thin::before {
	content: '\eb7e';
}

.ph-dots-three-outline-thin::before {
	content: '\eb7f';
}

.ph-dots-three-outline-vertical-thin::before {
	content: '\eb80';
}

.ph-dots-three-thin::before {
	content: '\eb81';
}

.ph-dots-three-vertical-thin::before {
	content: '\eb82';
}

.ph-download-simple-thin::before {
	content: '\eb83';
}

.ph-download-thin::before {
	content: '\eb84';
}

.ph-dribbble-logo-thin::before {
	content: '\eb85';
}

.ph-drop-half-bottom-thin::before {
	content: '\eb86';
}

.ph-drop-half-thin::before {
	content: '\eb87';
}

.ph-drop-thin::before {
	content: '\eb88';
}

.ph-ear-slash-thin::before {
	content: '\eb89';
}

.ph-ear-thin::before {
	content: '\eb8a';
}

.ph-egg-crack-thin::before {
	content: '\eb8b';
}

.ph-egg-thin::before {
	content: '\eb8c';
}

.ph-eject-simple-thin::before {
	content: '\eb8d';
}

.ph-eject-thin::before {
	content: '\eb8e';
}

.ph-envelope-open-thin::before {
	content: '\eb8f';
}

.ph-envelope-simple-open-thin::before {
	content: '\eb90';
}

.ph-envelope-simple-thin::before {
	content: '\eb91';
}

.ph-envelope-thin::before {
	content: '\eb92';
}

.ph-equalizer-thin::before {
	content: '\eb93';
}

.ph-equals-thin::before {
	content: '\eb94';
}

.ph-eraser-thin::before {
	content: '\eb95';
}

.ph-exam-thin::before {
	content: '\eb96';
}

.ph-export-thin::before {
	content: '\eb97';
}

.ph-eye-closed-thin::before {
	content: '\eb98';
}

.ph-eye-slash-thin::before {
	content: '\eb99';
}

.ph-eye-thin::before {
	content: '\eb9a';
}

.ph-eyedropper-sample-thin::before {
	content: '\eb9b';
}

.ph-eyedropper-thin::before {
	content: '\eb9c';
}

.ph-eyeglasses-thin::before {
	content: '\eb9d';
}

.ph-face-mask-thin::before {
	content: '\eb9e';
}

.ph-facebook-logo-thin::before {
	content: '\eb9f';
}

.ph-factory-thin::before {
	content: '\eba0';
}

.ph-faders-horizontal-thin::before {
	content: '\eba1';
}

.ph-faders-thin::before {
	content: '\eba2';
}

.ph-fast-forward-circle-thin::before {
	content: '\eba3';
}

.ph-fast-forward-thin::before {
	content: '\eba4';
}

.ph-figma-logo-thin::before {
	content: '\eba5';
}

.ph-file-arrow-down-thin::before {
	content: '\eba6';
}

.ph-file-arrow-up-thin::before {
	content: '\eba7';
}

.ph-file-audio-thin::before {
	content: '\eba8';
}

.ph-file-cloud-thin::before {
	content: '\eba9';
}

.ph-file-code-thin::before {
	content: '\ebaa';
}

.ph-file-css-thin::before {
	content: '\ebab';
}

.ph-file-csv-thin::before {
	content: '\ebac';
}

.ph-file-doc-thin::before {
	content: '\ebad';
}

.ph-file-dotted-thin::before {
	content: '\ebae';
}

.ph-file-html-thin::before {
	content: '\ebaf';
}

.ph-file-image-thin::before {
	content: '\ebb0';
}

.ph-file-jpg-thin::before {
	content: '\ebb1';
}

.ph-file-js-thin::before {
	content: '\ebb2';
}

.ph-file-jsx-thin::before {
	content: '\ebb3';
}

.ph-file-lock-thin::before {
	content: '\ebb4';
}

.ph-file-minus-thin::before {
	content: '\ebb5';
}

.ph-file-pdf-thin::before {
	content: '\ebb6';
}

.ph-file-plus-thin::before {
	content: '\ebb7';
}

.ph-file-png-thin::before {
	content: '\ebb8';
}

.ph-file-ppt-thin::before {
	content: '\ebb9';
}

.ph-file-rs-thin::before {
	content: '\ebba';
}

.ph-file-search-thin::before {
	content: '\ebbb';
}

.ph-file-text-thin::before {
	content: '\ebbc';
}

.ph-file-thin::before {
	content: '\ebbd';
}

.ph-file-ts-thin::before {
	content: '\ebbe';
}

.ph-file-tsx-thin::before {
	content: '\ebbf';
}

.ph-file-video-thin::before {
	content: '\ebc0';
}

.ph-file-vue-thin::before {
	content: '\ebc1';
}

.ph-file-x-thin::before {
	content: '\ebc2';
}

.ph-file-xls-thin::before {
	content: '\ebc3';
}

.ph-file-zip-thin::before {
	content: '\ebc4';
}

.ph-files-thin::before {
	content: '\ebc5';
}

.ph-film-script-thin::before {
	content: '\ebc6';
}

.ph-film-slate-thin::before {
	content: '\ebc7';
}

.ph-film-strip-thin::before {
	content: '\ebc8';
}

.ph-fingerprint-simple-thin::before {
	content: '\ebc9';
}

.ph-fingerprint-thin::before {
	content: '\ebca';
}

.ph-finn-the-human-thin::before {
	content: '\ebcb';
}

.ph-fire-simple-thin::before {
	content: '\ebcc';
}

.ph-fire-thin::before {
	content: '\ebcd';
}

.ph-first-aid-kit-thin::before {
	content: '\ebce';
}

.ph-first-aid-thin::before {
	content: '\ebcf';
}

.ph-fish-simple-thin::before {
	content: '\ebd0';
}

.ph-fish-thin::before {
	content: '\ebd1';
}

.ph-flag-banner-thin::before {
	content: '\ebd2';
}

.ph-flag-checkered-thin::before {
	content: '\ebd3';
}

.ph-flag-thin::before {
	content: '\ebd4';
}

.ph-flame-thin::before {
	content: '\ebd5';
}

.ph-flashlight-thin::before {
	content: '\ebd6';
}

.ph-flask-thin::before {
	content: '\ebd7';
}

.ph-floppy-disk-back-thin::before {
	content: '\ebd8';
}

.ph-floppy-disk-thin::before {
	content: '\ebd9';
}

.ph-flow-arrow-thin::before {
	content: '\ebda';
}

.ph-flower-lotus-thin::before {
	content: '\ebdb';
}

.ph-flower-thin::before {
	content: '\ebdc';
}

.ph-flying-saucer-thin::before {
	content: '\ebdd';
}

.ph-folder-dotted-thin::before {
	content: '\ebde';
}

.ph-folder-lock-thin::before {
	content: '\ebdf';
}

.ph-folder-minus-thin::before {
	content: '\ebe0';
}

.ph-folder-notch-minus-thin::before {
	content: '\ebe1';
}

.ph-folder-notch-open-thin::before {
	content: '\ebe2';
}

.ph-folder-notch-plus-thin::before {
	content: '\ebe3';
}

.ph-folder-notch-thin::before {
	content: '\ebe4';
}

.ph-folder-open-thin::before {
	content: '\ebe5';
}

.ph-folder-plus-thin::before {
	content: '\ebe6';
}

.ph-folder-simple-dotted-thin::before {
	content: '\ebe7';
}

.ph-folder-simple-lock-thin::before {
	content: '\ebe8';
}

.ph-folder-simple-minus-thin::before {
	content: '\ebe9';
}

.ph-folder-simple-plus-thin::before {
	content: '\ebea';
}

.ph-folder-simple-star-thin::before {
	content: '\ebeb';
}

.ph-folder-simple-thin::before {
	content: '\ebec';
}

.ph-folder-simple-user-thin::before {
	content: '\ebed';
}

.ph-folder-star-thin::before {
	content: '\ebee';
}

.ph-folder-thin::before {
	content: '\ebef';
}

.ph-folder-user-thin::before {
	content: '\ebf0';
}

.ph-folders-thin::before {
	content: '\ebf1';
}

.ph-football-thin::before {
	content: '\ebf2';
}

.ph-fork-knife-thin::before {
	content: '\ebf3';
}

.ph-frame-corners-thin::before {
	content: '\ebf4';
}

.ph-framer-logo-thin::before {
	content: '\ebf5';
}

.ph-function-thin::before {
	content: '\ebf6';
}

.ph-funnel-simple-thin::before {
	content: '\ebf7';
}

.ph-funnel-thin::before {
	content: '\ebf8';
}

.ph-game-controller-thin::before {
	content: '\ebf9';
}

.ph-gas-pump-thin::before {
	content: '\ebfa';
}

.ph-gauge-thin::before {
	content: '\ebfb';
}

.ph-gear-six-thin::before {
	content: '\ebfc';
}

.ph-gear-thin::before {
	content: '\ebfd';
}

.ph-gender-female-thin::before {
	content: '\ebfe';
}

.ph-gender-intersex-thin::before {
	content: '\ebff';
}

.ph-gender-male-thin::before {
	content: '\ec00';
}

.ph-gender-neuter-thin::before {
	content: '\ec01';
}

.ph-gender-nonbinary-thin::before {
	content: '\ec02';
}

.ph-gender-transgender-thin::before {
	content: '\ec03';
}

.ph-ghost-thin::before {
	content: '\ec04';
}

.ph-gif-thin::before {
	content: '\ec05';
}

.ph-gift-thin::before {
	content: '\ec06';
}

.ph-git-branch-thin::before {
	content: '\ec07';
}

.ph-git-commit-thin::before {
	content: '\ec08';
}

.ph-git-diff-thin::before {
	content: '\ec09';
}

.ph-git-fork-thin::before {
	content: '\ec0a';
}

.ph-git-merge-thin::before {
	content: '\ec0b';
}

.ph-git-pull-request-thin::before {
	content: '\ec0c';
}

.ph-github-logo-thin::before {
	content: '\ec0d';
}

.ph-gitlab-logo-simple-thin::before {
	content: '\ec0e';
}

.ph-gitlab-logo-thin::before {
	content: '\ec0f';
}

.ph-globe-hemisphere-east-thin::before {
	content: '\ec10';
}

.ph-globe-hemisphere-west-thin::before {
	content: '\ec11';
}

.ph-globe-simple-thin::before {
	content: '\ec12';
}

.ph-globe-stand-thin::before {
	content: '\ec13';
}

.ph-globe-thin::before {
	content: '\ec14';
}

.ph-google-chrome-logo-thin::before {
	content: '\ec15';
}

.ph-google-logo-thin::before {
	content: '\ec16';
}

.ph-google-photos-logo-thin::before {
	content: '\ec17';
}

.ph-google-play-logo-thin::before {
	content: '\ec18';
}

.ph-google-podcasts-logo-thin::before {
	content: '\ec19';
}

.ph-gradient-thin::before {
	content: '\ec1a';
}

.ph-graduation-cap-thin::before {
	content: '\ec1b';
}

.ph-graph-thin::before {
	content: '\ec1c';
}

.ph-grid-four-thin::before {
	content: '\ec1d';
}

.ph-hamburger-thin::before {
	content: '\ec1e';
}

.ph-hand-eye-thin::before {
	content: '\ec1f';
}

.ph-hand-fist-thin::before {
	content: '\ec20';
}

.ph-hand-grabbing-thin::before {
	content: '\ec21';
}

.ph-hand-palm-thin::before {
	content: '\ec22';
}

.ph-hand-pointing-thin::before {
	content: '\ec23';
}

.ph-hand-soap-thin::before {
	content: '\ec24';
}

.ph-hand-thin::before {
	content: '\ec25';
}

.ph-hand-waving-thin::before {
	content: '\ec26';
}

.ph-handbag-simple-thin::before {
	content: '\ec27';
}

.ph-handbag-thin::before {
	content: '\ec28';
}

.ph-hands-clapping-thin::before {
	content: '\ec29';
}

.ph-handshake-thin::before {
	content: '\ec2a';
}

.ph-hard-drive-thin::before {
	content: '\ec2b';
}

.ph-hard-drives-thin::before {
	content: '\ec2c';
}

.ph-hash-straight-thin::before {
	content: '\ec2d';
}

.ph-hash-thin::before {
	content: '\ec2e';
}

.ph-headlights-thin::before {
	content: '\ec2f';
}

.ph-headphones-thin::before {
	content: '\ec30';
}

.ph-headset-thin::before {
	content: '\ec31';
}

.ph-heart-break-thin::before {
	content: '\ec32';
}

.ph-heart-straight-break-thin::before {
	content: '\ec33';
}

.ph-heart-straight-thin::before {
	content: '\ec34';
}

.ph-heart-thin::before {
	content: '\ec35';
}

.ph-heartbeat-thin::before {
	content: '\ec36';
}

.ph-hexagon-thin::before {
	content: '\ec37';
}

.ph-highlighter-circle-thin::before {
	content: '\ec38';
}

.ph-horse-thin::before {
	content: '\ec39';
}

.ph-hourglass-high-thin::before {
	content: '\ec3a';
}

.ph-hourglass-low-thin::before {
	content: '\ec3b';
}

.ph-hourglass-medium-thin::before {
	content: '\ec3c';
}

.ph-hourglass-simple-high-thin::before {
	content: '\ec3d';
}

.ph-hourglass-simple-low-thin::before {
	content: '\ec3e';
}

.ph-hourglass-simple-medium-thin::before {
	content: '\ec3f';
}

.ph-hourglass-simple-thin::before {
	content: '\ec40';
}

.ph-hourglass-thin::before {
	content: '\ec41';
}

.ph-house-line-thin::before {
	content: '\ec42';
}

.ph-house-simple-thin::before {
	content: '\ec43';
}

.ph-house-thin::before {
	content: '\ec44';
}

.ph-identification-badge-thin::before {
	content: '\ec45';
}

.ph-identification-card-thin::before {
	content: '\ec46';
}

.ph-image-square-thin::before {
	content: '\ec47';
}

.ph-image-thin::before {
	content: '\ec48';
}

.ph-infinity-thin::before {
	content: '\ec49';
}

.ph-info-thin::before {
	content: '\ec4a';
}

.ph-instagram-logo-thin::before {
	content: '\ec4b';
}

.ph-intersect-thin::before {
	content: '\ec4c';
}

.ph-jeep-thin::before {
	content: '\ec4d';
}

.ph-kanban-thin::before {
	content: '\ec4e';
}

.ph-key-return-thin::before {
	content: '\ec4f';
}

.ph-key-thin::before {
	content: '\ec50';
}

.ph-keyboard-thin::before {
	content: '\ec51';
}

.ph-keyhole-thin::before {
	content: '\ec52';
}

.ph-knife-thin::before {
	content: '\ec53';
}

.ph-ladder-simple-thin::before {
	content: '\ec54';
}

.ph-ladder-thin::before {
	content: '\ec55';
}

.ph-lamp-thin::before {
	content: '\ec56';
}

.ph-laptop-thin::before {
	content: '\ec57';
}

.ph-layout-thin::before {
	content: '\ec58';
}

.ph-leaf-thin::before {
	content: '\ec59';
}

.ph-lifebuoy-thin::before {
	content: '\ec5a';
}

.ph-lightbulb-filament-thin::before {
	content: '\ec5b';
}

.ph-lightbulb-thin::before {
	content: '\ec5c';
}

.ph-lightning-slash-thin::before {
	content: '\ec5d';
}

.ph-lightning-thin::before {
	content: '\ec5e';
}

.ph-line-segment-thin::before {
	content: '\ec5f';
}

.ph-line-segments-thin::before {
	content: '\ec60';
}

.ph-link-break-thin::before {
	content: '\ec61';
}

.ph-link-simple-break-thin::before {
	content: '\ec62';
}

.ph-link-simple-horizontal-break-thin::before {
	content: '\ec63';
}

.ph-link-simple-horizontal-thin::before {
	content: '\ec64';
}

.ph-link-simple-thin::before {
	content: '\ec65';
}

.ph-link-thin::before {
	content: '\ec66';
}

.ph-linkedin-logo-thin::before {
	content: '\ec67';
}

.ph-linux-logo-thin::before {
	content: '\ec68';
}

.ph-list-bullets-thin::before {
	content: '\ec69';
}

.ph-list-checks-thin::before {
	content: '\ec6a';
}

.ph-list-dashes-thin::before {
	content: '\ec6b';
}

.ph-list-numbers-thin::before {
	content: '\ec6c';
}

.ph-list-plus-thin::before {
	content: '\ec6d';
}

.ph-list-thin::before {
	content: '\ec6e';
}

.ph-lock-key-open-thin::before {
	content: '\ec6f';
}

.ph-lock-key-thin::before {
	content: '\ec70';
}

.ph-lock-laminated-open-thin::before {
	content: '\ec71';
}

.ph-lock-laminated-thin::before {
	content: '\ec72';
}

.ph-lock-open-thin::before {
	content: '\ec73';
}

.ph-lock-simple-open-thin::before {
	content: '\ec74';
}

.ph-lock-simple-thin::before {
	content: '\ec75';
}

.ph-lock-thin::before {
	content: '\ec76';
}

.ph-magic-wand-thin::before {
	content: '\ec77';
}

.ph-magnet-straight-thin::before {
	content: '\ec78';
}

.ph-magnet-thin::before {
	content: '\ec79';
}

.ph-magnifying-glass-minus-thin::before {
	content: '\ec7a';
}

.ph-magnifying-glass-plus-thin::before {
	content: '\ec7b';
}

.ph-magnifying-glass-thin::before {
	content: '\ec7c';
}

.ph-map-pin-line-thin::before {
	content: '\ec7d';
}

.ph-map-pin-thin::before {
	content: '\ec7e';
}

.ph-map-trifold-thin::before {
	content: '\ec7f';
}

.ph-marker-circle-thin::before {
	content: '\ec80';
}

.ph-martini-thin::before {
	content: '\ec81';
}

.ph-mask-happy-thin::before {
	content: '\ec82';
}

.ph-mask-sad-thin::before {
	content: '\ec83';
}

.ph-math-operations-thin::before {
	content: '\ec84';
}

.ph-medal-thin::before {
	content: '\ec85';
}

.ph-medium-logo-thin::before {
	content: '\ec86';
}

.ph-megaphone-simple-thin::before {
	content: '\ec87';
}

.ph-megaphone-thin::before {
	content: '\ec88';
}

.ph-messenger-logo-thin::before {
	content: '\ec89';
}

.ph-microphone-slash-thin::before {
	content: '\ec8a';
}

.ph-microphone-stage-thin::before {
	content: '\ec8b';
}

.ph-microphone-thin::before {
	content: '\ec8c';
}

.ph-microsoft-excel-logo-thin::before {
	content: '\ec8d';
}

.ph-microsoft-powerpoint-logo-thin::before {
	content: '\ec8e';
}

.ph-microsoft-teams-logo-thin::before {
	content: '\ec8f';
}

.ph-microsoft-word-logo-thin::before {
	content: '\ec90';
}

.ph-minus-circle-thin::before {
	content: '\ec91';
}

.ph-minus-thin::before {
	content: '\ec92';
}

.ph-money-thin::before {
	content: '\ec93';
}

.ph-monitor-play-thin::before {
	content: '\ec94';
}

.ph-monitor-thin::before {
	content: '\ec95';
}

.ph-moon-stars-thin::before {
	content: '\ec96';
}

.ph-moon-thin::before {
	content: '\ec97';
}

.ph-mountains-thin::before {
	content: '\ec98';
}

.ph-mouse-simple-thin::before {
	content: '\ec99';
}

.ph-mouse-thin::before {
	content: '\ec9a';
}

.ph-music-note-simple-thin::before {
	content: '\ec9b';
}

.ph-music-note-thin::before {
	content: '\ec9c';
}

.ph-music-notes-plus-thin::before {
	content: '\ec9d';
}

.ph-music-notes-simple-thin::before {
	content: '\ec9e';
}

.ph-music-notes-thin::before {
	content: '\ec9f';
}

.ph-navigation-arrow-thin::before {
	content: '\eca0';
}

.ph-needle-thin::before {
	content: '\eca1';
}

.ph-newspaper-clipping-thin::before {
	content: '\eca2';
}

.ph-newspaper-thin::before {
	content: '\eca3';
}

.ph-note-blank-thin::before {
	content: '\eca4';
}

.ph-note-pencil-thin::before {
	content: '\eca5';
}

.ph-note-thin::before {
	content: '\eca6';
}

.ph-notebook-thin::before {
	content: '\eca7';
}

.ph-notepad-thin::before {
	content: '\eca8';
}

.ph-notification-thin::before {
	content: '\eca9';
}

.ph-number-circle-eight-thin::before {
	content: '\ecaa';
}

.ph-number-circle-five-thin::before {
	content: '\ecab';
}

.ph-number-circle-four-thin::before {
	content: '\ecac';
}

.ph-number-circle-nine-thin::before {
	content: '\ecad';
}

.ph-number-circle-one-thin::before {
	content: '\ecae';
}

.ph-number-circle-seven-thin::before {
	content: '\ecaf';
}

.ph-number-circle-six-thin::before {
	content: '\ecb0';
}

.ph-number-circle-three-thin::before {
	content: '\ecb1';
}

.ph-number-circle-two-thin::before {
	content: '\ecb2';
}

.ph-number-circle-zero-thin::before {
	content: '\ecb3';
}

.ph-number-eight-thin::before {
	content: '\ecb4';
}

.ph-number-five-thin::before {
	content: '\ecb5';
}

.ph-number-four-thin::before {
	content: '\ecb6';
}

.ph-number-nine-thin::before {
	content: '\ecb7';
}

.ph-number-one-thin::before {
	content: '\ecb8';
}

.ph-number-seven-thin::before {
	content: '\ecb9';
}

.ph-number-six-thin::before {
	content: '\ecba';
}

.ph-number-square-eight-thin::before {
	content: '\ecbb';
}

.ph-number-square-five-thin::before {
	content: '\ecbc';
}

.ph-number-square-four-thin::before {
	content: '\ecbd';
}

.ph-number-square-nine-thin::before {
	content: '\ecbe';
}

.ph-number-square-one-thin::before {
	content: '\ecbf';
}

.ph-number-square-seven-thin::before {
	content: '\ecc0';
}

.ph-number-square-six-thin::before {
	content: '\ecc1';
}

.ph-number-square-three-thin::before {
	content: '\ecc2';
}

.ph-number-square-two-thin::before {
	content: '\ecc3';
}

.ph-number-square-zero-thin::before {
	content: '\ecc4';
}

.ph-number-three-thin::before {
	content: '\ecc5';
}

.ph-number-two-thin::before {
	content: '\ecc6';
}

.ph-number-zero-thin::before {
	content: '\ecc7';
}

.ph-nut-thin::before {
	content: '\ecc8';
}

.ph-ny-times-logo-thin::before {
	content: '\ecc9';
}

.ph-octagon-thin::before {
	content: '\ecca';
}

.ph-option-thin::before {
	content: '\eccb';
}

.ph-package-thin::before {
	content: '\eccc';
}

.ph-paint-brush-broad-thin::before {
	content: '\eccd';
}

.ph-paint-brush-household-thin::before {
	content: '\ecce';
}

.ph-paint-brush-thin::before {
	content: '\eccf';
}

.ph-paint-bucket-thin::before {
	content: '\ecd0';
}

.ph-paint-roller-thin::before {
	content: '\ecd1';
}

.ph-palette-thin::before {
	content: '\ecd2';
}

.ph-paper-plane-right-thin::before {
	content: '\ecd3';
}

.ph-paper-plane-thin::before {
	content: '\ecd4';
}

.ph-paper-plane-tilt-thin::before {
	content: '\ecd5';
}

.ph-paperclip-horizontal-thin::before {
	content: '\ecd6';
}

.ph-paperclip-thin::before {
	content: '\ecd7';
}

.ph-parachute-thin::before {
	content: '\ecd8';
}

.ph-password-thin::before {
	content: '\ecd9';
}

.ph-path-thin::before {
	content: '\ecda';
}

.ph-pause-circle-thin::before {
	content: '\ecdb';
}

.ph-pause-thin::before {
	content: '\ecdc';
}

.ph-paw-print-thin::before {
	content: '\ecdd';
}

.ph-peace-thin::before {
	content: '\ecde';
}

.ph-pen-nib-straight-thin::before {
	content: '\ecdf';
}

.ph-pen-nib-thin::before {
	content: '\ece0';
}

.ph-pen-thin::before {
	content: '\ece1';
}

.ph-pencil-circle-thin::before {
	content: '\ece2';
}

.ph-pencil-line-thin::before {
	content: '\ece3';
}

.ph-pencil-simple-line-thin::before {
	content: '\ece4';
}

.ph-pencil-simple-thin::before {
	content: '\ece5';
}

.ph-pencil-thin::before {
	content: '\ece6';
}

.ph-percent-thin::before {
	content: '\ece7';
}

.ph-person-simple-run-thin::before {
	content: '\ece8';
}

.ph-person-simple-thin::before {
	content: '\ece9';
}

.ph-person-simple-walk-thin::before {
	content: '\ecea';
}

.ph-person-thin::before {
	content: '\eceb';
}

.ph-perspective-thin::before {
	content: '\ecec';
}

.ph-phone-call-thin::before {
	content: '\eced';
}

.ph-phone-disconnect-thin::before {
	content: '\ecee';
}

.ph-phone-incoming-thin::before {
	content: '\ecef';
}

.ph-phone-outgoing-thin::before {
	content: '\ecf0';
}

.ph-phone-slash-thin::before {
	content: '\ecf1';
}

.ph-phone-thin::before {
	content: '\ecf2';
}

.ph-phone-x-thin::before {
	content: '\ecf3';
}

.ph-phosphor-logo-thin::before {
	content: '\ecf4';
}

.ph-piano-keys-thin::before {
	content: '\ecf5';
}

.ph-picture-in-picture-thin::before {
	content: '\ecf6';
}

.ph-pill-thin::before {
	content: '\ecf7';
}

.ph-pinterest-logo-thin::before {
	content: '\ecf8';
}

.ph-pinwheel-thin::before {
	content: '\ecf9';
}

.ph-pizza-thin::before {
	content: '\ecfa';
}

.ph-placeholder-thin::before {
	content: '\ecfb';
}

.ph-planet-thin::before {
	content: '\ecfc';
}

.ph-play-circle-thin::before {
	content: '\ecfd';
}

.ph-play-thin::before {
	content: '\ecfe';
}

.ph-playlist-thin::before {
	content: '\ecff';
}

.ph-plug-thin::before {
	content: '\ed00';
}

.ph-plugs-connected-thin::before {
	content: '\ed01';
}

.ph-plugs-thin::before {
	content: '\ed02';
}

.ph-plus-circle-thin::before {
	content: '\ed03';
}

.ph-plus-minus-thin::before {
	content: '\ed04';
}

.ph-plus-thin::before {
	content: '\ed05';
}

.ph-poker-chip-thin::before {
	content: '\ed06';
}

.ph-police-car-thin::before {
	content: '\ed07';
}

.ph-polygon-thin::before {
	content: '\ed08';
}

.ph-popcorn-thin::before {
	content: '\ed09';
}

.ph-power-thin::before {
	content: '\ed0a';
}

.ph-prescription-thin::before {
	content: '\ed0b';
}

.ph-presentation-chart-thin::before {
	content: '\ed0c';
}

.ph-presentation-thin::before {
	content: '\ed0d';
}

.ph-printer-thin::before {
	content: '\ed0e';
}

.ph-prohibit-inset-thin::before {
	content: '\ed0f';
}

.ph-prohibit-thin::before {
	content: '\ed10';
}

.ph-projector-screen-chart-thin::before {
	content: '\ed11';
}

.ph-projector-screen-thin::before {
	content: '\ed12';
}

.ph-push-pin-simple-slash-thin::before {
	content: '\ed13';
}

.ph-push-pin-simple-thin::before {
	content: '\ed14';
}

.ph-push-pin-slash-thin::before {
	content: '\ed15';
}

.ph-push-pin-thin::before {
	content: '\ed16';
}

.ph-puzzle-piece-thin::before {
	content: '\ed17';
}

.ph-qr-code-thin::before {
	content: '\ed18';
}

.ph-question-thin::before {
	content: '\ed19';
}

.ph-queue-thin::before {
	content: '\ed1a';
}

.ph-quotes-thin::before {
	content: '\ed1b';
}

.ph-radical-thin::before {
	content: '\ed1c';
}

.ph-radio-button-thin::before {
	content: '\ed1d';
}

.ph-radio-thin::before {
	content: '\ed1e';
}

.ph-rainbow-cloud-thin::before {
	content: '\ed1f';
}

.ph-rainbow-thin::before {
	content: '\ed20';
}

.ph-receipt-thin::before {
	content: '\ed21';
}

.ph-record-thin::before {
	content: '\ed22';
}

.ph-rectangle-thin::before {
	content: '\ed23';
}

.ph-recycle-thin::before {
	content: '\ed24';
}

.ph-reddit-logo-thin::before {
	content: '\ed25';
}

.ph-repeat-once-thin::before {
	content: '\ed26';
}

.ph-repeat-thin::before {
	content: '\ed27';
}

.ph-rewind-circle-thin::before {
	content: '\ed28';
}

.ph-rewind-thin::before {
	content: '\ed29';
}

.ph-robot-thin::before {
	content: '\ed2a';
}

.ph-rocket-launch-thin::before {
	content: '\ed2b';
}

.ph-rocket-thin::before {
	content: '\ed2c';
}

.ph-rows-thin::before {
	content: '\ed2d';
}

.ph-rss-simple-thin::before {
	content: '\ed2e';
}

.ph-rss-thin::before {
	content: '\ed2f';
}

.ph-rug-thin::before {
	content: '\ed30';
}

.ph-ruler-thin::before {
	content: '\ed31';
}

.ph-scales-thin::before {
	content: '\ed32';
}

.ph-scan-thin::before {
	content: '\ed33';
}

.ph-scissors-thin::before {
	content: '\ed34';
}

.ph-screencast-thin::before {
	content: '\ed35';
}

.ph-scribble-loop-thin::before {
	content: '\ed36';
}

.ph-scroll-thin::before {
	content: '\ed37';
}

.ph-selection-all-thin::before {
	content: '\ed38';
}

.ph-selection-background-thin::before {
	content: '\ed39';
}

.ph-selection-foreground-thin::before {
	content: '\ed3a';
}

.ph-selection-inverse-thin::before {
	content: '\ed3b';
}

.ph-selection-plus-thin::before {
	content: '\ed3c';
}

.ph-selection-slash-thin::before {
	content: '\ed3d';
}

.ph-selection-thin::before {
	content: '\ed3e';
}

.ph-share-network-thin::before {
	content: '\ed3f';
}

.ph-share-thin::before {
	content: '\ed40';
}

.ph-shield-check-thin::before {
	content: '\ed41';
}

.ph-shield-checkered-thin::before {
	content: '\ed42';
}

.ph-shield-chevron-thin::before {
	content: '\ed43';
}

.ph-shield-plus-thin::before {
	content: '\ed44';
}

.ph-shield-slash-thin::before {
	content: '\ed45';
}

.ph-shield-star-thin::before {
	content: '\ed46';
}

.ph-shield-thin::before {
	content: '\ed47';
}

.ph-shield-warning-thin::before {
	content: '\ed48';
}

.ph-shopping-bag-open-thin::before {
	content: '\ed49';
}

.ph-shopping-bag-thin::before {
	content: '\ed4a';
}

.ph-shopping-cart-simple-thin::before {
	content: '\ed4b';
}

.ph-shopping-cart-thin::before {
	content: '\ed4c';
}

.ph-shower-thin::before {
	content: '\ed4d';
}

.ph-shuffle-angular-thin::before {
	content: '\ed4e';
}

.ph-shuffle-simple-thin::before {
	content: '\ed4f';
}

.ph-shuffle-thin::before {
	content: '\ed50';
}

.ph-sidebar-simple-thin::before {
	content: '\ed51';
}

.ph-sidebar-thin::before {
	content: '\ed52';
}

.ph-sign-in-thin::before {
	content: '\ed53';
}

.ph-sign-out-thin::before {
	content: '\ed54';
}

.ph-signpost-thin::before {
	content: '\ed55';
}

.ph-sim-card-thin::before {
	content: '\ed56';
}

.ph-sketch-logo-thin::before {
	content: '\ed57';
}

.ph-skip-back-circle-thin::before {
	content: '\ed58';
}

.ph-skip-back-thin::before {
	content: '\ed59';
}

.ph-skip-forward-circle-thin::before {
	content: '\ed5a';
}

.ph-skip-forward-thin::before {
	content: '\ed5b';
}

.ph-skull-thin::before {
	content: '\ed5c';
}

.ph-slack-logo-thin::before {
	content: '\ed5d';
}

.ph-sliders-horizontal-thin::before {
	content: '\ed5e';
}

.ph-sliders-thin::before {
	content: '\ed5f';
}

.ph-smiley-blank-thin::before {
	content: '\ed60';
}

.ph-smiley-meh-thin::before {
	content: '\ed61';
}

.ph-smiley-nervous-thin::before {
	content: '\ed62';
}

.ph-smiley-sad-thin::before {
	content: '\ed63';
}

.ph-smiley-sticker-thin::before {
	content: '\ed64';
}

.ph-smiley-thin::before {
	content: '\ed65';
}

.ph-smiley-wink-thin::before {
	content: '\ed66';
}

.ph-smiley-x-eyes-thin::before {
	content: '\ed67';
}

.ph-snapchat-logo-thin::before {
	content: '\ed68';
}

.ph-snowflake-thin::before {
	content: '\ed69';
}

.ph-soccer-ball-thin::before {
	content: '\ed6a';
}

.ph-sort-ascending-thin::before {
	content: '\ed6b';
}

.ph-sort-descending-thin::before {
	content: '\ed6c';
}

.ph-spade-thin::before {
	content: '\ed6d';
}

.ph-sparkle-thin::before {
	content: '\ed6e';
}

.ph-speaker-high-thin::before {
	content: '\ed6f';
}

.ph-speaker-low-thin::before {
	content: '\ed70';
}

.ph-speaker-none-thin::before {
	content: '\ed71';
}

.ph-speaker-simple-high-thin::before {
	content: '\ed72';
}

.ph-speaker-simple-low-thin::before {
	content: '\ed73';
}

.ph-speaker-simple-none-thin::before {
	content: '\ed74';
}

.ph-speaker-simple-slash-thin::before {
	content: '\ed75';
}

.ph-speaker-simple-x-thin::before {
	content: '\ed76';
}

.ph-speaker-slash-thin::before {
	content: '\ed77';
}

.ph-speaker-x-thin::before {
	content: '\ed78';
}

.ph-spinner-gap-thin::before {
	content: '\ed79';
}

.ph-spinner-thin::before {
	content: '\ed7a';
}

.ph-spiral-thin::before {
	content: '\ed7b';
}

.ph-spotify-logo-thin::before {
	content: '\ed7c';
}

.ph-square-half-bottom-thin::before {
	content: '\ed7d';
}

.ph-square-half-thin::before {
	content: '\ed7e';
}

.ph-square-logo-thin::before {
	content: '\ed7f';
}

.ph-square-thin::before {
	content: '\ed80';
}

.ph-squares-four-thin::before {
	content: '\ed81';
}

.ph-stack-overflow-logo-thin::before {
	content: '\ed82';
}

.ph-stack-simple-thin::before {
	content: '\ed83';
}

.ph-stack-thin::before {
	content: '\ed84';
}

.ph-stamp-thin::before {
	content: '\ed85';
}

.ph-star-four-thin::before {
	content: '\ed86';
}

.ph-star-half-thin::before {
	content: '\ed87';
}

.ph-star-thin::before {
	content: '\ed88';
}

.ph-sticker-thin::before {
	content: '\ed89';
}

.ph-stop-circle-thin::before {
	content: '\ed8a';
}

.ph-stop-thin::before {
	content: '\ed8b';
}

.ph-storefront-thin::before {
	content: '\ed8c';
}

.ph-strategy-thin::before {
	content: '\ed8d';
}

.ph-stripe-logo-thin::before {
	content: '\ed8e';
}

.ph-student-thin::before {
	content: '\ed8f';
}

.ph-suitcase-simple-thin::before {
	content: '\ed90';
}

.ph-suitcase-thin::before {
	content: '\ed91';
}

.ph-sun-dim-thin::before {
	content: '\ed92';
}

.ph-sun-horizon-thin::before {
	content: '\ed93';
}

.ph-sun-thin::before {
	content: '\ed94';
}

.ph-sunglasses-thin::before {
	content: '\ed95';
}

.ph-swap-thin::before {
	content: '\ed96';
}

.ph-swatches-thin::before {
	content: '\ed97';
}

.ph-sword-thin::before {
	content: '\ed98';
}

.ph-syringe-thin::before {
	content: '\ed99';
}

.ph-t-shirt-thin::before {
	content: '\ed9a';
}

.ph-table-thin::before {
	content: '\ed9b';
}

.ph-tabs-thin::before {
	content: '\ed9c';
}

.ph-tag-chevron-thin::before {
	content: '\ed9d';
}

.ph-tag-simple-thin::before {
	content: '\ed9e';
}

.ph-tag-thin::before {
	content: '\ed9f';
}

.ph-target-thin::before {
	content: '\eda0';
}

.ph-taxi-thin::before {
	content: '\eda1';
}

.ph-telegram-logo-thin::before {
	content: '\eda2';
}

.ph-television-simple-thin::before {
	content: '\eda3';
}

.ph-television-thin::before {
	content: '\eda4';
}

.ph-tennis-ball-thin::before {
	content: '\eda5';
}

.ph-terminal-thin::before {
	content: '\eda6';
}

.ph-terminal-window-thin::before {
	content: '\eda7';
}

.ph-test-tube-thin::before {
	content: '\eda8';
}

.ph-text-aa-thin::before {
	content: '\eda9';
}

.ph-text-align-center-thin::before {
	content: '\edaa';
}

.ph-text-align-justify-thin::before {
	content: '\edab';
}

.ph-text-align-left-thin::before {
	content: '\edac';
}

.ph-text-align-right-thin::before {
	content: '\edad';
}

.ph-text-bolder-thin::before {
	content: '\edae';
}

.ph-text-h-five-thin::before {
	content: '\edaf';
}

.ph-text-h-four-thin::before {
	content: '\edb0';
}

.ph-text-h-one-thin::before {
	content: '\edb1';
}

.ph-text-h-six-thin::before {
	content: '\edb2';
}

.ph-text-h-thin::before {
	content: '\edb3';
}

.ph-text-h-three-thin::before {
	content: '\edb4';
}

.ph-text-h-two-thin::before {
	content: '\edb5';
}

.ph-text-indent-thin::before {
	content: '\edb6';
}

.ph-text-italic-thin::before {
	content: '\edb7';
}

.ph-text-outdent-thin::before {
	content: '\edb8';
}

.ph-text-strikethrough-thin::before {
	content: '\edb9';
}

.ph-text-t-thin::before {
	content: '\edba';
}

.ph-text-underline-thin::before {
	content: '\edbb';
}

.ph-textbox-thin::before {
	content: '\edbc';
}

.ph-thermometer-cold-thin::before {
	content: '\edbd';
}

.ph-thermometer-hot-thin::before {
	content: '\edbe';
}

.ph-thermometer-simple-thin::before {
	content: '\edbf';
}

.ph-thermometer-thin::before {
	content: '\edc0';
}

.ph-thumbs-down-thin::before {
	content: '\edc1';
}

.ph-thumbs-up-thin::before {
	content: '\edc2';
}

.ph-ticket-thin::before {
	content: '\edc3';
}

.ph-tiktok-logo-thin::before {
	content: '\edc4';
}

.ph-timer-thin::before {
	content: '\edc5';
}

.ph-toggle-left-thin::before {
	content: '\edc6';
}

.ph-toggle-right-thin::before {
	content: '\edc7';
}

.ph-toilet-paper-thin::before {
	content: '\edc8';
}

.ph-toilet-thin::before {
	content: '\edc9';
}

.ph-tote-simple-thin::before {
	content: '\edca';
}

.ph-tote-thin::before {
	content: '\edcb';
}

.ph-trademark-registered-thin::before {
	content: '\edcc';
}

.ph-traffic-cone-thin::before {
	content: '\edcd';
}

.ph-traffic-sign-thin::before {
	content: '\edce';
}

.ph-traffic-signal-thin::before {
	content: '\edcf';
}

.ph-train-regional-thin::before {
	content: '\edd0';
}

.ph-train-simple-thin::before {
	content: '\edd1';
}

.ph-train-thin::before {
	content: '\edd2';
}

.ph-translate-thin::before {
	content: '\edd3';
}

.ph-trash-simple-thin::before {
	content: '\edd4';
}

.ph-trash-thin::before {
	content: '\edd5';
}

.ph-tray-thin::before {
	content: '\edd6';
}

.ph-tree-evergreen-thin::before {
	content: '\edd7';
}

.ph-tree-structure-thin::before {
	content: '\edd8';
}

.ph-tree-thin::before {
	content: '\edd9';
}

.ph-trend-down-thin::before {
	content: '\edda';
}

.ph-trend-up-thin::before {
	content: '\eddb';
}

.ph-triangle-thin::before {
	content: '\eddc';
}

.ph-trophy-thin::before {
	content: '\eddd';
}

.ph-truck-thin::before {
	content: '\edde';
}

.ph-twitch-logo-thin::before {
	content: '\eddf';
}

.ph-twitter-logo-thin::before {
	content: '\ede0';
}

.ph-umbrella-simple-thin::before {
	content: '\ede1';
}

.ph-umbrella-thin::before {
	content: '\ede2';
}

.ph-upload-simple-thin::before {
	content: '\ede3';
}

.ph-upload-thin::before {
	content: '\ede4';
}

.ph-user-circle-gear-thin::before {
	content: '\ede5';
}

.ph-user-circle-minus-thin::before {
	content: '\ede6';
}

.ph-user-circle-plus-thin::before {
	content: '\ede7';
}

.ph-user-circle-thin::before {
	content: '\ede8';
}

.ph-user-focus-thin::before {
	content: '\ede9';
}

.ph-user-gear-thin::before {
	content: '\edea';
}

.ph-user-list-thin::before {
	content: '\edeb';
}

.ph-user-minus-thin::before {
	content: '\edec';
}

.ph-user-plus-thin::before {
	content: '\eded';
}

.ph-user-rectangle-thin::before {
	content: '\edee';
}

.ph-user-square-thin::before {
	content: '\edef';
}

.ph-user-switch-thin::before {
	content: '\edf0';
}

.ph-user-thin::before {
	content: '\edf1';
}

.ph-users-four-thin::before {
	content: '\edf2';
}

.ph-users-thin::before {
	content: '\edf3';
}

.ph-users-three-thin::before {
	content: '\edf4';
}

.ph-vault-thin::before {
	content: '\edf5';
}

.ph-vibrate-thin::before {
	content: '\edf6';
}

.ph-video-camera-slash-thin::before {
	content: '\edf7';
}

.ph-video-camera-thin::before {
	content: '\edf8';
}

.ph-vignette-thin::before {
	content: '\edf9';
}

.ph-voicemail-thin::before {
	content: '\edfa';
}

.ph-volleyball-thin::before {
	content: '\edfb';
}

.ph-wall-thin::before {
	content: '\edfc';
}

.ph-wallet-thin::before {
	content: '\edfd';
}

.ph-warning-circle-thin::before {
	content: '\edfe';
}

.ph-warning-octagon-thin::before {
	content: '\edff';
}

.ph-warning-thin::before {
	content: '\ee00';
}

.ph-watch-thin::before {
	content: '\ee01';
}

.ph-wave-sawtooth-thin::before {
	content: '\ee02';
}

.ph-wave-sine-thin::before {
	content: '\ee03';
}

.ph-wave-square-thin::before {
	content: '\ee04';
}

.ph-wave-triangle-thin::before {
	content: '\ee05';
}

.ph-waves-thin::before {
	content: '\ee06';
}

.ph-webcam-thin::before {
	content: '\ee07';
}

.ph-whatsapp-logo-thin::before {
	content: '\ee08';
}

.ph-wheelchair-thin::before {
	content: '\ee09';
}

.ph-wifi-high-thin::before {
	content: '\ee0a';
}

.ph-wifi-low-thin::before {
	content: '\ee0b';
}

.ph-wifi-medium-thin::before {
	content: '\ee0c';
}

.ph-wifi-none-thin::before {
	content: '\ee0d';
}

.ph-wifi-slash-thin::before {
	content: '\ee0e';
}

.ph-wifi-x-thin::before {
	content: '\ee0f';
}

.ph-wind-thin::before {
	content: '\ee10';
}

.ph-windows-logo-thin::before {
	content: '\ee11';
}

.ph-wine-thin::before {
	content: '\ee12';
}

.ph-wrench-thin::before {
	content: '\ee13';
}

.ph-x-circle-thin::before {
	content: '\ee14';
}

.ph-x-square-thin::before {
	content: '\ee15';
}

.ph-x-thin::before {
	content: '\ee16';
}

.ph-yin-yang-thin::before {
	content: '\ee17';
}

.ph-youtube-logo-thin::before {
	content: '\ee18';
}

.ph-activity-light::before {
	content: '\ee19';
}

.ph-address-book-light::before {
	content: '\ee1a';
}

.ph-airplane-in-flight-light::before {
	content: '\ee1b';
}

.ph-airplane-landing-light::before {
	content: '\ee1c';
}

.ph-airplane-light::before {
	content: '\ee1d';
}

.ph-airplane-takeoff-light::before {
	content: '\ee1e';
}

.ph-airplane-tilt-light::before {
	content: '\ee1f';
}

.ph-airplay-light::before {
	content: '\ee20';
}

.ph-alarm-light::before {
	content: '\ee21';
}

.ph-alien-light::before {
	content: '\ee22';
}

.ph-align-bottom-light::before {
	content: '\ee23';
}

.ph-align-bottom-simple-light::before {
	content: '\ee24';
}

.ph-align-center-horizontal-light::before {
	content: '\ee25';
}

.ph-align-center-horizontal-simple-light::before {
	content: '\ee26';
}

.ph-align-center-vertical-light::before {
	content: '\ee27';
}

.ph-align-center-vertical-simple-light::before {
	content: '\ee28';
}

.ph-align-left-light::before {
	content: '\ee29';
}

.ph-align-left-simple-light::before {
	content: '\ee2a';
}

.ph-align-right-light::before {
	content: '\ee2b';
}

.ph-align-right-simple-light::before {
	content: '\ee2c';
}

.ph-align-top-light::before {
	content: '\ee2d';
}

.ph-align-top-simple-light::before {
	content: '\ee2e';
}

.ph-anchor-light::before {
	content: '\ee2f';
}

.ph-anchor-simple-light::before {
	content: '\ee30';
}

.ph-android-logo-light::before {
	content: '\ee31';
}

.ph-angular-logo-light::before {
	content: '\ee32';
}

.ph-aperture-light::before {
	content: '\ee33';
}

.ph-app-store-logo-light::before {
	content: '\ee34';
}

.ph-app-window-light::before {
	content: '\ee35';
}

.ph-apple-logo-light::before {
	content: '\ee36';
}

.ph-apple-podcasts-logo-light::before {
	content: '\ee37';
}

.ph-archive-box-light::before {
	content: '\ee38';
}

.ph-archive-light::before {
	content: '\ee39';
}

.ph-archive-tray-light::before {
	content: '\ee3a';
}

.ph-armchair-light::before {
	content: '\ee3b';
}

.ph-arrow-arc-left-light::before {
	content: '\ee3c';
}

.ph-arrow-arc-right-light::before {
	content: '\ee3d';
}

.ph-arrow-bend-double-up-left-light::before {
	content: '\ee3e';
}

.ph-arrow-bend-double-up-right-light::before {
	content: '\ee3f';
}

.ph-arrow-bend-down-left-light::before {
	content: '\ee40';
}

.ph-arrow-bend-down-right-light::before {
	content: '\ee41';
}

.ph-arrow-bend-left-down-light::before {
	content: '\ee42';
}

.ph-arrow-bend-left-up-light::before {
	content: '\ee43';
}

.ph-arrow-bend-right-down-light::before {
	content: '\ee44';
}

.ph-arrow-bend-right-up-light::before {
	content: '\ee45';
}

.ph-arrow-bend-up-left-light::before {
	content: '\ee46';
}

.ph-arrow-bend-up-right-light::before {
	content: '\ee47';
}

.ph-arrow-circle-down-left-light::before {
	content: '\ee48';
}

.ph-arrow-circle-down-light::before {
	content: '\ee49';
}

.ph-arrow-circle-down-right-light::before {
	content: '\ee4a';
}

.ph-arrow-circle-left-light::before {
	content: '\ee4b';
}

.ph-arrow-circle-right-light::before {
	content: '\ee4c';
}

.ph-arrow-circle-up-left-light::before {
	content: '\ee4d';
}

.ph-arrow-circle-up-light::before {
	content: '\ee4e';
}

.ph-arrow-circle-up-right-light::before {
	content: '\ee4f';
}

.ph-arrow-clockwise-light::before {
	content: '\ee50';
}

.ph-arrow-counter-clockwise-light::before {
	content: '\ee51';
}

.ph-arrow-down-left-light::before {
	content: '\ee52';
}

.ph-arrow-down-light::before {
	content: '\ee53';
}

.ph-arrow-down-right-light::before {
	content: '\ee54';
}

.ph-arrow-elbow-down-left-light::before {
	content: '\ee55';
}

.ph-arrow-elbow-down-right-light::before {
	content: '\ee56';
}

.ph-arrow-elbow-left-down-light::before {
	content: '\ee57';
}

.ph-arrow-elbow-left-light::before {
	content: '\ee58';
}

.ph-arrow-elbow-left-up-light::before {
	content: '\ee59';
}

.ph-arrow-elbow-right-down-light::before {
	content: '\ee5a';
}

.ph-arrow-elbow-right-light::before {
	content: '\ee5b';
}

.ph-arrow-elbow-right-up-light::before {
	content: '\ee5c';
}

.ph-arrow-elbow-up-left-light::before {
	content: '\ee5d';
}

.ph-arrow-elbow-up-right-light::before {
	content: '\ee5e';
}

.ph-arrow-fat-down-light::before {
	content: '\ee5f';
}

.ph-arrow-fat-left-light::before {
	content: '\ee60';
}

.ph-arrow-fat-line-down-light::before {
	content: '\ee61';
}

.ph-arrow-fat-line-left-light::before {
	content: '\ee62';
}

.ph-arrow-fat-line-right-light::before {
	content: '\ee63';
}

.ph-arrow-fat-line-up-light::before {
	content: '\ee64';
}

.ph-arrow-fat-lines-down-light::before {
	content: '\ee65';
}

.ph-arrow-fat-lines-left-light::before {
	content: '\ee66';
}

.ph-arrow-fat-lines-right-light::before {
	content: '\ee67';
}

.ph-arrow-fat-lines-up-light::before {
	content: '\ee68';
}

.ph-arrow-fat-right-light::before {
	content: '\ee69';
}

.ph-arrow-fat-up-light::before {
	content: '\ee6a';
}

.ph-arrow-left-light::before {
	content: '\ee6b';
}

.ph-arrow-line-down-left-light::before {
	content: '\ee6c';
}

.ph-arrow-line-down-light::before {
	content: '\ee6d';
}

.ph-arrow-line-down-right-light::before {
	content: '\ee6e';
}

.ph-arrow-line-left-light::before {
	content: '\ee6f';
}

.ph-arrow-line-right-light::before {
	content: '\ee70';
}

.ph-arrow-line-up-left-light::before {
	content: '\ee71';
}

.ph-arrow-line-up-light::before {
	content: '\ee72';
}

.ph-arrow-line-up-right-light::before {
	content: '\ee73';
}

.ph-arrow-right-light::before {
	content: '\ee74';
}

.ph-arrow-square-down-left-light::before {
	content: '\ee75';
}

.ph-arrow-square-down-light::before {
	content: '\ee76';
}

.ph-arrow-square-down-right-light::before {
	content: '\ee77';
}

.ph-arrow-square-in-light::before {
	content: '\ee78';
}

.ph-arrow-square-left-light::before {
	content: '\ee79';
}

.ph-arrow-square-out-light::before {
	content: '\ee7a';
}

.ph-arrow-square-right-light::before {
	content: '\ee7b';
}

.ph-arrow-square-up-left-light::before {
	content: '\ee7c';
}

.ph-arrow-square-up-light::before {
	content: '\ee7d';
}

.ph-arrow-square-up-right-light::before {
	content: '\ee7e';
}

.ph-arrow-u-down-left-light::before {
	content: '\ee7f';
}

.ph-arrow-u-down-right-light::before {
	content: '\ee80';
}

.ph-arrow-u-left-down-light::before {
	content: '\ee81';
}

.ph-arrow-u-left-up-light::before {
	content: '\ee82';
}

.ph-arrow-u-right-down-light::before {
	content: '\ee83';
}

.ph-arrow-u-right-up-light::before {
	content: '\ee84';
}

.ph-arrow-u-up-left-light::before {
	content: '\ee85';
}

.ph-arrow-u-up-right-light::before {
	content: '\ee86';
}

.ph-arrow-up-left-light::before {
	content: '\ee87';
}

.ph-arrow-up-light::before {
	content: '\ee88';
}

.ph-arrow-up-right-light::before {
	content: '\ee89';
}

.ph-arrows-clockwise-light::before {
	content: '\ee8a';
}

.ph-arrows-counter-clockwise-light::before {
	content: '\ee8b';
}

.ph-arrows-down-up-light::before {
	content: '\ee8c';
}

.ph-arrows-horizontal-light::before {
	content: '\ee8d';
}

.ph-arrows-in-cardinal-light::before {
	content: '\ee8e';
}

.ph-arrows-in-light::before {
	content: '\ee8f';
}

.ph-arrows-in-line-horizontal-light::before {
	content: '\ee90';
}

.ph-arrows-in-line-vertical-light::before {
	content: '\ee91';
}

.ph-arrows-in-simple-light::before {
	content: '\ee92';
}

.ph-arrows-left-right-light::before {
	content: '\ee93';
}

.ph-arrows-out-cardinal-light::before {
	content: '\ee94';
}

.ph-arrows-out-light::before {
	content: '\ee95';
}

.ph-arrows-out-line-horizontal-light::before {
	content: '\ee96';
}

.ph-arrows-out-line-vertical-light::before {
	content: '\ee97';
}

.ph-arrows-out-simple-light::before {
	content: '\ee98';
}

.ph-arrows-vertical-light::before {
	content: '\ee99';
}

.ph-article-light::before {
	content: '\ee9a';
}

.ph-article-medium-light::before {
	content: '\ee9b';
}

.ph-article-ny-times-light::before {
	content: '\ee9c';
}

.ph-asterisk-light::before {
	content: '\ee9d';
}

.ph-asterisk-simple-light::before {
	content: '\ee9e';
}

.ph-at-light::before {
	content: '\ee9f';
}

.ph-atom-light::before {
	content: '\eea0';
}

.ph-baby-light::before {
	content: '\eea1';
}

.ph-backpack-light::before {
	content: '\eea2';
}

.ph-backspace-light::before {
	content: '\eea3';
}

.ph-bag-light::before {
	content: '\eea4';
}

.ph-bag-simple-light::before {
	content: '\eea5';
}

.ph-balloon-light::before {
	content: '\eea6';
}

.ph-bandaids-light::before {
	content: '\eea7';
}

.ph-bank-light::before {
	content: '\eea8';
}

.ph-barbell-light::before {
	content: '\eea9';
}

.ph-barcode-light::before {
	content: '\eeaa';
}

.ph-barricade-light::before {
	content: '\eeab';
}

.ph-baseball-light::before {
	content: '\eeac';
}

.ph-basketball-light::before {
	content: '\eead';
}

.ph-bathtub-light::before {
	content: '\eeae';
}

.ph-battery-charging-light::before {
	content: '\eeaf';
}

.ph-battery-charging-vertical-light::before {
	content: '\eeb0';
}

.ph-battery-empty-light::before {
	content: '\eeb1';
}

.ph-battery-full-light::before {
	content: '\eeb2';
}

.ph-battery-high-light::before {
	content: '\eeb3';
}

.ph-battery-low-light::before {
	content: '\eeb4';
}

.ph-battery-medium-light::before {
	content: '\eeb5';
}

.ph-battery-plus-light::before {
	content: '\eeb6';
}

.ph-battery-warning-light::before {
	content: '\eeb7';
}

.ph-battery-warning-vertical-light::before {
	content: '\eeb8';
}

.ph-bed-light::before {
	content: '\eeb9';
}

.ph-beer-bottle-light::before {
	content: '\eeba';
}

.ph-behance-logo-light::before {
	content: '\eebb';
}

.ph-bell-light::before {
	content: '\eebc';
}

.ph-bell-ringing-light::before {
	content: '\eebd';
}

.ph-bell-simple-light::before {
	content: '\eebe';
}

.ph-bell-simple-ringing-light::before {
	content: '\eebf';
}

.ph-bell-simple-slash-light::before {
	content: '\eec0';
}

.ph-bell-simple-z-light::before {
	content: '\eec1';
}

.ph-bell-slash-light::before {
	content: '\eec2';
}

.ph-bell-z-light::before {
	content: '\eec3';
}

.ph-bezier-curve-light::before {
	content: '\eec4';
}

.ph-bicycle-light::before {
	content: '\eec5';
}

.ph-binoculars-light::before {
	content: '\eec6';
}

.ph-bird-light::before {
	content: '\eec7';
}

.ph-bluetooth-connected-light::before {
	content: '\eec8';
}

.ph-bluetooth-light::before {
	content: '\eec9';
}

.ph-bluetooth-slash-light::before {
	content: '\eeca';
}

.ph-bluetooth-x-light::before {
	content: '\eecb';
}

.ph-boat-light::before {
	content: '\eecc';
}

.ph-book-bookmark-light::before {
	content: '\eecd';
}

.ph-book-light::before {
	content: '\eece';
}

.ph-book-open-light::before {
	content: '\eecf';
}

.ph-bookmark-light::before {
	content: '\eed0';
}

.ph-bookmark-simple-light::before {
	content: '\eed1';
}

.ph-bookmarks-light::before {
	content: '\eed2';
}

.ph-bookmarks-simple-light::before {
	content: '\eed3';
}

.ph-books-light::before {
	content: '\eed4';
}

.ph-bounding-box-light::before {
	content: '\eed5';
}

.ph-brackets-angle-light::before {
	content: '\eed6';
}

.ph-brackets-curly-light::before {
	content: '\eed7';
}

.ph-brackets-round-light::before {
	content: '\eed8';
}

.ph-brackets-square-light::before {
	content: '\eed9';
}

.ph-brain-light::before {
	content: '\eeda';
}

.ph-brandy-light::before {
	content: '\eedb';
}

.ph-briefcase-light::before {
	content: '\eedc';
}

.ph-briefcase-metal-light::before {
	content: '\eedd';
}

.ph-broadcast-light::before {
	content: '\eede';
}

.ph-browser-light::before {
	content: '\eedf';
}

.ph-browsers-light::before {
	content: '\eee0';
}

.ph-bug-beetle-light::before {
	content: '\eee1';
}

.ph-bug-droid-light::before {
	content: '\eee2';
}

.ph-bug-light::before {
	content: '\eee3';
}

.ph-buildings-light::before {
	content: '\eee4';
}

.ph-bus-light::before {
	content: '\eee5';
}

.ph-butterfly-light::before {
	content: '\eee6';
}

.ph-cactus-light::before {
	content: '\eee7';
}

.ph-cake-light::before {
	content: '\eee8';
}

.ph-calculator-light::before {
	content: '\eee9';
}

.ph-calendar-blank-light::before {
	content: '\eeea';
}

.ph-calendar-check-light::before {
	content: '\eeeb';
}

.ph-calendar-light::before {
	content: '\eeec';
}

.ph-calendar-plus-light::before {
	content: '\eeed';
}

.ph-calendar-x-light::before {
	content: '\eeee';
}

.ph-camera-light::before {
	content: '\eeef';
}

.ph-camera-rotate-light::before {
	content: '\eef0';
}

.ph-camera-slash-light::before {
	content: '\eef1';
}

.ph-campfire-light::before {
	content: '\eef2';
}

.ph-car-light::before {
	content: '\eef3';
}

.ph-car-simple-light::before {
	content: '\eef4';
}

.ph-cardholder-light::before {
	content: '\eef5';
}

.ph-cards-light::before {
	content: '\eef6';
}

.ph-caret-circle-double-down-light::before {
	content: '\eef7';
}

.ph-caret-circle-double-left-light::before {
	content: '\eef8';
}

.ph-caret-circle-double-right-light::before {
	content: '\eef9';
}

.ph-caret-circle-double-up-light::before {
	content: '\eefa';
}

.ph-caret-circle-down-light::before {
	content: '\eefb';
}

.ph-caret-circle-left-light::before {
	content: '\eefc';
}

.ph-caret-circle-right-light::before {
	content: '\eefd';
}

.ph-caret-circle-up-light::before {
	content: '\eefe';
}

.ph-caret-double-down-light::before {
	content: '\eeff';
}

.ph-caret-double-left-light::before {
	content: '\ef00';
}

.ph-caret-double-right-light::before {
	content: '\ef01';
}

.ph-caret-double-up-light::before {
	content: '\ef02';
}

.ph-caret-down-light::before {
	content: '\ef03';
}

.ph-caret-left-light::before {
	content: '\ef04';
}

.ph-caret-right-light::before {
	content: '\ef05';
}

.ph-caret-up-light::before {
	content: '\ef06';
}

.ph-cat-light::before {
	content: '\ef07';
}

.ph-cell-signal-full-light::before {
	content: '\ef08';
}

.ph-cell-signal-high-light::before {
	content: '\ef09';
}

.ph-cell-signal-low-light::before {
	content: '\ef0a';
}

.ph-cell-signal-medium-light::before {
	content: '\ef0b';
}

.ph-cell-signal-none-light::before {
	content: '\ef0c';
}

.ph-cell-signal-slash-light::before {
	content: '\ef0d';
}

.ph-cell-signal-x-light::before {
	content: '\ef0e';
}

.ph-chalkboard-light::before {
	content: '\ef0f';
}

.ph-chalkboard-simple-light::before {
	content: '\ef10';
}

.ph-chalkboard-teacher-light::before {
	content: '\ef11';
}

.ph-chart-bar-horizontal-light::before {
	content: '\ef12';
}

.ph-chart-bar-light::before {
	content: '\ef13';
}

.ph-chart-line-light::before {
	content: '\ef14';
}

.ph-chart-line-up-light::before {
	content: '\ef15';
}

.ph-chart-pie-light::before {
	content: '\ef16';
}

.ph-chart-pie-slice-light::before {
	content: '\ef17';
}

.ph-chat-centered-dots-light::before {
	content: '\ef18';
}

.ph-chat-centered-light::before {
	content: '\ef19';
}

.ph-chat-centered-text-light::before {
	content: '\ef1a';
}

.ph-chat-circle-dots-light::before {
	content: '\ef1b';
}

.ph-chat-circle-light::before {
	content: '\ef1c';
}

.ph-chat-circle-text-light::before {
	content: '\ef1d';
}

.ph-chat-dots-light::before {
	content: '\ef1e';
}

.ph-chat-light::before {
	content: '\ef1f';
}

.ph-chat-teardrop-dots-light::before {
	content: '\ef20';
}

.ph-chat-teardrop-light::before {
	content: '\ef21';
}

.ph-chat-teardrop-text-light::before {
	content: '\ef22';
}

.ph-chat-text-light::before {
	content: '\ef23';
}

.ph-chats-circle-light::before {
	content: '\ef24';
}

.ph-chats-light::before {
	content: '\ef25';
}

.ph-chats-teardrop-light::before {
	content: '\ef26';
}

.ph-check-circle-light::before {
	content: '\ef27';
}

.ph-check-light::before {
	content: '\ef28';
}

.ph-check-square-light::before {
	content: '\ef29';
}

.ph-check-square-offset-light::before {
	content: '\ef2a';
}

.ph-checks-light::before {
	content: '\ef2b';
}

.ph-circle-dashed-light::before {
	content: '\ef2c';
}

.ph-circle-half-light::before {
	content: '\ef2d';
}

.ph-circle-half-tilt-light::before {
	content: '\ef2e';
}

.ph-circle-light::before {
	content: '\ef2f';
}

.ph-circle-notch-light::before {
	content: '\ef30';
}

.ph-circle-wavy-check-light::before {
	content: '\ef31';
}

.ph-circle-wavy-light::before {
	content: '\ef32';
}

.ph-circle-wavy-question-light::before {
	content: '\ef33';
}

.ph-circle-wavy-warning-light::before {
	content: '\ef34';
}

.ph-circles-four-light::before {
	content: '\ef35';
}

.ph-circles-three-light::before {
	content: '\ef36';
}

.ph-circles-three-plus-light::before {
	content: '\ef37';
}

.ph-clipboard-light::before {
	content: '\ef38';
}

.ph-clipboard-text-light::before {
	content: '\ef39';
}

.ph-clock-afternoon-light::before {
	content: '\ef3a';
}

.ph-clock-clockwise-light::before {
	content: '\ef3b';
}

.ph-clock-counter-clockwise-light::before {
	content: '\ef3c';
}

.ph-clock-light::before {
	content: '\ef3d';
}

.ph-closed-captioning-light::before {
	content: '\ef3e';
}

.ph-cloud-arrow-down-light::before {
	content: '\ef3f';
}

.ph-cloud-arrow-up-light::before {
	content: '\ef40';
}

.ph-cloud-check-light::before {
	content: '\ef41';
}

.ph-cloud-fog-light::before {
	content: '\ef42';
}

.ph-cloud-light::before {
	content: '\ef43';
}

.ph-cloud-lightning-light::before {
	content: '\ef44';
}

.ph-cloud-moon-light::before {
	content: '\ef45';
}

.ph-cloud-rain-light::before {
	content: '\ef46';
}

.ph-cloud-slash-light::before {
	content: '\ef47';
}

.ph-cloud-snow-light::before {
	content: '\ef48';
}

.ph-cloud-sun-light::before {
	content: '\ef49';
}

.ph-club-light::before {
	content: '\ef4a';
}

.ph-coat-hanger-light::before {
	content: '\ef4b';
}

.ph-code-light::before {
	content: '\ef4c';
}

.ph-code-simple-light::before {
	content: '\ef4d';
}

.ph-codepen-logo-light::before {
	content: '\ef4e';
}

.ph-codesandbox-logo-light::before {
	content: '\ef4f';
}

.ph-coffee-light::before {
	content: '\ef50';
}

.ph-coin-light::before {
	content: '\ef51';
}

.ph-coin-vertical-light::before {
	content: '\ef52';
}

.ph-coins-light::before {
	content: '\ef53';
}

.ph-columns-light::before {
	content: '\ef54';
}

.ph-command-light::before {
	content: '\ef55';
}

.ph-compass-light::before {
	content: '\ef56';
}

.ph-computer-tower-light::before {
	content: '\ef57';
}

.ph-confetti-light::before {
	content: '\ef58';
}

.ph-cookie-light::before {
	content: '\ef59';
}

.ph-cooking-pot-light::before {
	content: '\ef5a';
}

.ph-copy-light::before {
	content: '\ef5b';
}

.ph-copy-simple-light::before {
	content: '\ef5c';
}

.ph-copyleft-light::before {
	content: '\ef5d';
}

.ph-copyright-light::before {
	content: '\ef5e';
}

.ph-corners-in-light::before {
	content: '\ef5f';
}

.ph-corners-out-light::before {
	content: '\ef60';
}

.ph-cpu-light::before {
	content: '\ef61';
}

.ph-credit-card-light::before {
	content: '\ef62';
}

.ph-crop-light::before {
	content: '\ef63';
}

.ph-crosshair-light::before {
	content: '\ef64';
}

.ph-crosshair-simple-light::before {
	content: '\ef65';
}

.ph-crown-light::before {
	content: '\ef66';
}

.ph-crown-simple-light::before {
	content: '\ef67';
}

.ph-cube-light::before {
	content: '\ef68';
}

.ph-currency-btc-light::before {
	content: '\ef69';
}

.ph-currency-circle-dollar-light::before {
	content: '\ef6a';
}

.ph-currency-cny-light::before {
	content: '\ef6b';
}

.ph-currency-dollar-light::before {
	content: '\ef6c';
}

.ph-currency-dollar-simple-light::before {
	content: '\ef6d';
}

.ph-currency-eth-light::before {
	content: '\ef6e';
}

.ph-currency-eur-light::before {
	content: '\ef6f';
}

.ph-currency-gbp-light::before {
	content: '\ef70';
}

.ph-currency-inr-light::before {
	content: '\ef71';
}

.ph-currency-jpy-light::before {
	content: '\ef72';
}

.ph-currency-krw-light::before {
	content: '\ef73';
}

.ph-currency-kzt-light::before {
	content: '\ef74';
}

.ph-currency-ngn-light::before {
	content: '\ef75';
}

.ph-currency-rub-light::before {
	content: '\ef76';
}

.ph-cursor-light::before {
	content: '\ef77';
}

.ph-cursor-text-light::before {
	content: '\ef78';
}

.ph-cylinder-light::before {
	content: '\ef79';
}

.ph-database-light::before {
	content: '\ef7a';
}

.ph-desktop-light::before {
	content: '\ef7b';
}

.ph-desktop-tower-light::before {
	content: '\ef7c';
}

.ph-detective-light::before {
	content: '\ef7d';
}

.ph-device-mobile-camera-light::before {
	content: '\ef7e';
}

.ph-device-mobile-light::before {
	content: '\ef7f';
}

.ph-device-mobile-speaker-light::before {
	content: '\ef80';
}

.ph-device-tablet-camera-light::before {
	content: '\ef81';
}

.ph-device-tablet-light::before {
	content: '\ef82';
}

.ph-device-tablet-speaker-light::before {
	content: '\ef83';
}

.ph-diamond-light::before {
	content: '\ef84';
}

.ph-diamonds-four-light::before {
	content: '\ef85';
}

.ph-dice-five-light::before {
	content: '\ef86';
}

.ph-dice-four-light::before {
	content: '\ef87';
}

.ph-dice-one-light::before {
	content: '\ef88';
}

.ph-dice-six-light::before {
	content: '\ef89';
}

.ph-dice-three-light::before {
	content: '\ef8a';
}

.ph-dice-two-light::before {
	content: '\ef8b';
}

.ph-disc-light::before {
	content: '\ef8c';
}

.ph-discord-logo-light::before {
	content: '\ef8d';
}

.ph-divide-light::before {
	content: '\ef8e';
}

.ph-dog-light::before {
	content: '\ef8f';
}

.ph-door-light::before {
	content: '\ef90';
}

.ph-dots-nine-light::before {
	content: '\ef91';
}

.ph-dots-six-light::before {
	content: '\ef92';
}

.ph-dots-six-vertical-light::before {
	content: '\ef93';
}

.ph-dots-three-circle-light::before {
	content: '\ef94';
}

.ph-dots-three-circle-vertical-light::before {
	content: '\ef95';
}

.ph-dots-three-light::before {
	content: '\ef96';
}

.ph-dots-three-outline-light::before {
	content: '\ef97';
}

.ph-dots-three-outline-vertical-light::before {
	content: '\ef98';
}

.ph-dots-three-vertical-light::before {
	content: '\ef99';
}

.ph-download-light::before {
	content: '\ef9a';
}

.ph-download-simple-light::before {
	content: '\ef9b';
}

.ph-dribbble-logo-light::before {
	content: '\ef9c';
}

.ph-drop-half-bottom-light::before {
	content: '\ef9d';
}

.ph-drop-half-light::before {
	content: '\ef9e';
}

.ph-drop-light::before {
	content: '\ef9f';
}

.ph-ear-light::before {
	content: '\efa0';
}

.ph-ear-slash-light::before {
	content: '\efa1';
}

.ph-egg-crack-light::before {
	content: '\efa2';
}

.ph-egg-light::before {
	content: '\efa3';
}

.ph-eject-light::before {
	content: '\efa4';
}

.ph-eject-simple-light::before {
	content: '\efa5';
}

.ph-envelope-light::before {
	content: '\efa6';
}

.ph-envelope-open-light::before {
	content: '\efa7';
}

.ph-envelope-simple-light::before {
	content: '\efa8';
}

.ph-envelope-simple-open-light::before {
	content: '\efa9';
}

.ph-equalizer-light::before {
	content: '\efaa';
}

.ph-equals-light::before {
	content: '\efab';
}

.ph-eraser-light::before {
	content: '\efac';
}

.ph-exam-light::before {
	content: '\efad';
}

.ph-export-light::before {
	content: '\efae';
}

.ph-eye-closed-light::before {
	content: '\efaf';
}

.ph-eye-light::before {
	content: '\efb0';
}

.ph-eye-slash-light::before {
	content: '\efb1';
}

.ph-eyedropper-light::before {
	content: '\efb2';
}

.ph-eyedropper-sample-light::before {
	content: '\efb3';
}

.ph-eyeglasses-light::before {
	content: '\efb4';
}

.ph-face-mask-light::before {
	content: '\efb5';
}

.ph-facebook-logo-light::before {
	content: '\efb6';
}

.ph-factory-light::before {
	content: '\efb7';
}

.ph-faders-horizontal-light::before {
	content: '\efb8';
}

.ph-faders-light::before {
	content: '\efb9';
}

.ph-fast-forward-circle-light::before {
	content: '\efba';
}

.ph-fast-forward-light::before {
	content: '\efbb';
}

.ph-figma-logo-light::before {
	content: '\efbc';
}

.ph-file-arrow-down-light::before {
	content: '\efbd';
}

.ph-file-arrow-up-light::before {
	content: '\efbe';
}

.ph-file-audio-light::before {
	content: '\efbf';
}

.ph-file-cloud-light::before {
	content: '\efc0';
}

.ph-file-code-light::before {
	content: '\efc1';
}

.ph-file-css-light::before {
	content: '\efc2';
}

.ph-file-csv-light::before {
	content: '\efc3';
}

.ph-file-doc-light::before {
	content: '\efc4';
}

.ph-file-dotted-light::before {
	content: '\efc5';
}

.ph-file-html-light::before {
	content: '\efc6';
}

.ph-file-image-light::before {
	content: '\efc7';
}

.ph-file-jpg-light::before {
	content: '\efc8';
}

.ph-file-js-light::before {
	content: '\efc9';
}

.ph-file-jsx-light::before {
	content: '\efca';
}

.ph-file-light::before {
	content: '\efcb';
}

.ph-file-lock-light::before {
	content: '\efcc';
}

.ph-file-minus-light::before {
	content: '\efcd';
}

.ph-file-pdf-light::before {
	content: '\efce';
}

.ph-file-plus-light::before {
	content: '\efcf';
}

.ph-file-png-light::before {
	content: '\efd0';
}

.ph-file-ppt-light::before {
	content: '\efd1';
}

.ph-file-rs-light::before {
	content: '\efd2';
}

.ph-file-search-light::before {
	content: '\efd3';
}

.ph-file-text-light::before {
	content: '\efd4';
}

.ph-file-ts-light::before {
	content: '\efd5';
}

.ph-file-tsx-light::before {
	content: '\efd6';
}

.ph-file-video-light::before {
	content: '\efd7';
}

.ph-file-vue-light::before {
	content: '\efd8';
}

.ph-file-x-light::before {
	content: '\efd9';
}

.ph-file-xls-light::before {
	content: '\efda';
}

.ph-file-zip-light::before {
	content: '\efdb';
}

.ph-files-light::before {
	content: '\efdc';
}

.ph-film-script-light::before {
	content: '\efdd';
}

.ph-film-slate-light::before {
	content: '\efde';
}

.ph-film-strip-light::before {
	content: '\efdf';
}

.ph-fingerprint-light::before {
	content: '\efe0';
}

.ph-fingerprint-simple-light::before {
	content: '\efe1';
}

.ph-finn-the-human-light::before {
	content: '\efe2';
}

.ph-fire-light::before {
	content: '\efe3';
}

.ph-fire-simple-light::before {
	content: '\efe4';
}

.ph-first-aid-kit-light::before {
	content: '\efe5';
}

.ph-first-aid-light::before {
	content: '\efe6';
}

.ph-fish-light::before {
	content: '\efe7';
}

.ph-fish-simple-light::before {
	content: '\efe8';
}

.ph-flag-banner-light::before {
	content: '\efe9';
}

.ph-flag-checkered-light::before {
	content: '\efea';
}

.ph-flag-light::before {
	content: '\efeb';
}

.ph-flame-light::before {
	content: '\efec';
}

.ph-flashlight-light::before {
	content: '\efed';
}

.ph-flask-light::before {
	content: '\efee';
}

.ph-floppy-disk-back-light::before {
	content: '\efef';
}

.ph-floppy-disk-light::before {
	content: '\eff0';
}

.ph-flow-arrow-light::before {
	content: '\eff1';
}

.ph-flower-light::before {
	content: '\eff2';
}

.ph-flower-lotus-light::before {
	content: '\eff3';
}

.ph-flying-saucer-light::before {
	content: '\eff4';
}

.ph-folder-dotted-light::before {
	content: '\eff5';
}

.ph-folder-light::before {
	content: '\eff6';
}

.ph-folder-lock-light::before {
	content: '\eff7';
}

.ph-folder-minus-light::before {
	content: '\eff8';
}

.ph-folder-notch-light::before {
	content: '\eff9';
}

.ph-folder-notch-minus-light::before {
	content: '\effa';
}

.ph-folder-notch-open-light::before {
	content: '\effb';
}

.ph-folder-notch-plus-light::before {
	content: '\effc';
}

.ph-folder-open-light::before {
	content: '\effd';
}

.ph-folder-plus-light::before {
	content: '\effe';
}

.ph-folder-simple-dotted-light::before {
	content: '\efff';
}

.ph-folder-simple-light::before {
	content: '\f000';
}

.ph-folder-simple-lock-light::before {
	content: '\f001';
}

.ph-folder-simple-minus-light::before {
	content: '\f002';
}

.ph-folder-simple-plus-light::before {
	content: '\f003';
}

.ph-folder-simple-star-light::before {
	content: '\f004';
}

.ph-folder-simple-user-light::before {
	content: '\f005';
}

.ph-folder-star-light::before {
	content: '\f006';
}

.ph-folder-user-light::before {
	content: '\f007';
}

.ph-folders-light::before {
	content: '\f008';
}

.ph-football-light::before {
	content: '\f009';
}

.ph-fork-knife-light::before {
	content: '\f00a';
}

.ph-frame-corners-light::before {
	content: '\f00b';
}

.ph-framer-logo-light::before {
	content: '\f00c';
}

.ph-function-light::before {
	content: '\f00d';
}

.ph-funnel-light::before {
	content: '\f00e';
}

.ph-funnel-simple-light::before {
	content: '\f00f';
}

.ph-game-controller-light::before {
	content: '\f010';
}

.ph-gas-pump-light::before {
	content: '\f011';
}

.ph-gauge-light::before {
	content: '\f012';
}

.ph-gear-light::before {
	content: '\f013';
}

.ph-gear-six-light::before {
	content: '\f014';
}

.ph-gender-female-light::before {
	content: '\f015';
}

.ph-gender-intersex-light::before {
	content: '\f016';
}

.ph-gender-male-light::before {
	content: '\f017';
}

.ph-gender-neuter-light::before {
	content: '\f018';
}

.ph-gender-nonbinary-light::before {
	content: '\f019';
}

.ph-gender-transgender-light::before {
	content: '\f01a';
}

.ph-ghost-light::before {
	content: '\f01b';
}

.ph-gif-light::before {
	content: '\f01c';
}

.ph-gift-light::before {
	content: '\f01d';
}

.ph-git-branch-light::before {
	content: '\f01e';
}

.ph-git-commit-light::before {
	content: '\f01f';
}

.ph-git-diff-light::before {
	content: '\f020';
}

.ph-git-fork-light::before {
	content: '\f021';
}

.ph-git-merge-light::before {
	content: '\f022';
}

.ph-git-pull-request-light::before {
	content: '\f023';
}

.ph-github-logo-light::before {
	content: '\f024';
}

.ph-gitlab-logo-light::before {
	content: '\f025';
}

.ph-gitlab-logo-simple-light::before {
	content: '\f026';
}

.ph-globe-hemisphere-east-light::before {
	content: '\f027';
}

.ph-globe-hemisphere-west-light::before {
	content: '\f028';
}

.ph-globe-light::before {
	content: '\f029';
}

.ph-globe-simple-light::before {
	content: '\f02a';
}

.ph-globe-stand-light::before {
	content: '\f02b';
}

.ph-google-chrome-logo-light::before {
	content: '\f02c';
}

.ph-google-logo-light::before {
	content: '\f02d';
}

.ph-google-photos-logo-light::before {
	content: '\f02e';
}

.ph-google-play-logo-light::before {
	content: '\f02f';
}

.ph-google-podcasts-logo-light::before {
	content: '\f030';
}

.ph-gradient-light::before {
	content: '\f031';
}

.ph-graduation-cap-light::before {
	content: '\f032';
}

.ph-graph-light::before {
	content: '\f033';
}

.ph-grid-four-light::before {
	content: '\f034';
}

.ph-hamburger-light::before {
	content: '\f035';
}

.ph-hand-eye-light::before {
	content: '\f036';
}

.ph-hand-fist-light::before {
	content: '\f037';
}

.ph-hand-grabbing-light::before {
	content: '\f038';
}

.ph-hand-light::before {
	content: '\f039';
}

.ph-hand-palm-light::before {
	content: '\f03a';
}

.ph-hand-pointing-light::before {
	content: '\f03b';
}

.ph-hand-soap-light::before {
	content: '\f03c';
}

.ph-hand-waving-light::before {
	content: '\f03d';
}

.ph-handbag-light::before {
	content: '\f03e';
}

.ph-handbag-simple-light::before {
	content: '\f03f';
}

.ph-hands-clapping-light::before {
	content: '\f040';
}

.ph-handshake-light::before {
	content: '\f041';
}

.ph-hard-drive-light::before {
	content: '\f042';
}

.ph-hard-drives-light::before {
	content: '\f043';
}

.ph-hash-light::before {
	content: '\f044';
}

.ph-hash-straight-light::before {
	content: '\f045';
}

.ph-headlights-light::before {
	content: '\f046';
}

.ph-headphones-light::before {
	content: '\f047';
}

.ph-headset-light::before {
	content: '\f048';
}

.ph-heart-break-light::before {
	content: '\f049';
}

.ph-heart-light::before {
	content: '\f04a';
}

.ph-heart-straight-break-light::before {
	content: '\f04b';
}

.ph-heart-straight-light::before {
	content: '\f04c';
}

.ph-heartbeat-light::before {
	content: '\f04d';
}

.ph-hexagon-light::before {
	content: '\f04e';
}

.ph-highlighter-circle-light::before {
	content: '\f04f';
}

.ph-horse-light::before {
	content: '\f050';
}

.ph-hourglass-high-light::before {
	content: '\f051';
}

.ph-hourglass-light::before {
	content: '\f052';
}

.ph-hourglass-low-light::before {
	content: '\f053';
}

.ph-hourglass-medium-light::before {
	content: '\f054';
}

.ph-hourglass-simple-high-light::before {
	content: '\f055';
}

.ph-hourglass-simple-light::before {
	content: '\f056';
}

.ph-hourglass-simple-low-light::before {
	content: '\f057';
}

.ph-hourglass-simple-medium-light::before {
	content: '\f058';
}

.ph-house-light::before {
	content: '\f059';
}

.ph-house-line-light::before {
	content: '\f05a';
}

.ph-house-simple-light::before {
	content: '\f05b';
}

.ph-identification-badge-light::before {
	content: '\f05c';
}

.ph-identification-card-light::before {
	content: '\f05d';
}

.ph-image-light::before {
	content: '\f05e';
}

.ph-image-square-light::before {
	content: '\f05f';
}

.ph-infinity-light::before {
	content: '\f060';
}

.ph-info-light::before {
	content: '\f061';
}

.ph-instagram-logo-light::before {
	content: '\f062';
}

.ph-intersect-light::before {
	content: '\f063';
}

.ph-jeep-light::before {
	content: '\f064';
}

.ph-kanban-light::before {
	content: '\f065';
}

.ph-key-light::before {
	content: '\f066';
}

.ph-key-return-light::before {
	content: '\f067';
}

.ph-keyboard-light::before {
	content: '\f068';
}

.ph-keyhole-light::before {
	content: '\f069';
}

.ph-knife-light::before {
	content: '\f06a';
}

.ph-ladder-light::before {
	content: '\f06b';
}

.ph-ladder-simple-light::before {
	content: '\f06c';
}

.ph-lamp-light::before {
	content: '\f06d';
}

.ph-laptop-light::before {
	content: '\f06e';
}

.ph-layout-light::before {
	content: '\f06f';
}

.ph-leaf-light::before {
	content: '\f070';
}

.ph-lifebuoy-light::before {
	content: '\f071';
}

.ph-lightbulb-filament-light::before {
	content: '\f072';
}

.ph-lightbulb-light::before {
	content: '\f073';
}

.ph-lightning-light::before {
	content: '\f074';
}

.ph-lightning-slash-light::before {
	content: '\f075';
}

.ph-line-segment-light::before {
	content: '\f076';
}

.ph-line-segments-light::before {
	content: '\f077';
}

.ph-link-break-light::before {
	content: '\f078';
}

.ph-link-light::before {
	content: '\f079';
}

.ph-link-simple-break-light::before {
	content: '\f07a';
}

.ph-link-simple-horizontal-break-light::before {
	content: '\f07b';
}

.ph-link-simple-horizontal-light::before {
	content: '\f07c';
}

.ph-link-simple-light::before {
	content: '\f07d';
}

.ph-linkedin-logo-light::before {
	content: '\f07e';
}

.ph-linux-logo-light::before {
	content: '\f07f';
}

.ph-list-bullets-light::before {
	content: '\f080';
}

.ph-list-checks-light::before {
	content: '\f081';
}

.ph-list-dashes-light::before {
	content: '\f082';
}

.ph-list-light::before {
	content: '\f083';
}

.ph-list-numbers-light::before {
	content: '\f084';
}

.ph-list-plus-light::before {
	content: '\f085';
}

.ph-lock-key-light::before {
	content: '\f086';
}

.ph-lock-key-open-light::before {
	content: '\f087';
}

.ph-lock-laminated-light::before {
	content: '\f088';
}

.ph-lock-laminated-open-light::before {
	content: '\f089';
}

.ph-lock-light::before {
	content: '\f08a';
}

.ph-lock-open-light::before {
	content: '\f08b';
}

.ph-lock-simple-light::before {
	content: '\f08c';
}

.ph-lock-simple-open-light::before {
	content: '\f08d';
}

.ph-magic-wand-light::before {
	content: '\f08e';
}

.ph-magnet-light::before {
	content: '\f08f';
}

.ph-magnet-straight-light::before {
	content: '\f090';
}

.ph-magnifying-glass-light::before {
	content: '\f091';
}

.ph-magnifying-glass-minus-light::before {
	content: '\f092';
}

.ph-magnifying-glass-plus-light::before {
	content: '\f093';
}

.ph-map-pin-light::before {
	content: '\f094';
}

.ph-map-pin-line-light::before {
	content: '\f095';
}

.ph-map-trifold-light::before {
	content: '\f096';
}

.ph-marker-circle-light::before {
	content: '\f097';
}

.ph-martini-light::before {
	content: '\f098';
}

.ph-mask-happy-light::before {
	content: '\f099';
}

.ph-mask-sad-light::before {
	content: '\f09a';
}

.ph-math-operations-light::before {
	content: '\f09b';
}

.ph-medal-light::before {
	content: '\f09c';
}

.ph-medium-logo-light::before {
	content: '\f09d';
}

.ph-megaphone-light::before {
	content: '\f09e';
}

.ph-megaphone-simple-light::before {
	content: '\f09f';
}

.ph-messenger-logo-light::before {
	content: '\f0a0';
}

.ph-microphone-light::before {
	content: '\f0a1';
}

.ph-microphone-slash-light::before {
	content: '\f0a2';
}

.ph-microphone-stage-light::before {
	content: '\f0a3';
}

.ph-microsoft-excel-logo-light::before {
	content: '\f0a4';
}

.ph-microsoft-powerpoint-logo-light::before {
	content: '\f0a5';
}

.ph-microsoft-teams-logo-light::before {
	content: '\f0a6';
}

.ph-microsoft-word-logo-light::before {
	content: '\f0a7';
}

.ph-minus-circle-light::before {
	content: '\f0a8';
}

.ph-minus-light::before {
	content: '\f0a9';
}

.ph-money-light::before {
	content: '\f0aa';
}

.ph-monitor-light::before {
	content: '\f0ab';
}

.ph-monitor-play-light::before {
	content: '\f0ac';
}

.ph-moon-light::before {
	content: '\f0ad';
}

.ph-moon-stars-light::before {
	content: '\f0ae';
}

.ph-mountains-light::before {
	content: '\f0af';
}

.ph-mouse-light::before {
	content: '\f0b0';
}

.ph-mouse-simple-light::before {
	content: '\f0b1';
}

.ph-music-note-light::before {
	content: '\f0b2';
}

.ph-music-note-simple-light::before {
	content: '\f0b3';
}

.ph-music-notes-light::before {
	content: '\f0b4';
}

.ph-music-notes-plus-light::before {
	content: '\f0b5';
}

.ph-music-notes-simple-light::before {
	content: '\f0b6';
}

.ph-navigation-arrow-light::before {
	content: '\f0b7';
}

.ph-needle-light::before {
	content: '\f0b8';
}

.ph-newspaper-clipping-light::before {
	content: '\f0b9';
}

.ph-newspaper-light::before {
	content: '\f0ba';
}

.ph-note-blank-light::before {
	content: '\f0bb';
}

.ph-note-light::before {
	content: '\f0bc';
}

.ph-note-pencil-light::before {
	content: '\f0bd';
}

.ph-notebook-light::before {
	content: '\f0be';
}

.ph-notepad-light::before {
	content: '\f0bf';
}

.ph-notification-light::before {
	content: '\f0c0';
}

.ph-number-circle-eight-light::before {
	content: '\f0c1';
}

.ph-number-circle-five-light::before {
	content: '\f0c2';
}

.ph-number-circle-four-light::before {
	content: '\f0c3';
}

.ph-number-circle-nine-light::before {
	content: '\f0c4';
}

.ph-number-circle-one-light::before {
	content: '\f0c5';
}

.ph-number-circle-seven-light::before {
	content: '\f0c6';
}

.ph-number-circle-six-light::before {
	content: '\f0c7';
}

.ph-number-circle-three-light::before {
	content: '\f0c8';
}

.ph-number-circle-two-light::before {
	content: '\f0c9';
}

.ph-number-circle-zero-light::before {
	content: '\f0ca';
}

.ph-number-eight-light::before {
	content: '\f0cb';
}

.ph-number-five-light::before {
	content: '\f0cc';
}

.ph-number-four-light::before {
	content: '\f0cd';
}

.ph-number-nine-light::before {
	content: '\f0ce';
}

.ph-number-one-light::before {
	content: '\f0cf';
}

.ph-number-seven-light::before {
	content: '\f0d0';
}

.ph-number-six-light::before {
	content: '\f0d1';
}

.ph-number-square-eight-light::before {
	content: '\f0d2';
}

.ph-number-square-five-light::before {
	content: '\f0d3';
}

.ph-number-square-four-light::before {
	content: '\f0d4';
}

.ph-number-square-nine-light::before {
	content: '\f0d5';
}

.ph-number-square-one-light::before {
	content: '\f0d6';
}

.ph-number-square-seven-light::before {
	content: '\f0d7';
}

.ph-number-square-six-light::before {
	content: '\f0d8';
}

.ph-number-square-three-light::before {
	content: '\f0d9';
}

.ph-number-square-two-light::before {
	content: '\f0da';
}

.ph-number-square-zero-light::before {
	content: '\f0db';
}

.ph-number-three-light::before {
	content: '\f0dc';
}

.ph-number-two-light::before {
	content: '\f0dd';
}

.ph-number-zero-light::before {
	content: '\f0de';
}

.ph-nut-light::before {
	content: '\f0df';
}

.ph-ny-times-logo-light::before {
	content: '\f0e0';
}

.ph-octagon-light::before {
	content: '\f0e1';
}

.ph-option-light::before {
	content: '\f0e2';
}

.ph-package-light::before {
	content: '\f0e3';
}

.ph-paint-brush-broad-light::before {
	content: '\f0e4';
}

.ph-paint-brush-household-light::before {
	content: '\f0e5';
}

.ph-paint-brush-light::before {
	content: '\f0e6';
}

.ph-paint-bucket-light::before {
	content: '\f0e7';
}

.ph-paint-roller-light::before {
	content: '\f0e8';
}

.ph-palette-light::before {
	content: '\f0e9';
}

.ph-paper-plane-light::before {
	content: '\f0ea';
}

.ph-paper-plane-right-light::before {
	content: '\f0eb';
}

.ph-paper-plane-tilt-light::before {
	content: '\f0ec';
}

.ph-paperclip-horizontal-light::before {
	content: '\f0ed';
}

.ph-paperclip-light::before {
	content: '\f0ee';
}

.ph-parachute-light::before {
	content: '\f0ef';
}

.ph-password-light::before {
	content: '\f0f0';
}

.ph-path-light::before {
	content: '\f0f1';
}

.ph-pause-circle-light::before {
	content: '\f0f2';
}

.ph-pause-light::before {
	content: '\f0f3';
}

.ph-paw-print-light::before {
	content: '\f0f4';
}

.ph-peace-light::before {
	content: '\f0f5';
}

.ph-pen-light::before {
	content: '\f0f6';
}

.ph-pen-nib-light::before {
	content: '\f0f7';
}

.ph-pen-nib-straight-light::before {
	content: '\f0f8';
}

.ph-pencil-circle-light::before {
	content: '\f0f9';
}

.ph-pencil-light::before {
	content: '\f0fa';
}

.ph-pencil-line-light::before {
	content: '\f0fb';
}

.ph-pencil-simple-light::before {
	content: '\f0fc';
}

.ph-pencil-simple-line-light::before {
	content: '\f0fd';
}

.ph-percent-light::before {
	content: '\f0fe';
}

.ph-person-light::before {
	content: '\f0ff';
}

.ph-person-simple-light::before {
	content: '\f100';
}

.ph-person-simple-run-light::before {
	content: '\f101';
}

.ph-person-simple-walk-light::before {
	content: '\f102';
}

.ph-perspective-light::before {
	content: '\f103';
}

.ph-phone-call-light::before {
	content: '\f104';
}

.ph-phone-disconnect-light::before {
	content: '\f105';
}

.ph-phone-incoming-light::before {
	content: '\f106';
}

.ph-phone-light::before {
	content: '\f107';
}

.ph-phone-outgoing-light::before {
	content: '\f108';
}

.ph-phone-slash-light::before {
	content: '\f109';
}

.ph-phone-x-light::before {
	content: '\f10a';
}

.ph-phosphor-logo-light::before {
	content: '\f10b';
}

.ph-piano-keys-light::before {
	content: '\f10c';
}

.ph-picture-in-picture-light::before {
	content: '\f10d';
}

.ph-pill-light::before {
	content: '\f10e';
}

.ph-pinterest-logo-light::before {
	content: '\f10f';
}

.ph-pinwheel-light::before {
	content: '\f110';
}

.ph-pizza-light::before {
	content: '\f111';
}

.ph-placeholder-light::before {
	content: '\f112';
}

.ph-planet-light::before {
	content: '\f113';
}

.ph-play-circle-light::before {
	content: '\f114';
}

.ph-play-light::before {
	content: '\f115';
}

.ph-playlist-light::before {
	content: '\f116';
}

.ph-plug-light::before {
	content: '\f117';
}

.ph-plugs-connected-light::before {
	content: '\f118';
}

.ph-plugs-light::before {
	content: '\f119';
}

.ph-plus-circle-light::before {
	content: '\f11a';
}

.ph-plus-light::before {
	content: '\f11b';
}

.ph-plus-minus-light::before {
	content: '\f11c';
}

.ph-poker-chip-light::before {
	content: '\f11d';
}

.ph-police-car-light::before {
	content: '\f11e';
}

.ph-polygon-light::before {
	content: '\f11f';
}

.ph-popcorn-light::before {
	content: '\f120';
}

.ph-power-light::before {
	content: '\f121';
}

.ph-prescription-light::before {
	content: '\f122';
}

.ph-presentation-chart-light::before {
	content: '\f123';
}

.ph-presentation-light::before {
	content: '\f124';
}

.ph-printer-light::before {
	content: '\f125';
}

.ph-prohibit-inset-light::before {
	content: '\f126';
}

.ph-prohibit-light::before {
	content: '\f127';
}

.ph-projector-screen-chart-light::before {
	content: '\f128';
}

.ph-projector-screen-light::before {
	content: '\f129';
}

.ph-push-pin-light::before {
	content: '\f12a';
}

.ph-push-pin-simple-light::before {
	content: '\f12b';
}

.ph-push-pin-simple-slash-light::before {
	content: '\f12c';
}

.ph-push-pin-slash-light::before {
	content: '\f12d';
}

.ph-puzzle-piece-light::before {
	content: '\f12e';
}

.ph-qr-code-light::before {
	content: '\f12f';
}

.ph-question-light::before {
	content: '\f130';
}

.ph-queue-light::before {
	content: '\f131';
}

.ph-quotes-light::before {
	content: '\f132';
}

.ph-radical-light::before {
	content: '\f133';
}

.ph-radio-button-light::before {
	content: '\f134';
}

.ph-radio-light::before {
	content: '\f135';
}

.ph-rainbow-cloud-light::before {
	content: '\f136';
}

.ph-rainbow-light::before {
	content: '\f137';
}

.ph-receipt-light::before {
	content: '\f138';
}

.ph-record-light::before {
	content: '\f139';
}

.ph-rectangle-light::before {
	content: '\f13a';
}

.ph-recycle-light::before {
	content: '\f13b';
}

.ph-reddit-logo-light::before {
	content: '\f13c';
}

.ph-repeat-light::before {
	content: '\f13d';
}

.ph-repeat-once-light::before {
	content: '\f13e';
}

.ph-rewind-circle-light::before {
	content: '\f13f';
}

.ph-rewind-light::before {
	content: '\f140';
}

.ph-robot-light::before {
	content: '\f141';
}

.ph-rocket-launch-light::before {
	content: '\f142';
}

.ph-rocket-light::before {
	content: '\f143';
}

.ph-rows-light::before {
	content: '\f144';
}

.ph-rss-light::before {
	content: '\f145';
}

.ph-rss-simple-light::before {
	content: '\f146';
}

.ph-rug-light::before {
	content: '\f147';
}

.ph-ruler-light::before {
	content: '\f148';
}

.ph-scales-light::before {
	content: '\f149';
}

.ph-scan-light::before {
	content: '\f14a';
}

.ph-scissors-light::before {
	content: '\f14b';
}

.ph-screencast-light::before {
	content: '\f14c';
}

.ph-scribble-loop-light::before {
	content: '\f14d';
}

.ph-scroll-light::before {
	content: '\f14e';
}

.ph-selection-all-light::before {
	content: '\f14f';
}

.ph-selection-background-light::before {
	content: '\f150';
}

.ph-selection-foreground-light::before {
	content: '\f151';
}

.ph-selection-inverse-light::before {
	content: '\f152';
}

.ph-selection-light::before {
	content: '\f153';
}

.ph-selection-plus-light::before {
	content: '\f154';
}

.ph-selection-slash-light::before {
	content: '\f155';
}

.ph-share-light::before {
	content: '\f156';
}

.ph-share-network-light::before {
	content: '\f157';
}

.ph-shield-check-light::before {
	content: '\f158';
}

.ph-shield-checkered-light::before {
	content: '\f159';
}

.ph-shield-chevron-light::before {
	content: '\f15a';
}

.ph-shield-light::before {
	content: '\f15b';
}

.ph-shield-plus-light::before {
	content: '\f15c';
}

.ph-shield-slash-light::before {
	content: '\f15d';
}

.ph-shield-star-light::before {
	content: '\f15e';
}

.ph-shield-warning-light::before {
	content: '\f15f';
}

.ph-shopping-bag-light::before {
	content: '\f160';
}

.ph-shopping-bag-open-light::before {
	content: '\f161';
}

.ph-shopping-cart-light::before {
	content: '\f162';
}

.ph-shopping-cart-simple-light::before {
	content: '\f163';
}

.ph-shower-light::before {
	content: '\f164';
}

.ph-shuffle-angular-light::before {
	content: '\f165';
}

.ph-shuffle-light::before {
	content: '\f166';
}

.ph-shuffle-simple-light::before {
	content: '\f167';
}

.ph-sidebar-light::before {
	content: '\f168';
}

.ph-sidebar-simple-light::before {
	content: '\f169';
}

.ph-sign-in-light::before {
	content: '\f16a';
}

.ph-sign-out-light::before {
	content: '\f16b';
}

.ph-signpost-light::before {
	content: '\f16c';
}

.ph-sim-card-light::before {
	content: '\f16d';
}

.ph-sketch-logo-light::before {
	content: '\f16e';
}

.ph-skip-back-circle-light::before {
	content: '\f16f';
}

.ph-skip-back-light::before {
	content: '\f170';
}

.ph-skip-forward-circle-light::before {
	content: '\f171';
}

.ph-skip-forward-light::before {
	content: '\f172';
}

.ph-skull-light::before {
	content: '\f173';
}

.ph-slack-logo-light::before {
	content: '\f174';
}

.ph-sliders-horizontal-light::before {
	content: '\f175';
}

.ph-sliders-light::before {
	content: '\f176';
}

.ph-smiley-blank-light::before {
	content: '\f177';
}

.ph-smiley-light::before {
	content: '\f178';
}

.ph-smiley-meh-light::before {
	content: '\f179';
}

.ph-smiley-nervous-light::before {
	content: '\f17a';
}

.ph-smiley-sad-light::before {
	content: '\f17b';
}

.ph-smiley-sticker-light::before {
	content: '\f17c';
}

.ph-smiley-wink-light::before {
	content: '\f17d';
}

.ph-smiley-x-eyes-light::before {
	content: '\f17e';
}

.ph-snapchat-logo-light::before {
	content: '\f17f';
}

.ph-snowflake-light::before {
	content: '\f180';
}

.ph-soccer-ball-light::before {
	content: '\f181';
}

.ph-sort-ascending-light::before {
	content: '\f182';
}

.ph-sort-descending-light::before {
	content: '\f183';
}

.ph-spade-light::before {
	content: '\f184';
}

.ph-sparkle-light::before {
	content: '\f185';
}

.ph-speaker-high-light::before {
	content: '\f186';
}

.ph-speaker-low-light::before {
	content: '\f187';
}

.ph-speaker-none-light::before {
	content: '\f188';
}

.ph-speaker-simple-high-light::before {
	content: '\f189';
}

.ph-speaker-simple-low-light::before {
	content: '\f18a';
}

.ph-speaker-simple-none-light::before {
	content: '\f18b';
}

.ph-speaker-simple-slash-light::before {
	content: '\f18c';
}

.ph-speaker-simple-x-light::before {
	content: '\f18d';
}

.ph-speaker-slash-light::before {
	content: '\f18e';
}

.ph-speaker-x-light::before {
	content: '\f18f';
}

.ph-spinner-gap-light::before {
	content: '\f190';
}

.ph-spinner-light::before {
	content: '\f191';
}

.ph-spiral-light::before {
	content: '\f192';
}

.ph-spotify-logo-light::before {
	content: '\f193';
}

.ph-square-half-bottom-light::before {
	content: '\f194';
}

.ph-square-half-light::before {
	content: '\f195';
}

.ph-square-light::before {
	content: '\f196';
}

.ph-square-logo-light::before {
	content: '\f197';
}

.ph-squares-four-light::before {
	content: '\f198';
}

.ph-stack-light::before {
	content: '\f199';
}

.ph-stack-overflow-logo-light::before {
	content: '\f19a';
}

.ph-stack-simple-light::before {
	content: '\f19b';
}

.ph-stamp-light::before {
	content: '\f19c';
}

.ph-star-four-light::before {
	content: '\f19d';
}

.ph-star-half-light::before {
	content: '\f19e';
}

.ph-star-light::before {
	content: '\f19f';
}

.ph-sticker-light::before {
	content: '\f1a0';
}

.ph-stop-circle-light::before {
	content: '\f1a1';
}

.ph-stop-light::before {
	content: '\f1a2';
}

.ph-storefront-light::before {
	content: '\f1a3';
}

.ph-strategy-light::before {
	content: '\f1a4';
}

.ph-stripe-logo-light::before {
	content: '\f1a5';
}

.ph-student-light::before {
	content: '\f1a6';
}

.ph-suitcase-light::before {
	content: '\f1a7';
}

.ph-suitcase-simple-light::before {
	content: '\f1a8';
}

.ph-sun-dim-light::before {
	content: '\f1a9';
}

.ph-sun-horizon-light::before {
	content: '\f1aa';
}

.ph-sun-light::before {
	content: '\f1ab';
}

.ph-sunglasses-light::before {
	content: '\f1ac';
}

.ph-swap-light::before {
	content: '\f1ad';
}

.ph-swatches-light::before {
	content: '\f1ae';
}

.ph-sword-light::before {
	content: '\f1af';
}

.ph-syringe-light::before {
	content: '\f1b0';
}

.ph-t-shirt-light::before {
	content: '\f1b1';
}

.ph-table-light::before {
	content: '\f1b2';
}

.ph-tabs-light::before {
	content: '\f1b3';
}

.ph-tag-chevron-light::before {
	content: '\f1b4';
}

.ph-tag-light::before {
	content: '\f1b5';
}

.ph-tag-simple-light::before {
	content: '\f1b6';
}

.ph-target-light::before {
	content: '\f1b7';
}

.ph-taxi-light::before {
	content: '\f1b8';
}

.ph-telegram-logo-light::before {
	content: '\f1b9';
}

.ph-television-light::before {
	content: '\f1ba';
}

.ph-television-simple-light::before {
	content: '\f1bb';
}

.ph-tennis-ball-light::before {
	content: '\f1bc';
}

.ph-terminal-light::before {
	content: '\f1bd';
}

.ph-terminal-window-light::before {
	content: '\f1be';
}

.ph-test-tube-light::before {
	content: '\f1bf';
}

.ph-text-aa-light::before {
	content: '\f1c0';
}

.ph-text-align-center-light::before {
	content: '\f1c1';
}

.ph-text-align-justify-light::before {
	content: '\f1c2';
}

.ph-text-align-left-light::before {
	content: '\f1c3';
}

.ph-text-align-right-light::before {
	content: '\f1c4';
}

.ph-text-bolder-light::before {
	content: '\f1c5';
}

.ph-text-h-five-light::before {
	content: '\f1c6';
}

.ph-text-h-four-light::before {
	content: '\f1c7';
}

.ph-text-h-light::before {
	content: '\f1c8';
}

.ph-text-h-one-light::before {
	content: '\f1c9';
}

.ph-text-h-six-light::before {
	content: '\f1ca';
}

.ph-text-h-three-light::before {
	content: '\f1cb';
}

.ph-text-h-two-light::before {
	content: '\f1cc';
}

.ph-text-indent-light::before {
	content: '\f1cd';
}

.ph-text-italic-light::before {
	content: '\f1ce';
}

.ph-text-outdent-light::before {
	content: '\f1cf';
}

.ph-text-strikethrough-light::before {
	content: '\f1d0';
}

.ph-text-t-light::before {
	content: '\f1d1';
}

.ph-text-underline-light::before {
	content: '\f1d2';
}

.ph-textbox-light::before {
	content: '\f1d3';
}

.ph-thermometer-cold-light::before {
	content: '\f1d4';
}

.ph-thermometer-hot-light::before {
	content: '\f1d5';
}

.ph-thermometer-light::before {
	content: '\f1d6';
}

.ph-thermometer-simple-light::before {
	content: '\f1d7';
}

.ph-thumbs-down-light::before {
	content: '\f1d8';
}

.ph-thumbs-up-light::before {
	content: '\f1d9';
}

.ph-ticket-light::before {
	content: '\f1da';
}

.ph-tiktok-logo-light::before {
	content: '\f1db';
}

.ph-timer-light::before {
	content: '\f1dc';
}

.ph-toggle-left-light::before {
	content: '\f1dd';
}

.ph-toggle-right-light::before {
	content: '\f1de';
}

.ph-toilet-light::before {
	content: '\f1df';
}

.ph-toilet-paper-light::before {
	content: '\f1e0';
}

.ph-tote-light::before {
	content: '\f1e1';
}

.ph-tote-simple-light::before {
	content: '\f1e2';
}

.ph-trademark-registered-light::before {
	content: '\f1e3';
}

.ph-traffic-cone-light::before {
	content: '\f1e4';
}

.ph-traffic-sign-light::before {
	content: '\f1e5';
}

.ph-traffic-signal-light::before {
	content: '\f1e6';
}

.ph-train-light::before {
	content: '\f1e7';
}

.ph-train-regional-light::before {
	content: '\f1e8';
}

.ph-train-simple-light::before {
	content: '\f1e9';
}

.ph-translate-light::before {
	content: '\f1ea';
}

.ph-trash-light::before {
	content: '\f1eb';
}

.ph-trash-simple-light::before {
	content: '\f1ec';
}

.ph-tray-light::before {
	content: '\f1ed';
}

.ph-tree-evergreen-light::before {
	content: '\f1ee';
}

.ph-tree-light::before {
	content: '\f1ef';
}

.ph-tree-structure-light::before {
	content: '\f1f0';
}

.ph-trend-down-light::before {
	content: '\f1f1';
}

.ph-trend-up-light::before {
	content: '\f1f2';
}

.ph-triangle-light::before {
	content: '\f1f3';
}

.ph-trophy-light::before {
	content: '\f1f4';
}

.ph-truck-light::before {
	content: '\f1f5';
}

.ph-twitch-logo-light::before {
	content: '\f1f6';
}

.ph-twitter-logo-light::before {
	content: '\f1f7';
}

.ph-umbrella-light::before {
	content: '\f1f8';
}

.ph-umbrella-simple-light::before {
	content: '\f1f9';
}

.ph-upload-light::before {
	content: '\f1fa';
}

.ph-upload-simple-light::before {
	content: '\f1fb';
}

.ph-user-circle-gear-light::before {
	content: '\f1fc';
}

.ph-user-circle-light::before {
	content: '\f1fd';
}

.ph-user-circle-minus-light::before {
	content: '\f1fe';
}

.ph-user-circle-plus-light::before {
	content: '\f1ff';
}

.ph-user-focus-light::before {
	content: '\f200';
}

.ph-user-gear-light::before {
	content: '\f201';
}

.ph-user-light::before {
	content: '\f202';
}

.ph-user-list-light::before {
	content: '\f203';
}

.ph-user-minus-light::before {
	content: '\f204';
}

.ph-user-plus-light::before {
	content: '\f205';
}

.ph-user-rectangle-light::before {
	content: '\f206';
}

.ph-user-square-light::before {
	content: '\f207';
}

.ph-user-switch-light::before {
	content: '\f208';
}

.ph-users-four-light::before {
	content: '\f209';
}

.ph-users-light::before {
	content: '\f20a';
}

.ph-users-three-light::before {
	content: '\f20b';
}

.ph-vault-light::before {
	content: '\f20c';
}

.ph-vibrate-light::before {
	content: '\f20d';
}

.ph-video-camera-light::before {
	content: '\f20e';
}

.ph-video-camera-slash-light::before {
	content: '\f20f';
}

.ph-vignette-light::before {
	content: '\f210';
}

.ph-voicemail-light::before {
	content: '\f211';
}

.ph-volleyball-light::before {
	content: '\f212';
}

.ph-wall-light::before {
	content: '\f213';
}

.ph-wallet-light::before {
	content: '\f214';
}

.ph-warning-circle-light::before {
	content: '\f215';
}

.ph-warning-light::before {
	content: '\f216';
}

.ph-warning-octagon-light::before {
	content: '\f217';
}

.ph-watch-light::before {
	content: '\f218';
}

.ph-wave-sawtooth-light::before {
	content: '\f219';
}

.ph-wave-sine-light::before {
	content: '\f21a';
}

.ph-wave-square-light::before {
	content: '\f21b';
}

.ph-wave-triangle-light::before {
	content: '\f21c';
}

.ph-waves-light::before {
	content: '\f21d';
}

.ph-webcam-light::before {
	content: '\f21e';
}

.ph-whatsapp-logo-light::before {
	content: '\f21f';
}

.ph-wheelchair-light::before {
	content: '\f220';
}

.ph-wifi-high-light::before {
	content: '\f221';
}

.ph-wifi-low-light::before {
	content: '\f222';
}

.ph-wifi-medium-light::before {
	content: '\f223';
}

.ph-wifi-none-light::before {
	content: '\f224';
}

.ph-wifi-slash-light::before {
	content: '\f225';
}

.ph-wifi-x-light::before {
	content: '\f226';
}

.ph-wind-light::before {
	content: '\f227';
}

.ph-windows-logo-light::before {
	content: '\f228';
}

.ph-wine-light::before {
	content: '\f229';
}

.ph-wrench-light::before {
	content: '\f22a';
}

.ph-x-circle-light::before {
	content: '\f22b';
}

.ph-x-light::before {
	content: '\f22c';
}

.ph-x-square-light::before {
	content: '\f22d';
}

.ph-yin-yang-light::before {
	content: '\f22e';
}

.ph-youtube-logo-light::before {
	content: '\f22f';
}

.ph-activity::before {
	content: '\f230';
}

.ph-address-book::before {
	content: '\f231';
}

.ph-airplane::before {
	content: '\f232';
}

.ph-airplane-in-flight::before {
	content: '\f233';
}

.ph-airplane-landing::before {
	content: '\f234';
}

.ph-airplane-takeoff::before {
	content: '\f235';
}

.ph-airplane-tilt::before {
	content: '\f236';
}

.ph-airplay::before {
	content: '\f237';
}

.ph-alarm::before {
	content: '\f238';
}

.ph-alien::before {
	content: '\f239';
}

.ph-align-bottom::before {
	content: '\f23a';
}

.ph-align-bottom-simple::before {
	content: '\f23b';
}

.ph-align-center-horizontal::before {
	content: '\f23c';
}

.ph-align-center-horizontal-simple::before {
	content: '\f23d';
}

.ph-align-center-vertical::before {
	content: '\f23e';
}

.ph-align-center-vertical-simple::before {
	content: '\f23f';
}

.ph-align-left::before {
	content: '\f240';
}

.ph-align-left-simple::before {
	content: '\f241';
}

.ph-align-right::before {
	content: '\f242';
}

.ph-align-right-simple::before {
	content: '\f243';
}

.ph-align-top::before {
	content: '\f244';
}

.ph-align-top-simple::before {
	content: '\f245';
}

.ph-anchor::before {
	content: '\f246';
}

.ph-anchor-simple::before {
	content: '\f247';
}

.ph-android-logo::before {
	content: '\f248';
}

.ph-angular-logo::before {
	content: '\f249';
}

.ph-aperture::before {
	content: '\f24a';
}

.ph-app-store-logo::before {
	content: '\f24b';
}

.ph-app-window::before {
	content: '\f24c';
}

.ph-apple-logo::before {
	content: '\f24d';
}

.ph-apple-podcasts-logo::before {
	content: '\f24e';
}

.ph-archive::before {
	content: '\f24f';
}

.ph-archive-box::before {
	content: '\f250';
}

.ph-archive-tray::before {
	content: '\f251';
}

.ph-armchair::before {
	content: '\f252';
}

.ph-arrow-arc-left::before {
	content: '\f253';
}

.ph-arrow-arc-right::before {
	content: '\f254';
}

.ph-arrow-bend-double-up-left::before {
	content: '\f255';
}

.ph-arrow-bend-double-up-right::before {
	content: '\f256';
}

.ph-arrow-bend-down-left::before {
	content: '\f257';
}

.ph-arrow-bend-down-right::before {
	content: '\f258';
}

.ph-arrow-bend-left-down::before {
	content: '\f259';
}

.ph-arrow-bend-left-up::before {
	content: '\f25a';
}

.ph-arrow-bend-right-down::before {
	content: '\f25b';
}

.ph-arrow-bend-right-up::before {
	content: '\f25c';
}

.ph-arrow-bend-up-left::before {
	content: '\f25d';
}

.ph-arrow-bend-up-right::before {
	content: '\f25e';
}

.ph-arrow-circle-down::before {
	content: '\f25f';
}

.ph-arrow-circle-down-left::before {
	content: '\f260';
}

.ph-arrow-circle-down-right::before {
	content: '\f261';
}

.ph-arrow-circle-left::before {
	content: '\f262';
}

.ph-arrow-circle-right::before {
	content: '\f263';
}

.ph-arrow-circle-up::before {
	content: '\f264';
}

.ph-arrow-circle-up-left::before {
	content: '\f265';
}

.ph-arrow-circle-up-right::before {
	content: '\f266';
}

.ph-arrow-clockwise::before {
	content: '\f267';
}

.ph-arrow-counter-clockwise::before {
	content: '\f268';
}

.ph-arrow-down::before {
	content: '\f269';
}

.ph-arrow-down-left::before {
	content: '\f26a';
}

.ph-arrow-down-right::before {
	content: '\f26b';
}

.ph-arrow-elbow-down-left::before {
	content: '\f26c';
}

.ph-arrow-elbow-down-right::before {
	content: '\f26d';
}

.ph-arrow-elbow-left::before {
	content: '\f26e';
}

.ph-arrow-elbow-left-down::before {
	content: '\f26f';
}

.ph-arrow-elbow-left-up::before {
	content: '\f270';
}

.ph-arrow-elbow-right::before {
	content: '\f271';
}

.ph-arrow-elbow-right-down::before {
	content: '\f272';
}

.ph-arrow-elbow-right-up::before {
	content: '\f273';
}

.ph-arrow-elbow-up-left::before {
	content: '\f274';
}

.ph-arrow-elbow-up-right::before {
	content: '\f275';
}

.ph-arrow-fat-down::before {
	content: '\f276';
}

.ph-arrow-fat-left::before {
	content: '\f277';
}

.ph-arrow-fat-line-down::before {
	content: '\f278';
}

.ph-arrow-fat-line-left::before {
	content: '\f279';
}

.ph-arrow-fat-line-right::before {
	content: '\f27a';
}

.ph-arrow-fat-line-up::before {
	content: '\f27b';
}

.ph-arrow-fat-lines-down::before {
	content: '\f27c';
}

.ph-arrow-fat-lines-left::before {
	content: '\f27d';
}

.ph-arrow-fat-lines-right::before {
	content: '\f27e';
}

.ph-arrow-fat-lines-up::before {
	content: '\f27f';
}

.ph-arrow-fat-right::before {
	content: '\f280';
}

.ph-arrow-fat-up::before {
	content: '\f281';
}

.ph-arrow-left::before {
	content: '\f282';
}

.ph-arrow-line-down::before {
	content: '\f283';
}

.ph-arrow-line-down-left::before {
	content: '\f284';
}

.ph-arrow-line-down-right::before {
	content: '\f285';
}

.ph-arrow-line-left::before {
	content: '\f286';
}

.ph-arrow-line-right::before {
	content: '\f287';
}

.ph-arrow-line-up::before {
	content: '\f288';
}

.ph-arrow-line-up-left::before {
	content: '\f289';
}

.ph-arrow-line-up-right::before {
	content: '\f28a';
}

.ph-arrow-right::before {
	content: '\f28b';
}

.ph-arrow-square-down::before {
	content: '\f28c';
}

.ph-arrow-square-down-left::before {
	content: '\f28d';
}

.ph-arrow-square-down-right::before {
	content: '\f28e';
}

.ph-arrow-square-in::before {
	content: '\f28f';
}

.ph-arrow-square-left::before {
	content: '\f290';
}

.ph-arrow-square-out::before {
	content: '\f291';
}

.ph-arrow-square-right::before {
	content: '\f292';
}

.ph-arrow-square-up::before {
	content: '\f293';
}

.ph-arrow-square-up-left::before {
	content: '\f294';
}

.ph-arrow-square-up-right::before {
	content: '\f295';
}

.ph-arrow-u-down-left::before {
	content: '\f296';
}

.ph-arrow-u-down-right::before {
	content: '\f297';
}

.ph-arrow-u-left-down::before {
	content: '\f298';
}

.ph-arrow-u-left-up::before {
	content: '\f299';
}

.ph-arrow-u-right-down::before {
	content: '\f29a';
}

.ph-arrow-u-right-up::before {
	content: '\f29b';
}

.ph-arrow-u-up-left::before {
	content: '\f29c';
}

.ph-arrow-u-up-right::before {
	content: '\f29d';
}

.ph-arrow-up::before {
	content: '\f29e';
}

.ph-arrow-up-left::before {
	content: '\f29f';
}

.ph-arrow-up-right::before {
	content: '\f2a0';
}

.ph-arrows-clockwise::before {
	content: '\f2a1';
}

.ph-arrows-counter-clockwise::before {
	content: '\f2a2';
}

.ph-arrows-down-up::before {
	content: '\f2a3';
}

.ph-arrows-horizontal::before {
	content: '\f2a4';
}

.ph-arrows-in::before {
	content: '\f2a5';
}

.ph-arrows-in-cardinal::before {
	content: '\f2a6';
}

.ph-arrows-in-line-horizontal::before {
	content: '\f2a7';
}

.ph-arrows-in-line-vertical::before {
	content: '\f2a8';
}

.ph-arrows-in-simple::before {
	content: '\f2a9';
}

.ph-arrows-left-right::before {
	content: '\f2aa';
}

.ph-arrows-out::before {
	content: '\f2ab';
}

.ph-arrows-out-cardinal::before {
	content: '\f2ac';
}

.ph-arrows-out-line-horizontal::before {
	content: '\f2ad';
}

.ph-arrows-out-line-vertical::before {
	content: '\f2ae';
}

.ph-arrows-out-simple::before {
	content: '\f2af';
}

.ph-arrows-vertical::before {
	content: '\f2b0';
}

.ph-article::before {
	content: '\f2b1';
}

.ph-article-medium::before {
	content: '\f2b2';
}

.ph-article-ny-times::before {
	content: '\f2b3';
}

.ph-asterisk::before {
	content: '\f2b4';
}

.ph-asterisk-simple::before {
	content: '\f2b5';
}

.ph-at::before {
	content: '\f2b6';
}

.ph-atom::before {
	content: '\f2b7';
}

.ph-baby::before {
	content: '\f2b8';
}

.ph-backpack::before {
	content: '\f2b9';
}

.ph-backspace::before {
	content: '\f2ba';
}

.ph-bag::before {
	content: '\f2bb';
}

.ph-bag-simple::before {
	content: '\f2bc';
}

.ph-balloon::before {
	content: '\f2bd';
}

.ph-bandaids::before {
	content: '\f2be';
}

.ph-bank::before {
	content: '\f2bf';
}

.ph-barbell::before {
	content: '\f2c0';
}

.ph-barcode::before {
	content: '\f2c1';
}

.ph-barricade::before {
	content: '\f2c2';
}

.ph-baseball::before {
	content: '\f2c3';
}

.ph-basketball::before {
	content: '\f2c4';
}

.ph-bathtub::before {
	content: '\f2c5';
}

.ph-battery-charging::before {
	content: '\f2c6';
}

.ph-battery-charging-vertical::before {
	content: '\f2c7';
}

.ph-battery-empty::before {
	content: '\f2c8';
}

.ph-battery-full::before {
	content: '\f2c9';
}

.ph-battery-high::before {
	content: '\f2ca';
}

.ph-battery-low::before {
	content: '\f2cb';
}

.ph-battery-medium::before {
	content: '\f2cc';
}

.ph-battery-plus::before {
	content: '\f2cd';
}

.ph-battery-warning::before {
	content: '\f2ce';
}

.ph-battery-warning-vertical::before {
	content: '\f2cf';
}

.ph-bed::before {
	content: '\f2d0';
}

.ph-beer-bottle::before {
	content: '\f2d1';
}

.ph-behance-logo::before {
	content: '\f2d2';
}

.ph-bell::before {
	content: '\f2d3';
}

.ph-bell-ringing::before {
	content: '\f2d4';
}

.ph-bell-simple::before {
	content: '\f2d5';
}

.ph-bell-simple-ringing::before {
	content: '\f2d6';
}

.ph-bell-simple-slash::before {
	content: '\f2d7';
}

.ph-bell-simple-z::before {
	content: '\f2d8';
}

.ph-bell-slash::before {
	content: '\f2d9';
}

.ph-bell-z::before {
	content: '\f2da';
}

.ph-bezier-curve::before {
	content: '\f2db';
}

.ph-bicycle::before {
	content: '\f2dc';
}

.ph-binoculars::before {
	content: '\f2dd';
}

.ph-bird::before {
	content: '\f2de';
}

.ph-bluetooth::before {
	content: '\f2df';
}

.ph-bluetooth-connected::before {
	content: '\f2e0';
}

.ph-bluetooth-slash::before {
	content: '\f2e1';
}

.ph-bluetooth-x::before {
	content: '\f2e2';
}

.ph-boat::before {
	content: '\f2e3';
}

.ph-book::before {
	content: '\f2e4';
}

.ph-book-bookmark::before {
	content: '\f2e5';
}

.ph-book-open::before {
	content: '\f2e6';
}

.ph-bookmark::before {
	content: '\f2e7';
}

.ph-bookmark-simple::before {
	content: '\f2e8';
}

.ph-bookmarks::before {
	content: '\f2e9';
}

.ph-bookmarks-simple::before {
	content: '\f2ea';
}

.ph-books::before {
	content: '\f2eb';
}

.ph-bounding-box::before {
	content: '\f2ec';
}

.ph-brackets-angle::before {
	content: '\f2ed';
}

.ph-brackets-curly::before {
	content: '\f2ee';
}

.ph-brackets-round::before {
	content: '\f2ef';
}

.ph-brackets-square::before {
	content: '\f2f0';
}

.ph-brain::before {
	content: '\f2f1';
}

.ph-brandy::before {
	content: '\f2f2';
}

.ph-briefcase::before {
	content: '\f2f3';
}

.ph-briefcase-metal::before {
	content: '\f2f4';
}

.ph-broadcast::before {
	content: '\f2f5';
}

.ph-browser::before {
	content: '\f2f6';
}

.ph-browsers::before {
	content: '\f2f7';
}

.ph-bug::before {
	content: '\f2f8';
}

.ph-bug-beetle::before {
	content: '\f2f9';
}

.ph-bug-droid::before {
	content: '\f2fa';
}

.ph-buildings::before {
	content: '\f2fb';
}

.ph-bus::before {
	content: '\f2fc';
}

.ph-butterfly::before {
	content: '\f2fd';
}

.ph-cactus::before {
	content: '\f2fe';
}

.ph-cake::before {
	content: '\f2ff';
}

.ph-calculator::before {
	content: '\f300';
}

.ph-calendar::before {
	content: '\f301';
}

.ph-calendar-blank::before {
	content: '\f302';
}

.ph-calendar-check::before {
	content: '\f303';
}

.ph-calendar-plus::before {
	content: '\f304';
}

.ph-calendar-x::before {
	content: '\f305';
}

.ph-camera::before {
	content: '\f306';
}

.ph-camera-rotate::before {
	content: '\f307';
}

.ph-camera-slash::before {
	content: '\f308';
}

.ph-campfire::before {
	content: '\f309';
}

.ph-car::before {
	content: '\f30a';
}

.ph-car-simple::before {
	content: '\f30b';
}

.ph-cardholder::before {
	content: '\f30c';
}

.ph-cards::before {
	content: '\f30d';
}

.ph-caret-circle-double-down::before {
	content: '\f30e';
}

.ph-caret-circle-double-left::before {
	content: '\f30f';
}

.ph-caret-circle-double-right::before {
	content: '\f310';
}

.ph-caret-circle-double-up::before {
	content: '\f311';
}

.ph-caret-circle-down::before {
	content: '\f312';
}

.ph-caret-circle-left::before {
	content: '\f313';
}

.ph-caret-circle-right::before {
	content: '\f314';
}

.ph-caret-circle-up::before {
	content: '\f315';
}

.ph-caret-double-down::before {
	content: '\f316';
}

.ph-caret-double-left::before {
	content: '\f317';
}

.ph-caret-double-right::before {
	content: '\f318';
}

.ph-caret-double-up::before {
	content: '\f319';
}

.ph-caret-down::before {
	content: '\f31a';
}

.ph-caret-left::before {
	content: '\f31b';
}

.ph-caret-right::before {
	content: '\f31c';
}

.ph-caret-up::before {
	content: '\f31d';
}

.ph-cat::before {
	content: '\f31e';
}

.ph-cell-signal-full::before {
	content: '\f31f';
}

.ph-cell-signal-high::before {
	content: '\f320';
}

.ph-cell-signal-low::before {
	content: '\f321';
}

.ph-cell-signal-medium::before {
	content: '\f322';
}

.ph-cell-signal-none::before {
	content: '\f323';
}

.ph-cell-signal-slash::before {
	content: '\f324';
}

.ph-cell-signal-x::before {
	content: '\f325';
}

.ph-chalkboard::before {
	content: '\f326';
}

.ph-chalkboard-simple::before {
	content: '\f327';
}

.ph-chalkboard-teacher::before {
	content: '\f328';
}

.ph-chart-bar::before {
	content: '\f329';
}

.ph-chart-bar-horizontal::before {
	content: '\f32a';
}

.ph-chart-line::before {
	content: '\f32b';
}

.ph-chart-line-up::before {
	content: '\f32c';
}

.ph-chart-pie::before {
	content: '\f32d';
}

.ph-chart-pie-slice::before {
	content: '\f32e';
}

.ph-chat::before {
	content: '\f32f';
}

.ph-chat-centered::before {
	content: '\f330';
}

.ph-chat-centered-dots::before {
	content: '\f331';
}

.ph-chat-centered-text::before {
	content: '\f332';
}

.ph-chat-circle::before {
	content: '\f333';
}

.ph-chat-circle-dots::before {
	content: '\f334';
}

.ph-chat-circle-text::before {
	content: '\f335';
}

.ph-chat-dots::before {
	content: '\f336';
}

.ph-chat-teardrop::before {
	content: '\f337';
}

.ph-chat-teardrop-dots::before {
	content: '\f338';
}

.ph-chat-teardrop-text::before {
	content: '\f339';
}

.ph-chat-text::before {
	content: '\f33a';
}

.ph-chats::before {
	content: '\f33b';
}

.ph-chats-circle::before {
	content: '\f33c';
}

.ph-chats-teardrop::before {
	content: '\f33d';
}

.ph-check::before {
	content: '\f33e';
}

.ph-check-circle::before {
	content: '\f33f';
}

.ph-check-square::before {
	content: '\f340';
}

.ph-check-square-offset::before {
	content: '\f341';
}

.ph-checks::before {
	content: '\f342';
}

.ph-circle::before {
	content: '\f343';
}

.ph-circle-dashed::before {
	content: '\f344';
}

.ph-circle-half::before {
	content: '\f345';
}

.ph-circle-half-tilt::before {
	content: '\f346';
}

.ph-circle-notch::before {
	content: '\f347';
}

.ph-circle-wavy::before {
	content: '\f348';
}

.ph-circle-wavy-check::before {
	content: '\f349';
}

.ph-circle-wavy-question::before {
	content: '\f34a';
}

.ph-circle-wavy-warning::before {
	content: '\f34b';
}

.ph-circles-four::before {
	content: '\f34c';
}

.ph-circles-three::before {
	content: '\f34d';
}

.ph-circles-three-plus::before {
	content: '\f34e';
}

.ph-clipboard::before {
	content: '\f34f';
}

.ph-clipboard-text::before {
	content: '\f350';
}

.ph-clock::before {
	content: '\f351';
}

.ph-clock-afternoon::before {
	content: '\f352';
}

.ph-clock-clockwise::before {
	content: '\f353';
}

.ph-clock-counter-clockwise::before {
	content: '\f354';
}

.ph-closed-captioning::before {
	content: '\f355';
}

.ph-cloud::before {
	content: '\f356';
}

.ph-cloud-arrow-down::before {
	content: '\f357';
}

.ph-cloud-arrow-up::before {
	content: '\f358';
}

.ph-cloud-check::before {
	content: '\f359';
}

.ph-cloud-fog::before {
	content: '\f35a';
}

.ph-cloud-lightning::before {
	content: '\f35b';
}

.ph-cloud-moon::before {
	content: '\f35c';
}

.ph-cloud-rain::before {
	content: '\f35d';
}

.ph-cloud-slash::before {
	content: '\f35e';
}

.ph-cloud-snow::before {
	content: '\f35f';
}

.ph-cloud-sun::before {
	content: '\f360';
}

.ph-club::before {
	content: '\f361';
}

.ph-coat-hanger::before {
	content: '\f362';
}

.ph-code::before {
	content: '\f363';
}

.ph-code-simple::before {
	content: '\f364';
}

.ph-codepen-logo::before {
	content: '\f365';
}

.ph-codesandbox-logo::before {
	content: '\f366';
}

.ph-coffee::before {
	content: '\f367';
}

.ph-coin::before {
	content: '\f368';
}

.ph-coin-vertical::before {
	content: '\f369';
}

.ph-coins::before {
	content: '\f36a';
}

.ph-columns::before {
	content: '\f36b';
}

.ph-command::before {
	content: '\f36c';
}

.ph-compass::before {
	content: '\f36d';
}

.ph-computer-tower::before {
	content: '\f36e';
}

.ph-confetti::before {
	content: '\f36f';
}

.ph-cookie::before {
	content: '\f370';
}

.ph-cooking-pot::before {
	content: '\f371';
}

.ph-copy::before {
	content: '\f372';
}

.ph-copy-simple::before {
	content: '\f373';
}

.ph-copyleft::before {
	content: '\f374';
}

.ph-copyright::before {
	content: '\f375';
}

.ph-corners-in::before {
	content: '\f376';
}

.ph-corners-out::before {
	content: '\f377';
}

.ph-cpu::before {
	content: '\f378';
}

.ph-credit-card::before {
	content: '\f379';
}

.ph-crop::before {
	content: '\f37a';
}

.ph-crosshair::before {
	content: '\f37b';
}

.ph-crosshair-simple::before {
	content: '\f37c';
}

.ph-crown::before {
	content: '\f37d';
}

.ph-crown-simple::before {
	content: '\f37e';
}

.ph-cube::before {
	content: '\f37f';
}

.ph-currency-btc::before {
	content: '\f380';
}

.ph-currency-circle-dollar::before {
	content: '\f381';
}

.ph-currency-cny::before {
	content: '\f382';
}

.ph-currency-dollar::before {
	content: '\f383';
}

.ph-currency-dollar-simple::before {
	content: '\f384';
}

.ph-currency-eth::before {
	content: '\f385';
}

.ph-currency-eur::before {
	content: '\f386';
}

.ph-currency-gbp::before {
	content: '\f387';
}

.ph-currency-inr::before {
	content: '\f388';
}

.ph-currency-jpy::before {
	content: '\f389';
}

.ph-currency-krw::before {
	content: '\f38a';
}

.ph-currency-kzt::before {
	content: '\f38b';
}

.ph-currency-ngn::before {
	content: '\f38c';
}

.ph-currency-rub::before {
	content: '\f38d';
}

.ph-cursor::before {
	content: '\f38e';
}

.ph-cursor-text::before {
	content: '\f38f';
}

.ph-cylinder::before {
	content: '\f390';
}

.ph-database::before {
	content: '\f391';
}

.ph-desktop::before {
	content: '\f392';
}

.ph-desktop-tower::before {
	content: '\f393';
}

.ph-detective::before {
	content: '\f394';
}

.ph-device-mobile::before {
	content: '\f395';
}

.ph-device-mobile-camera::before {
	content: '\f396';
}

.ph-device-mobile-speaker::before {
	content: '\f397';
}

.ph-device-tablet::before {
	content: '\f398';
}

.ph-device-tablet-camera::before {
	content: '\f399';
}

.ph-device-tablet-speaker::before {
	content: '\f39a';
}

.ph-diamond::before {
	content: '\f39b';
}

.ph-diamonds-four::before {
	content: '\f39c';
}

.ph-dice-five::before {
	content: '\f39d';
}

.ph-dice-four::before {
	content: '\f39e';
}

.ph-dice-one::before {
	content: '\f39f';
}

.ph-dice-six::before {
	content: '\f3a0';
}

.ph-dice-three::before {
	content: '\f3a1';
}

.ph-dice-two::before {
	content: '\f3a2';
}

.ph-disc::before {
	content: '\f3a3';
}

.ph-discord-logo::before {
	content: '\f3a4';
}

.ph-divide::before {
	content: '\f3a5';
}

.ph-dog::before {
	content: '\f3a6';
}

.ph-door::before {
	content: '\f3a7';
}

.ph-dots-nine::before {
	content: '\f3a8';
}

.ph-dots-six::before {
	content: '\f3a9';
}

.ph-dots-six-vertical::before {
	content: '\f3aa';
}

.ph-dots-three::before {
	content: '\f3ab';
}

.ph-dots-three-circle::before {
	content: '\f3ac';
}

.ph-dots-three-circle-vertical::before {
	content: '\f3ad';
}

.ph-dots-three-outline::before {
	content: '\f3ae';
}

.ph-dots-three-outline-vertical::before {
	content: '\f3af';
}

.ph-dots-three-vertical::before {
	content: '\f3b0';
}

.ph-download::before {
	content: '\f3b1';
}

.ph-download-simple::before {
	content: '\f3b2';
}

.ph-dribbble-logo::before {
	content: '\f3b3';
}

.ph-drop::before {
	content: '\f3b4';
}

.ph-drop-half::before {
	content: '\f3b5';
}

.ph-drop-half-bottom::before {
	content: '\f3b6';
}

.ph-ear::before {
	content: '\f3b7';
}

.ph-ear-slash::before {
	content: '\f3b8';
}

.ph-egg::before {
	content: '\f3b9';
}

.ph-egg-crack::before {
	content: '\f3ba';
}

.ph-eject::before {
	content: '\f3bb';
}

.ph-eject-simple::before {
	content: '\f3bc';
}

.ph-envelope::before {
	content: '\f3bd';
}

.ph-envelope-open::before {
	content: '\f3be';
}

.ph-envelope-simple::before {
	content: '\f3bf';
}

.ph-envelope-simple-open::before {
	content: '\f3c0';
}

.ph-equalizer::before {
	content: '\f3c1';
}

.ph-equals::before {
	content: '\f3c2';
}

.ph-eraser::before {
	content: '\f3c3';
}

.ph-exam::before {
	content: '\f3c4';
}

.ph-export::before {
	content: '\f3c5';
}

.ph-eye::before {
	content: '\f3c6';
}

.ph-eye-closed::before {
	content: '\f3c7';
}

.ph-eye-slash::before {
	content: '\f3c8';
}

.ph-eyedropper::before {
	content: '\f3c9';
}

.ph-eyedropper-sample::before {
	content: '\f3ca';
}

.ph-eyeglasses::before {
	content: '\f3cb';
}

.ph-face-mask::before {
	content: '\f3cc';
}

.ph-facebook-logo::before {
	content: '\f3cd';
}

.ph-factory::before {
	content: '\f3ce';
}

.ph-faders::before {
	content: '\f3cf';
}

.ph-faders-horizontal::before {
	content: '\f3d0';
}

.ph-fast-forward::before {
	content: '\f3d1';
}

.ph-fast-forward-circle::before {
	content: '\f3d2';
}

.ph-figma-logo::before {
	content: '\f3d3';
}

.ph-file::before {
	content: '\f3d4';
}

.ph-file-arrow-down::before {
	content: '\f3d5';
}

.ph-file-arrow-up::before {
	content: '\f3d6';
}

.ph-file-audio::before {
	content: '\f3d7';
}

.ph-file-cloud::before {
	content: '\f3d8';
}

.ph-file-code::before {
	content: '\f3d9';
}

.ph-file-css::before {
	content: '\f3da';
}

.ph-file-csv::before {
	content: '\f3db';
}

.ph-file-doc::before {
	content: '\f3dc';
}

.ph-file-dotted::before {
	content: '\f3dd';
}

.ph-file-html::before {
	content: '\f3de';
}

.ph-file-image::before {
	content: '\f3df';
}

.ph-file-jpg::before {
	content: '\f3e0';
}

.ph-file-js::before {
	content: '\f3e1';
}

.ph-file-jsx::before {
	content: '\f3e2';
}

.ph-file-lock::before {
	content: '\f3e3';
}

.ph-file-minus::before {
	content: '\f3e4';
}

.ph-file-pdf::before {
	content: '\f3e5';
}

.ph-file-plus::before {
	content: '\f3e6';
}

.ph-file-png::before {
	content: '\f3e7';
}

.ph-file-ppt::before {
	content: '\f3e8';
}

.ph-file-rs::before {
	content: '\f3e9';
}

.ph-file-search::before {
	content: '\f3ea';
}

.ph-file-text::before {
	content: '\f3eb';
}

.ph-file-ts::before {
	content: '\f3ec';
}

.ph-file-tsx::before {
	content: '\f3ed';
}

.ph-file-video::before {
	content: '\f3ee';
}

.ph-file-vue::before {
	content: '\f3ef';
}

.ph-file-x::before {
	content: '\f3f0';
}

.ph-file-xls::before {
	content: '\f3f1';
}

.ph-file-zip::before {
	content: '\f3f2';
}

.ph-files::before {
	content: '\f3f3';
}

.ph-film-script::before {
	content: '\f3f4';
}

.ph-film-slate::before {
	content: '\f3f5';
}

.ph-film-strip::before {
	content: '\f3f6';
}

.ph-fingerprint::before {
	content: '\f3f7';
}

.ph-fingerprint-simple::before {
	content: '\f3f8';
}

.ph-finn-the-human::before {
	content: '\f3f9';
}

.ph-fire::before {
	content: '\f3fa';
}

.ph-fire-simple::before {
	content: '\f3fb';
}

.ph-first-aid::before {
	content: '\f3fc';
}

.ph-first-aid-kit::before {
	content: '\f3fd';
}

.ph-fish::before {
	content: '\f3fe';
}

.ph-fish-simple::before {
	content: '\f3ff';
}

.ph-flag::before {
	content: '\f400';
}

.ph-flag-banner::before {
	content: '\f401';
}

.ph-flag-checkered::before {
	content: '\f402';
}

.ph-flame::before {
	content: '\f403';
}

.ph-flashlight::before {
	content: '\f404';
}

.ph-flask::before {
	content: '\f405';
}

.ph-floppy-disk::before {
	content: '\f406';
}

.ph-floppy-disk-back::before {
	content: '\f407';
}

.ph-flow-arrow::before {
	content: '\f408';
}

.ph-flower::before {
	content: '\f409';
}

.ph-flower-lotus::before {
	content: '\f40a';
}

.ph-flying-saucer::before {
	content: '\f40b';
}

.ph-folder::before {
	content: '\f40c';
}

.ph-folder-dotted::before {
	content: '\f40d';
}

.ph-folder-lock::before {
	content: '\f40e';
}

.ph-folder-minus::before {
	content: '\f40f';
}

.ph-folder-notch::before {
	content: '\f410';
}

.ph-folder-notch-minus::before {
	content: '\f411';
}

.ph-folder-notch-open::before {
	content: '\f412';
}

.ph-folder-notch-plus::before {
	content: '\f413';
}

.ph-folder-open::before {
	content: '\f414';
}

.ph-folder-plus::before {
	content: '\f415';
}

.ph-folder-simple::before {
	content: '\f416';
}

.ph-folder-simple-dotted::before {
	content: '\f417';
}

.ph-folder-simple-lock::before {
	content: '\f418';
}

.ph-folder-simple-minus::before {
	content: '\f419';
}

.ph-folder-simple-plus::before {
	content: '\f41a';
}

.ph-folder-simple-star::before {
	content: '\f41b';
}

.ph-folder-simple-user::before {
	content: '\f41c';
}

.ph-folder-star::before {
	content: '\f41d';
}

.ph-folder-user::before {
	content: '\f41e';
}

.ph-folders::before {
	content: '\f41f';
}

.ph-football::before {
	content: '\f420';
}

.ph-fork-knife::before {
	content: '\f421';
}

.ph-frame-corners::before {
	content: '\f422';
}

.ph-framer-logo::before {
	content: '\f423';
}

.ph-function::before {
	content: '\f424';
}

.ph-funnel::before {
	content: '\f425';
}

.ph-funnel-simple::before {
	content: '\f426';
}

.ph-game-controller::before {
	content: '\f427';
}

.ph-gas-pump::before {
	content: '\f428';
}

.ph-gauge::before {
	content: '\f429';
}

.ph-gear::before {
	content: '\f42a';
}

.ph-gear-six::before {
	content: '\f42b';
}

.ph-gender-female::before {
	content: '\f42c';
}

.ph-gender-intersex::before {
	content: '\f42d';
}

.ph-gender-male::before {
	content: '\f42e';
}

.ph-gender-neuter::before {
	content: '\f42f';
}

.ph-gender-nonbinary::before {
	content: '\f430';
}

.ph-gender-transgender::before {
	content: '\f431';
}

.ph-ghost::before {
	content: '\f432';
}

.ph-gif::before {
	content: '\f433';
}

.ph-gift::before {
	content: '\f434';
}

.ph-git-branch::before {
	content: '\f435';
}

.ph-git-commit::before {
	content: '\f436';
}

.ph-git-diff::before {
	content: '\f437';
}

.ph-git-fork::before {
	content: '\f438';
}

.ph-git-merge::before {
	content: '\f439';
}

.ph-git-pull-request::before {
	content: '\f43a';
}

.ph-github-logo::before {
	content: '\f43b';
}

.ph-gitlab-logo::before {
	content: '\f43c';
}

.ph-gitlab-logo-simple::before {
	content: '\f43d';
}

.ph-globe::before {
	content: '\f43e';
}

.ph-globe-hemisphere-east::before {
	content: '\f43f';
}

.ph-globe-hemisphere-west::before {
	content: '\f440';
}

.ph-globe-simple::before {
	content: '\f441';
}

.ph-globe-stand::before {
	content: '\f442';
}

.ph-google-chrome-logo::before {
	content: '\f443';
}

.ph-google-logo::before {
	content: '\f444';
}

.ph-google-photos-logo::before {
	content: '\f445';
}

.ph-google-play-logo::before {
	content: '\f446';
}

.ph-google-podcasts-logo::before {
	content: '\f447';
}

.ph-gradient::before {
	content: '\f448';
}

.ph-graduation-cap::before {
	content: '\f449';
}

.ph-graph::before {
	content: '\f44a';
}

.ph-grid-four::before {
	content: '\f44b';
}

.ph-hamburger::before {
	content: '\f44c';
}

.ph-hand::before {
	content: '\f44d';
}

.ph-hand-eye::before {
	content: '\f44e';
}

.ph-hand-fist::before {
	content: '\f44f';
}

.ph-hand-grabbing::before {
	content: '\f450';
}

.ph-hand-palm::before {
	content: '\f451';
}

.ph-hand-pointing::before {
	content: '\f452';
}

.ph-hand-soap::before {
	content: '\f453';
}

.ph-hand-waving::before {
	content: '\f454';
}

.ph-handbag::before {
	content: '\f455';
}

.ph-handbag-simple::before {
	content: '\f456';
}

.ph-hands-clapping::before {
	content: '\f457';
}

.ph-handshake::before {
	content: '\f458';
}

.ph-hard-drive::before {
	content: '\f459';
}

.ph-hard-drives::before {
	content: '\f45a';
}

.ph-hash::before {
	content: '\f45b';
}

.ph-hash-straight::before {
	content: '\f45c';
}

.ph-headlights::before {
	content: '\f45d';
}

.ph-headphones::before {
	content: '\f45e';
}

.ph-headset::before {
	content: '\f45f';
}

.ph-heart::before {
	content: '\f460';
}

.ph-heart-break::before {
	content: '\f461';
}

.ph-heart-straight::before {
	content: '\f462';
}

.ph-heart-straight-break::before {
	content: '\f463';
}

.ph-heartbeat::before {
	content: '\f464';
}

.ph-hexagon::before {
	content: '\f465';
}

.ph-highlighter-circle::before {
	content: '\f466';
}

.ph-horse::before {
	content: '\f467';
}

.ph-hourglass::before {
	content: '\f468';
}

.ph-hourglass-high::before {
	content: '\f469';
}

.ph-hourglass-low::before {
	content: '\f46a';
}

.ph-hourglass-medium::before {
	content: '\f46b';
}

.ph-hourglass-simple::before {
	content: '\f46c';
}

.ph-hourglass-simple-high::before {
	content: '\f46d';
}

.ph-hourglass-simple-low::before {
	content: '\f46e';
}

.ph-hourglass-simple-medium::before {
	content: '\f46f';
}

.ph-house::before {
	content: '\f470';
}

.ph-house-line::before {
	content: '\f471';
}

.ph-house-simple::before {
	content: '\f472';
}

.ph-identification-badge::before {
	content: '\f473';
}

.ph-identification-card::before {
	content: '\f474';
}

.ph-image::before {
	content: '\f475';
}

.ph-image-square::before {
	content: '\f476';
}

.ph-infinity::before {
	content: '\f477';
}

.ph-info::before {
	content: '\f478';
}

.ph-instagram-logo::before {
	content: '\f479';
}

.ph-intersect::before {
	content: '\f47a';
}

.ph-jeep::before {
	content: '\f47b';
}

.ph-kanban::before {
	content: '\f47c';
}

.ph-key::before {
	content: '\f47d';
}

.ph-key-return::before {
	content: '\f47e';
}

.ph-keyboard::before {
	content: '\f47f';
}

.ph-keyhole::before {
	content: '\f480';
}

.ph-knife::before {
	content: '\f481';
}

.ph-ladder::before {
	content: '\f482';
}

.ph-ladder-simple::before {
	content: '\f483';
}

.ph-lamp::before {
	content: '\f484';
}

.ph-laptop::before {
	content: '\f485';
}

.ph-layout::before {
	content: '\f486';
}

.ph-leaf::before {
	content: '\f487';
}

.ph-lifebuoy::before {
	content: '\f488';
}

.ph-lightbulb::before {
	content: '\f489';
}

.ph-lightbulb-filament::before {
	content: '\f48a';
}

.ph-lightning::before {
	content: '\f48b';
}

.ph-lightning-slash::before {
	content: '\f48c';
}

.ph-line-segment::before {
	content: '\f48d';
}

.ph-line-segments::before {
	content: '\f48e';
}

.ph-link::before {
	content: '\f48f';
}

.ph-link-break::before {
	content: '\f490';
}

.ph-link-simple::before {
	content: '\f491';
}

.ph-link-simple-break::before {
	content: '\f492';
}

.ph-link-simple-horizontal::before {
	content: '\f493';
}

.ph-link-simple-horizontal-break::before {
	content: '\f494';
}

.ph-linkedin-logo::before {
	content: '\f495';
}

.ph-linux-logo::before {
	content: '\f496';
}

.ph-list::before {
	content: '\f497';
}

.ph-list-bullets::before {
	content: '\f498';
}

.ph-list-checks::before {
	content: '\f499';
}

.ph-list-dashes::before {
	content: '\f49a';
}

.ph-list-numbers::before {
	content: '\f49b';
}

.ph-list-plus::before {
	content: '\f49c';
}

.ph-lock::before {
	content: '\f49d';
}

.ph-lock-key::before {
	content: '\f49e';
}

.ph-lock-key-open::before {
	content: '\f49f';
}

.ph-lock-laminated::before {
	content: '\f4a0';
}

.ph-lock-laminated-open::before {
	content: '\f4a1';
}

.ph-lock-open::before {
	content: '\f4a2';
}

.ph-lock-simple::before {
	content: '\f4a3';
}

.ph-lock-simple-open::before {
	content: '\f4a4';
}

.ph-magic-wand::before {
	content: '\f4a5';
}

.ph-magnet::before {
	content: '\f4a6';
}

.ph-magnet-straight::before {
	content: '\f4a7';
}

.ph-magnifying-glass::before {
	content: '\f4a8';
}

.ph-magnifying-glass-minus::before {
	content: '\f4a9';
}

.ph-magnifying-glass-plus::before {
	content: '\f4aa';
}

.ph-map-pin::before {
	content: '\f4ab';
}

.ph-map-pin-line::before {
	content: '\f4ac';
}

.ph-map-trifold::before {
	content: '\f4ad';
}

.ph-marker-circle::before {
	content: '\f4ae';
}

.ph-martini::before {
	content: '\f4af';
}

.ph-mask-happy::before {
	content: '\f4b0';
}

.ph-mask-sad::before {
	content: '\f4b1';
}

.ph-math-operations::before {
	content: '\f4b2';
}

.ph-medal::before {
	content: '\f4b3';
}

.ph-medium-logo::before {
	content: '\f4b4';
}

.ph-megaphone::before {
	content: '\f4b5';
}

.ph-megaphone-simple::before {
	content: '\f4b6';
}

.ph-messenger-logo::before {
	content: '\f4b7';
}

.ph-microphone::before {
	content: '\f4b8';
}

.ph-microphone-slash::before {
	content: '\f4b9';
}

.ph-microphone-stage::before {
	content: '\f4ba';
}

.ph-microsoft-excel-logo::before {
	content: '\f4bb';
}

.ph-microsoft-powerpoint-logo::before {
	content: '\f4bc';
}

.ph-microsoft-teams-logo::before {
	content: '\f4bd';
}

.ph-microsoft-word-logo::before {
	content: '\f4be';
}

.ph-minus::before {
	content: '\f4bf';
}

.ph-minus-circle::before {
	content: '\f4c0';
}

.ph-money::before {
	content: '\f4c1';
}

.ph-monitor::before {
	content: '\f4c2';
}

.ph-monitor-play::before {
	content: '\f4c3';
}

.ph-moon::before {
	content: '\f4c4';
}

.ph-moon-stars::before {
	content: '\f4c5';
}

.ph-mountains::before {
	content: '\f4c6';
}

.ph-mouse::before {
	content: '\f4c7';
}

.ph-mouse-simple::before {
	content: '\f4c8';
}

.ph-music-note::before {
	content: '\f4c9';
}

.ph-music-note-simple::before {
	content: '\f4ca';
}

.ph-music-notes::before {
	content: '\f4cb';
}

.ph-music-notes-plus::before {
	content: '\f4cc';
}

.ph-music-notes-simple::before {
	content: '\f4cd';
}

.ph-navigation-arrow::before {
	content: '\f4ce';
}

.ph-needle::before {
	content: '\f4cf';
}

.ph-newspaper::before {
	content: '\f4d0';
}

.ph-newspaper-clipping::before {
	content: '\f4d1';
}

.ph-note::before {
	content: '\f4d2';
}

.ph-note-blank::before {
	content: '\f4d3';
}

.ph-note-pencil::before {
	content: '\f4d4';
}

.ph-notebook::before {
	content: '\f4d5';
}

.ph-notepad::before {
	content: '\f4d6';
}

.ph-notification::before {
	content: '\f4d7';
}

.ph-number-circle-eight::before {
	content: '\f4d8';
}

.ph-number-circle-five::before {
	content: '\f4d9';
}

.ph-number-circle-four::before {
	content: '\f4da';
}

.ph-number-circle-nine::before {
	content: '\f4db';
}

.ph-number-circle-one::before {
	content: '\f4dc';
}

.ph-number-circle-seven::before {
	content: '\f4dd';
}

.ph-number-circle-six::before {
	content: '\f4de';
}

.ph-number-circle-three::before {
	content: '\f4df';
}

.ph-number-circle-two::before {
	content: '\f4e0';
}

.ph-number-circle-zero::before {
	content: '\f4e1';
}

.ph-number-eight::before {
	content: '\f4e2';
}

.ph-number-five::before {
	content: '\f4e3';
}

.ph-number-four::before {
	content: '\f4e4';
}

.ph-number-nine::before {
	content: '\f4e5';
}

.ph-number-one::before {
	content: '\f4e6';
}

.ph-number-seven::before {
	content: '\f4e7';
}

.ph-number-six::before {
	content: '\f4e8';
}

.ph-number-square-eight::before {
	content: '\f4e9';
}

.ph-number-square-five::before {
	content: '\f4ea';
}

.ph-number-square-four::before {
	content: '\f4eb';
}

.ph-number-square-nine::before {
	content: '\f4ec';
}

.ph-number-square-one::before {
	content: '\f4ed';
}

.ph-number-square-seven::before {
	content: '\f4ee';
}

.ph-number-square-six::before {
	content: '\f4ef';
}

.ph-number-square-three::before {
	content: '\f4f0';
}

.ph-number-square-two::before {
	content: '\f4f1';
}

.ph-number-square-zero::before {
	content: '\f4f2';
}

.ph-number-three::before {
	content: '\f4f3';
}

.ph-number-two::before {
	content: '\f4f4';
}

.ph-number-zero::before {
	content: '\f4f5';
}

.ph-nut::before {
	content: '\f4f6';
}

.ph-ny-times-logo::before {
	content: '\f4f7';
}

.ph-octagon::before {
	content: '\f4f8';
}

.ph-option::before {
	content: '\f4f9';
}

.ph-package::before {
	content: '\f4fa';
}

.ph-paint-brush::before {
	content: '\f4fb';
}

.ph-paint-brush-broad::before {
	content: '\f4fc';
}

.ph-paint-brush-household::before {
	content: '\f4fd';
}

.ph-paint-bucket::before {
	content: '\f4fe';
}

.ph-paint-roller::before {
	content: '\f4ff';
}

.ph-palette::before {
	content: '\f500';
}

.ph-paper-plane::before {
	content: '\f501';
}

.ph-paper-plane-right::before {
	content: '\f502';
}

.ph-paper-plane-tilt::before {
	content: '\f503';
}

.ph-paperclip::before {
	content: '\f504';
}

.ph-paperclip-horizontal::before {
	content: '\f505';
}

.ph-parachute::before {
	content: '\f506';
}

.ph-password::before {
	content: '\f507';
}

.ph-path::before {
	content: '\f508';
}

.ph-pause::before {
	content: '\f509';
}

.ph-pause-circle::before {
	content: '\f50a';
}

.ph-paw-print::before {
	content: '\f50b';
}

.ph-peace::before {
	content: '\f50c';
}

.ph-pen::before {
	content: '\f50d';
}

.ph-pen-nib::before {
	content: '\f50e';
}

.ph-pen-nib-straight::before {
	content: '\f50f';
}

.ph-pencil::before {
	content: '\f510';
}

.ph-pencil-circle::before {
	content: '\f511';
}

.ph-pencil-line::before {
	content: '\f512';
}

.ph-pencil-simple::before {
	content: '\f513';
}

.ph-pencil-simple-line::before {
	content: '\f514';
}

.ph-percent::before {
	content: '\f515';
}

.ph-person::before {
	content: '\f516';
}

.ph-person-simple::before {
	content: '\f517';
}

.ph-person-simple-run::before {
	content: '\f518';
}

.ph-person-simple-walk::before {
	content: '\f519';
}

.ph-perspective::before {
	content: '\f51a';
}

.ph-phone::before {
	content: '\f51b';
}

.ph-phone-call::before {
	content: '\f51c';
}

.ph-phone-disconnect::before {
	content: '\f51d';
}

.ph-phone-incoming::before {
	content: '\f51e';
}

.ph-phone-outgoing::before {
	content: '\f51f';
}

.ph-phone-slash::before {
	content: '\f520';
}

.ph-phone-x::before {
	content: '\f521';
}

.ph-phosphor-logo::before {
	content: '\f522';
}

.ph-piano-keys::before {
	content: '\f523';
}

.ph-picture-in-picture::before {
	content: '\f524';
}

.ph-pill::before {
	content: '\f525';
}

.ph-pinterest-logo::before {
	content: '\f526';
}

.ph-pinwheel::before {
	content: '\f527';
}

.ph-pizza::before {
	content: '\f528';
}

.ph-placeholder::before {
	content: '\f529';
}

.ph-planet::before {
	content: '\f52a';
}

.ph-play::before {
	content: '\f52b';
}

.ph-play-circle::before {
	content: '\f52c';
}

.ph-playlist::before {
	content: '\f52d';
}

.ph-plug::before {
	content: '\f52e';
}

.ph-plugs::before {
	content: '\f52f';
}

.ph-plugs-connected::before {
	content: '\f530';
}

.ph-plus::before {
	content: '\f531';
}

.ph-plus-circle::before {
	content: '\f532';
}

.ph-plus-minus::before {
	content: '\f533';
}

.ph-poker-chip::before {
	content: '\f534';
}

.ph-police-car::before {
	content: '\f535';
}

.ph-polygon::before {
	content: '\f536';
}

.ph-popcorn::before {
	content: '\f537';
}

.ph-power::before {
	content: '\f538';
}

.ph-prescription::before {
	content: '\f539';
}

.ph-presentation::before {
	content: '\f53a';
}

.ph-presentation-chart::before {
	content: '\f53b';
}

.ph-printer::before {
	content: '\f53c';
}

.ph-prohibit::before {
	content: '\f53d';
}

.ph-prohibit-inset::before {
	content: '\f53e';
}

.ph-projector-screen::before {
	content: '\f53f';
}

.ph-projector-screen-chart::before {
	content: '\f540';
}

.ph-push-pin::before {
	content: '\f541';
}

.ph-push-pin-simple::before {
	content: '\f542';
}

.ph-push-pin-simple-slash::before {
	content: '\f543';
}

.ph-push-pin-slash::before {
	content: '\f544';
}

.ph-puzzle-piece::before {
	content: '\f545';
}

.ph-qr-code::before {
	content: '\f546';
}

.ph-question::before {
	content: '\f547';
}

.ph-queue::before {
	content: '\f548';
}

.ph-quotes::before {
	content: '\f549';
}

.ph-radical::before {
	content: '\f54a';
}

.ph-radio::before {
	content: '\f54b';
}

.ph-radio-button::before {
	content: '\f54c';
}

.ph-rainbow::before {
	content: '\f54d';
}

.ph-rainbow-cloud::before {
	content: '\f54e';
}

.ph-receipt::before {
	content: '\f54f';
}

.ph-record::before {
	content: '\f550';
}

.ph-rectangle::before {
	content: '\f551';
}

.ph-recycle::before {
	content: '\f552';
}

.ph-reddit-logo::before {
	content: '\f553';
}

.ph-repeat::before {
	content: '\f554';
}

.ph-repeat-once::before {
	content: '\f555';
}

.ph-rewind::before {
	content: '\f556';
}

.ph-rewind-circle::before {
	content: '\f557';
}

.ph-robot::before {
	content: '\f558';
}

.ph-rocket::before {
	content: '\f559';
}

.ph-rocket-launch::before {
	content: '\f55a';
}

.ph-rows::before {
	content: '\f55b';
}

.ph-rss::before {
	content: '\f55c';
}

.ph-rss-simple::before {
	content: '\f55d';
}

.ph-rug::before {
	content: '\f55e';
}

.ph-ruler::before {
	content: '\f55f';
}

.ph-scales::before {
	content: '\f560';
}

.ph-scan::before {
	content: '\f561';
}

.ph-scissors::before {
	content: '\f562';
}

.ph-screencast::before {
	content: '\f563';
}

.ph-scribble-loop::before {
	content: '\f564';
}

.ph-scroll::before {
	content: '\f565';
}

.ph-selection::before {
	content: '\f566';
}

.ph-selection-all::before {
	content: '\f567';
}

.ph-selection-background::before {
	content: '\f568';
}

.ph-selection-foreground::before {
	content: '\f569';
}

.ph-selection-inverse::before {
	content: '\f56a';
}

.ph-selection-plus::before {
	content: '\f56b';
}

.ph-selection-slash::before {
	content: '\f56c';
}

.ph-share::before {
	content: '\f56d';
}

.ph-share-network::before {
	content: '\f56e';
}

.ph-shield::before {
	content: '\f56f';
}

.ph-shield-check::before {
	content: '\f570';
}

.ph-shield-checkered::before {
	content: '\f571';
}

.ph-shield-chevron::before {
	content: '\f572';
}

.ph-shield-plus::before {
	content: '\f573';
}

.ph-shield-slash::before {
	content: '\f574';
}

.ph-shield-star::before {
	content: '\f575';
}

.ph-shield-warning::before {
	content: '\f576';
}

.ph-shopping-bag::before {
	content: '\f577';
}

.ph-shopping-bag-open::before {
	content: '\f578';
}

.ph-shopping-cart::before {
	content: '\f579';
}

.ph-shopping-cart-simple::before {
	content: '\f57a';
}

.ph-shower::before {
	content: '\f57b';
}

.ph-shuffle::before {
	content: '\f57c';
}

.ph-shuffle-angular::before {
	content: '\f57d';
}

.ph-shuffle-simple::before {
	content: '\f57e';
}

.ph-sidebar::before {
	content: '\f57f';
}

.ph-sidebar-simple::before {
	content: '\f580';
}

.ph-sign-in::before {
	content: '\f581';
}

.ph-sign-out::before {
	content: '\f582';
}

.ph-signpost::before {
	content: '\f583';
}

.ph-sim-card::before {
	content: '\f584';
}

.ph-sketch-logo::before {
	content: '\f585';
}

.ph-skip-back::before {
	content: '\f586';
}

.ph-skip-back-circle::before {
	content: '\f587';
}

.ph-skip-forward::before {
	content: '\f588';
}

.ph-skip-forward-circle::before {
	content: '\f589';
}

.ph-skull::before {
	content: '\f58a';
}

.ph-slack-logo::before {
	content: '\f58b';
}

.ph-sliders::before {
	content: '\f58c';
}

.ph-sliders-horizontal::before {
	content: '\f58d';
}

.ph-smiley::before {
	content: '\f58e';
}

.ph-smiley-blank::before {
	content: '\f58f';
}

.ph-smiley-meh::before {
	content: '\f590';
}

.ph-smiley-nervous::before {
	content: '\f591';
}

.ph-smiley-sad::before {
	content: '\f592';
}

.ph-smiley-sticker::before {
	content: '\f593';
}

.ph-smiley-wink::before {
	content: '\f594';
}

.ph-smiley-x-eyes::before {
	content: '\f595';
}

.ph-snapchat-logo::before {
	content: '\f596';
}

.ph-snowflake::before {
	content: '\f597';
}

.ph-soccer-ball::before {
	content: '\f598';
}

.ph-sort-ascending::before {
	content: '\f599';
}

.ph-sort-descending::before {
	content: '\f59a';
}

.ph-spade::before {
	content: '\f59b';
}

.ph-sparkle::before {
	content: '\f59c';
}

.ph-speaker-high::before {
	content: '\f59d';
}

.ph-speaker-low::before {
	content: '\f59e';
}

.ph-speaker-none::before {
	content: '\f59f';
}

.ph-speaker-simple-high::before {
	content: '\f5a0';
}

.ph-speaker-simple-low::before {
	content: '\f5a1';
}

.ph-speaker-simple-none::before {
	content: '\f5a2';
}

.ph-speaker-simple-slash::before {
	content: '\f5a3';
}

.ph-speaker-simple-x::before {
	content: '\f5a4';
}

.ph-speaker-slash::before {
	content: '\f5a5';
}

.ph-speaker-x::before {
	content: '\f5a6';
}

.ph-spinner::before {
	content: '\f5a7';
}

.ph-spinner-gap::before {
	content: '\f5a8';
}

.ph-spiral::before {
	content: '\f5a9';
}

.ph-spotify-logo::before {
	content: '\f5aa';
}

.ph-square::before {
	content: '\f5ab';
}

.ph-square-half::before {
	content: '\f5ac';
}

.ph-square-half-bottom::before {
	content: '\f5ad';
}

.ph-square-logo::before {
	content: '\f5ae';
}

.ph-squares-four::before {
	content: '\f5af';
}

.ph-stack::before {
	content: '\f5b0';
}

.ph-stack-overflow-logo::before {
	content: '\f5b1';
}

.ph-stack-simple::before {
	content: '\f5b2';
}

.ph-stamp::before {
	content: '\f5b3';
}

.ph-star::before {
	content: '\f5b4';
}

.ph-star-four::before {
	content: '\f5b5';
}

.ph-star-half::before {
	content: '\f5b6';
}

.ph-sticker::before {
	content: '\f5b7';
}

.ph-stop::before {
	content: '\f5b8';
}

.ph-stop-circle::before {
	content: '\f5b9';
}

.ph-storefront::before {
	content: '\f5ba';
}

.ph-strategy::before {
	content: '\f5bb';
}

.ph-stripe-logo::before {
	content: '\f5bc';
}

.ph-student::before {
	content: '\f5bd';
}

.ph-suitcase::before {
	content: '\f5be';
}

.ph-suitcase-simple::before {
	content: '\f5bf';
}

.ph-sun::before {
	content: '\f5c0';
}

.ph-sun-dim::before {
	content: '\f5c1';
}

.ph-sun-horizon::before {
	content: '\f5c2';
}

.ph-sunglasses::before {
	content: '\f5c3';
}

.ph-swap::before {
	content: '\f5c4';
}

.ph-swatches::before {
	content: '\f5c5';
}

.ph-sword::before {
	content: '\f5c6';
}

.ph-syringe::before {
	content: '\f5c7';
}

.ph-t-shirt::before {
	content: '\f5c8';
}

.ph-table::before {
	content: '\f5c9';
}

.ph-tabs::before {
	content: '\f5ca';
}

.ph-tag::before {
	content: '\f5cb';
}

.ph-tag-chevron::before {
	content: '\f5cc';
}

.ph-tag-simple::before {
	content: '\f5cd';
}

.ph-target::before {
	content: '\f5ce';
}

.ph-taxi::before {
	content: '\f5cf';
}

.ph-telegram-logo::before {
	content: '\f5d0';
}

.ph-television::before {
	content: '\f5d1';
}

.ph-television-simple::before {
	content: '\f5d2';
}

.ph-tennis-ball::before {
	content: '\f5d3';
}

.ph-terminal::before {
	content: '\f5d4';
}

.ph-terminal-window::before {
	content: '\f5d5';
}

.ph-test-tube::before {
	content: '\f5d6';
}

.ph-text-aa::before {
	content: '\f5d7';
}

.ph-text-align-center::before {
	content: '\f5d8';
}

.ph-text-align-justify::before {
	content: '\f5d9';
}

.ph-text-align-left::before {
	content: '\f5da';
}

.ph-text-align-right::before {
	content: '\f5db';
}

.ph-text-bolder::before {
	content: '\f5dc';
}

.ph-text-h::before {
	content: '\f5dd';
}

.ph-text-h-five::before {
	content: '\f5de';
}

.ph-text-h-four::before {
	content: '\f5df';
}

.ph-text-h-one::before {
	content: '\f5e0';
}

.ph-text-h-six::before {
	content: '\f5e1';
}

.ph-text-h-three::before {
	content: '\f5e2';
}

.ph-text-h-two::before {
	content: '\f5e3';
}

.ph-text-indent::before {
	content: '\f5e4';
}

.ph-text-italic::before {
	content: '\f5e5';
}

.ph-text-outdent::before {
	content: '\f5e6';
}

.ph-text-strikethrough::before {
	content: '\f5e7';
}

.ph-text-t::before {
	content: '\f5e8';
}

.ph-text-underline::before {
	content: '\f5e9';
}

.ph-textbox::before {
	content: '\f5ea';
}

.ph-thermometer::before {
	content: '\f5eb';
}

.ph-thermometer-cold::before {
	content: '\f5ec';
}

.ph-thermometer-hot::before {
	content: '\f5ed';
}

.ph-thermometer-simple::before {
	content: '\f5ee';
}

.ph-thumbs-down::before {
	content: '\f5ef';
}

.ph-thumbs-up::before {
	content: '\f5f0';
}

.ph-ticket::before {
	content: '\f5f1';
}

.ph-tiktok-logo::before {
	content: '\f5f2';
}

.ph-timer::before {
	content: '\f5f3';
}

.ph-toggle-left::before {
	content: '\f5f4';
}

.ph-toggle-right::before {
	content: '\f5f5';
}

.ph-toilet::before {
	content: '\f5f6';
}

.ph-toilet-paper::before {
	content: '\f5f7';
}

.ph-tote::before {
	content: '\f5f8';
}

.ph-tote-simple::before {
	content: '\f5f9';
}

.ph-trademark-registered::before {
	content: '\f5fa';
}

.ph-traffic-cone::before {
	content: '\f5fb';
}

.ph-traffic-sign::before {
	content: '\f5fc';
}

.ph-traffic-signal::before {
	content: '\f5fd';
}

.ph-train::before {
	content: '\f5fe';
}

.ph-train-regional::before {
	content: '\f5ff';
}

.ph-train-simple::before {
	content: '\f600';
}

.ph-translate::before {
	content: '\f601';
}

.ph-trash::before {
	content: '\f602';
}

.ph-trash-simple::before {
	content: '\f603';
}

.ph-tray::before {
	content: '\f604';
}

.ph-tree::before {
	content: '\f605';
}

.ph-tree-evergreen::before {
	content: '\f606';
}

.ph-tree-structure::before {
	content: '\f607';
}

.ph-trend-down::before {
	content: '\f608';
}

.ph-trend-up::before {
	content: '\f609';
}

.ph-triangle::before {
	content: '\f60a';
}

.ph-trophy::before {
	content: '\f60b';
}

.ph-truck::before {
	content: '\f60c';
}

.ph-twitch-logo::before {
	content: '\f60d';
}

.ph-twitter-logo::before {
	content: '\f60e';
}

.ph-umbrella::before {
	content: '\f60f';
}

.ph-umbrella-simple::before {
	content: '\f610';
}

.ph-upload::before {
	content: '\f611';
}

.ph-upload-simple::before {
	content: '\f612';
}

.ph-user::before {
	content: '\f613';
}

.ph-user-circle::before {
	content: '\f614';
}

.ph-user-circle-gear::before {
	content: '\f615';
}

.ph-user-circle-minus::before {
	content: '\f616';
}

.ph-user-circle-plus::before {
	content: '\f617';
}

.ph-user-focus::before {
	content: '\f618';
}

.ph-user-gear::before {
	content: '\f619';
}

.ph-user-list::before {
	content: '\f61a';
}

.ph-user-minus::before {
	content: '\f61b';
}

.ph-user-plus::before {
	content: '\f61c';
}

.ph-user-rectangle::before {
	content: '\f61d';
}

.ph-user-square::before {
	content: '\f61e';
}

.ph-user-switch::before {
	content: '\f61f';
}

.ph-users::before {
	content: '\f620';
}

.ph-users-four::before {
	content: '\f621';
}

.ph-users-three::before {
	content: '\f622';
}

.ph-vault::before {
	content: '\f623';
}

.ph-vibrate::before {
	content: '\f624';
}

.ph-video-camera::before {
	content: '\f625';
}

.ph-video-camera-slash::before {
	content: '\f626';
}

.ph-vignette::before {
	content: '\f627';
}

.ph-voicemail::before {
	content: '\f628';
}

.ph-volleyball::before {
	content: '\f629';
}

.ph-wall::before {
	content: '\f62a';
}

.ph-wallet::before {
	content: '\f62b';
}

.ph-warning::before {
	content: '\f62c';
}

.ph-warning-circle::before {
	content: '\f62d';
}

.ph-warning-octagon::before {
	content: '\f62e';
}

.ph-watch::before {
	content: '\f62f';
}

.ph-wave-sawtooth::before {
	content: '\f630';
}

.ph-wave-sine::before {
	content: '\f631';
}

.ph-wave-square::before {
	content: '\f632';
}

.ph-wave-triangle::before {
	content: '\f633';
}

.ph-waves::before {
	content: '\f634';
}

.ph-webcam::before {
	content: '\f635';
}

.ph-whatsapp-logo::before {
	content: '\f636';
}

.ph-wheelchair::before {
	content: '\f637';
}

.ph-wifi-high::before {
	content: '\f638';
}

.ph-wifi-low::before {
	content: '\f639';
}

.ph-wifi-medium::before {
	content: '\f63a';
}

.ph-wifi-none::before {
	content: '\f63b';
}

.ph-wifi-slash::before {
	content: '\f63c';
}

.ph-wifi-x::before {
	content: '\f63d';
}

.ph-wind::before {
	content: '\f63e';
}

.ph-windows-logo::before {
	content: '\f63f';
}

.ph-wine::before {
	content: '\f640';
}

.ph-wrench::before {
	content: '\f641';
}

.ph-x::before {
	content: '\f642';
}

.ph-x-circle::before {
	content: '\f643';
}

.ph-x-square::before {
	content: '\f644';
}

.ph-yin-yang::before {
	content: '\f645';
}

.ph-youtube-logo::before {
	content: '\f646';
}

.ph-activity-bold::before {
	content: '\f647';
}

.ph-address-book-bold::before {
	content: '\f648';
}

.ph-airplane-bold::before {
	content: '\f649';
}

.ph-airplane-in-flight-bold::before {
	content: '\f64a';
}

.ph-airplane-landing-bold::before {
	content: '\f64b';
}

.ph-airplane-takeoff-bold::before {
	content: '\f64c';
}

.ph-airplane-tilt-bold::before {
	content: '\f64d';
}

.ph-airplay-bold::before {
	content: '\f64e';
}

.ph-alarm-bold::before {
	content: '\f64f';
}

.ph-alien-bold::before {
	content: '\f650';
}

.ph-align-bottom-bold::before {
	content: '\f651';
}

.ph-align-bottom-simple-bold::before {
	content: '\f652';
}

.ph-align-center-horizontal-bold::before {
	content: '\f653';
}

.ph-align-center-horizontal-simple-bold::before {
	content: '\f654';
}

.ph-align-center-vertical-bold::before {
	content: '\f655';
}

.ph-align-center-vertical-simple-bold::before {
	content: '\f656';
}

.ph-align-left-bold::before {
	content: '\f657';
}

.ph-align-left-simple-bold::before {
	content: '\f658';
}

.ph-align-right-bold::before {
	content: '\f659';
}

.ph-align-right-simple-bold::before {
	content: '\f65a';
}

.ph-align-top-bold::before {
	content: '\f65b';
}

.ph-align-top-simple-bold::before {
	content: '\f65c';
}

.ph-anchor-bold::before {
	content: '\f65d';
}

.ph-anchor-simple-bold::before {
	content: '\f65e';
}

.ph-android-logo-bold::before {
	content: '\f65f';
}

.ph-angular-logo-bold::before {
	content: '\f660';
}

.ph-aperture-bold::before {
	content: '\f661';
}

.ph-app-store-logo-bold::before {
	content: '\f662';
}

.ph-app-window-bold::before {
	content: '\f663';
}

.ph-apple-logo-bold::before {
	content: '\f664';
}

.ph-apple-podcasts-logo-bold::before {
	content: '\f665';
}

.ph-archive-bold::before {
	content: '\f666';
}

.ph-archive-box-bold::before {
	content: '\f667';
}

.ph-archive-tray-bold::before {
	content: '\f668';
}

.ph-armchair-bold::before {
	content: '\f669';
}

.ph-arrow-arc-left-bold::before {
	content: '\f66a';
}

.ph-arrow-arc-right-bold::before {
	content: '\f66b';
}

.ph-arrow-bend-double-up-left-bold::before {
	content: '\f66c';
}

.ph-arrow-bend-double-up-right-bold::before {
	content: '\f66d';
}

.ph-arrow-bend-down-left-bold::before {
	content: '\f66e';
}

.ph-arrow-bend-down-right-bold::before {
	content: '\f66f';
}

.ph-arrow-bend-left-down-bold::before {
	content: '\f670';
}

.ph-arrow-bend-left-up-bold::before {
	content: '\f671';
}

.ph-arrow-bend-right-down-bold::before {
	content: '\f672';
}

.ph-arrow-bend-right-up-bold::before {
	content: '\f673';
}

.ph-arrow-bend-up-left-bold::before {
	content: '\f674';
}

.ph-arrow-bend-up-right-bold::before {
	content: '\f675';
}

.ph-arrow-circle-down-bold::before {
	content: '\f676';
}

.ph-arrow-circle-down-left-bold::before {
	content: '\f677';
}

.ph-arrow-circle-down-right-bold::before {
	content: '\f678';
}

.ph-arrow-circle-left-bold::before {
	content: '\f679';
}

.ph-arrow-circle-right-bold::before {
	content: '\f67a';
}

.ph-arrow-circle-up-bold::before {
	content: '\f67b';
}

.ph-arrow-circle-up-left-bold::before {
	content: '\f67c';
}

.ph-arrow-circle-up-right-bold::before {
	content: '\f67d';
}

.ph-arrow-clockwise-bold::before {
	content: '\f67e';
}

.ph-arrow-counter-clockwise-bold::before {
	content: '\f67f';
}

.ph-arrow-down-bold::before {
	content: '\f680';
}

.ph-arrow-down-left-bold::before {
	content: '\f681';
}

.ph-arrow-down-right-bold::before {
	content: '\f682';
}

.ph-arrow-elbow-down-left-bold::before {
	content: '\f683';
}

.ph-arrow-elbow-down-right-bold::before {
	content: '\f684';
}

.ph-arrow-elbow-left-bold::before {
	content: '\f685';
}

.ph-arrow-elbow-left-down-bold::before {
	content: '\f686';
}

.ph-arrow-elbow-left-up-bold::before {
	content: '\f687';
}

.ph-arrow-elbow-right-bold::before {
	content: '\f688';
}

.ph-arrow-elbow-right-down-bold::before {
	content: '\f689';
}

.ph-arrow-elbow-right-up-bold::before {
	content: '\f68a';
}

.ph-arrow-elbow-up-left-bold::before {
	content: '\f68b';
}

.ph-arrow-elbow-up-right-bold::before {
	content: '\f68c';
}

.ph-arrow-fat-down-bold::before {
	content: '\f68d';
}

.ph-arrow-fat-left-bold::before {
	content: '\f68e';
}

.ph-arrow-fat-line-down-bold::before {
	content: '\f68f';
}

.ph-arrow-fat-line-left-bold::before {
	content: '\f690';
}

.ph-arrow-fat-line-right-bold::before {
	content: '\f691';
}

.ph-arrow-fat-line-up-bold::before {
	content: '\f692';
}

.ph-arrow-fat-lines-down-bold::before {
	content: '\f693';
}

.ph-arrow-fat-lines-left-bold::before {
	content: '\f694';
}

.ph-arrow-fat-lines-right-bold::before {
	content: '\f695';
}

.ph-arrow-fat-lines-up-bold::before {
	content: '\f696';
}

.ph-arrow-fat-right-bold::before {
	content: '\f697';
}

.ph-arrow-fat-up-bold::before {
	content: '\f698';
}

.ph-arrow-left-bold::before {
	content: '\f699';
}

.ph-arrow-line-down-bold::before {
	content: '\f69a';
}

.ph-arrow-line-down-left-bold::before {
	content: '\f69b';
}

.ph-arrow-line-down-right-bold::before {
	content: '\f69c';
}

.ph-arrow-line-left-bold::before {
	content: '\f69d';
}

.ph-arrow-line-right-bold::before {
	content: '\f69e';
}

.ph-arrow-line-up-bold::before {
	content: '\f69f';
}

.ph-arrow-line-up-left-bold::before {
	content: '\f6a0';
}

.ph-arrow-line-up-right-bold::before {
	content: '\f6a1';
}

.ph-arrow-right-bold::before {
	content: '\f6a2';
}

.ph-arrow-square-down-bold::before {
	content: '\f6a3';
}

.ph-arrow-square-down-left-bold::before {
	content: '\f6a4';
}

.ph-arrow-square-down-right-bold::before {
	content: '\f6a5';
}

.ph-arrow-square-in-bold::before {
	content: '\f6a6';
}

.ph-arrow-square-left-bold::before {
	content: '\f6a7';
}

.ph-arrow-square-out-bold::before {
	content: '\f6a8';
}

.ph-arrow-square-right-bold::before {
	content: '\f6a9';
}

.ph-arrow-square-up-bold::before {
	content: '\f6aa';
}

.ph-arrow-square-up-left-bold::before {
	content: '\f6ab';
}

.ph-arrow-square-up-right-bold::before {
	content: '\f6ac';
}

.ph-arrow-u-down-left-bold::before {
	content: '\f6ad';
}

.ph-arrow-u-down-right-bold::before {
	content: '\f6ae';
}

.ph-arrow-u-left-down-bold::before {
	content: '\f6af';
}

.ph-arrow-u-left-up-bold::before {
	content: '\f6b0';
}

.ph-arrow-u-right-down-bold::before {
	content: '\f6b1';
}

.ph-arrow-u-right-up-bold::before {
	content: '\f6b2';
}

.ph-arrow-u-up-left-bold::before {
	content: '\f6b3';
}

.ph-arrow-u-up-right-bold::before {
	content: '\f6b4';
}

.ph-arrow-up-bold::before {
	content: '\f6b5';
}

.ph-arrow-up-left-bold::before {
	content: '\f6b6';
}

.ph-arrow-up-right-bold::before {
	content: '\f6b7';
}

.ph-arrows-clockwise-bold::before {
	content: '\f6b8';
}

.ph-arrows-counter-clockwise-bold::before {
	content: '\f6b9';
}

.ph-arrows-down-up-bold::before {
	content: '\f6ba';
}

.ph-arrows-horizontal-bold::before {
	content: '\f6bb';
}

.ph-arrows-in-bold::before {
	content: '\f6bc';
}

.ph-arrows-in-cardinal-bold::before {
	content: '\f6bd';
}

.ph-arrows-in-line-horizontal-bold::before {
	content: '\f6be';
}

.ph-arrows-in-line-vertical-bold::before {
	content: '\f6bf';
}

.ph-arrows-in-simple-bold::before {
	content: '\f6c0';
}

.ph-arrows-left-right-bold::before {
	content: '\f6c1';
}

.ph-arrows-out-bold::before {
	content: '\f6c2';
}

.ph-arrows-out-cardinal-bold::before {
	content: '\f6c3';
}

.ph-arrows-out-line-horizontal-bold::before {
	content: '\f6c4';
}

.ph-arrows-out-line-vertical-bold::before {
	content: '\f6c5';
}

.ph-arrows-out-simple-bold::before {
	content: '\f6c6';
}

.ph-arrows-vertical-bold::before {
	content: '\f6c7';
}

.ph-article-bold::before {
	content: '\f6c8';
}

.ph-article-medium-bold::before {
	content: '\f6c9';
}

.ph-article-ny-times-bold::before {
	content: '\f6ca';
}

.ph-asterisk-bold::before {
	content: '\f6cb';
}

.ph-asterisk-simple-bold::before {
	content: '\f6cc';
}

.ph-at-bold::before {
	content: '\f6cd';
}

.ph-atom-bold::before {
	content: '\f6ce';
}

.ph-baby-bold::before {
	content: '\f6cf';
}

.ph-backpack-bold::before {
	content: '\f6d0';
}

.ph-backspace-bold::before {
	content: '\f6d1';
}

.ph-bag-bold::before {
	content: '\f6d2';
}

.ph-bag-simple-bold::before {
	content: '\f6d3';
}

.ph-balloon-bold::before {
	content: '\f6d4';
}

.ph-bandaids-bold::before {
	content: '\f6d5';
}

.ph-bank-bold::before {
	content: '\f6d6';
}

.ph-barbell-bold::before {
	content: '\f6d7';
}

.ph-barcode-bold::before {
	content: '\f6d8';
}

.ph-barricade-bold::before {
	content: '\f6d9';
}

.ph-baseball-bold::before {
	content: '\f6da';
}

.ph-basketball-bold::before {
	content: '\f6db';
}

.ph-bathtub-bold::before {
	content: '\f6dc';
}

.ph-battery-charging-bold::before {
	content: '\f6dd';
}

.ph-battery-charging-vertical-bold::before {
	content: '\f6de';
}

.ph-battery-empty-bold::before {
	content: '\f6df';
}

.ph-battery-full-bold::before {
	content: '\f6e0';
}

.ph-battery-high-bold::before {
	content: '\f6e1';
}

.ph-battery-low-bold::before {
	content: '\f6e2';
}

.ph-battery-medium-bold::before {
	content: '\f6e3';
}

.ph-battery-plus-bold::before {
	content: '\f6e4';
}

.ph-battery-warning-bold::before {
	content: '\f6e5';
}

.ph-battery-warning-vertical-bold::before {
	content: '\f6e6';
}

.ph-bed-bold::before {
	content: '\f6e7';
}

.ph-beer-bottle-bold::before {
	content: '\f6e8';
}

.ph-behance-logo-bold::before {
	content: '\f6e9';
}

.ph-bell-bold::before {
	content: '\f6ea';
}

.ph-bell-ringing-bold::before {
	content: '\f6eb';
}

.ph-bell-simple-bold::before {
	content: '\f6ec';
}

.ph-bell-simple-ringing-bold::before {
	content: '\f6ed';
}

.ph-bell-simple-slash-bold::before {
	content: '\f6ee';
}

.ph-bell-simple-z-bold::before {
	content: '\f6ef';
}

.ph-bell-slash-bold::before {
	content: '\f6f0';
}

.ph-bell-z-bold::before {
	content: '\f6f1';
}

.ph-bezier-curve-bold::before {
	content: '\f6f2';
}

.ph-bicycle-bold::before {
	content: '\f6f3';
}

.ph-binoculars-bold::before {
	content: '\f6f4';
}

.ph-bird-bold::before {
	content: '\f6f5';
}

.ph-bluetooth-bold::before {
	content: '\f6f6';
}

.ph-bluetooth-connected-bold::before {
	content: '\f6f7';
}

.ph-bluetooth-slash-bold::before {
	content: '\f6f8';
}

.ph-bluetooth-x-bold::before {
	content: '\f6f9';
}

.ph-boat-bold::before {
	content: '\f6fa';
}

.ph-book-bold::before {
	content: '\f6fb';
}

.ph-book-bookmark-bold::before {
	content: '\f6fc';
}

.ph-book-open-bold::before {
	content: '\f6fd';
}

.ph-bookmark-bold::before {
	content: '\f6fe';
}

.ph-bookmark-simple-bold::before {
	content: '\f6ff';
}

.ph-bookmarks-bold::before {
	content: '\f700';
}

.ph-bookmarks-simple-bold::before {
	content: '\f701';
}

.ph-books-bold::before {
	content: '\f702';
}

.ph-bounding-box-bold::before {
	content: '\f703';
}

.ph-brackets-angle-bold::before {
	content: '\f704';
}

.ph-brackets-curly-bold::before {
	content: '\f705';
}

.ph-brackets-round-bold::before {
	content: '\f706';
}

.ph-brackets-square-bold::before {
	content: '\f707';
}

.ph-brain-bold::before {
	content: '\f708';
}

.ph-brandy-bold::before {
	content: '\f709';
}

.ph-briefcase-bold::before {
	content: '\f70a';
}

.ph-briefcase-metal-bold::before {
	content: '\f70b';
}

.ph-broadcast-bold::before {
	content: '\f70c';
}

.ph-browser-bold::before {
	content: '\f70d';
}

.ph-browsers-bold::before {
	content: '\f70e';
}

.ph-bug-beetle-bold::before {
	content: '\f70f';
}

.ph-bug-bold::before {
	content: '\f710';
}

.ph-bug-droid-bold::before {
	content: '\f711';
}

.ph-buildings-bold::before {
	content: '\f712';
}

.ph-bus-bold::before {
	content: '\f713';
}

.ph-butterfly-bold::before {
	content: '\f714';
}

.ph-cactus-bold::before {
	content: '\f715';
}

.ph-cake-bold::before {
	content: '\f716';
}

.ph-calculator-bold::before {
	content: '\f717';
}

.ph-calendar-blank-bold::before {
	content: '\f718';
}

.ph-calendar-bold::before {
	content: '\f719';
}

.ph-calendar-check-bold::before {
	content: '\f71a';
}

.ph-calendar-plus-bold::before {
	content: '\f71b';
}

.ph-calendar-x-bold::before {
	content: '\f71c';
}

.ph-camera-bold::before {
	content: '\f71d';
}

.ph-camera-rotate-bold::before {
	content: '\f71e';
}

.ph-camera-slash-bold::before {
	content: '\f71f';
}

.ph-campfire-bold::before {
	content: '\f720';
}

.ph-car-bold::before {
	content: '\f721';
}

.ph-car-simple-bold::before {
	content: '\f722';
}

.ph-cardholder-bold::before {
	content: '\f723';
}

.ph-cards-bold::before {
	content: '\f724';
}

.ph-caret-circle-double-down-bold::before {
	content: '\f725';
}

.ph-caret-circle-double-left-bold::before {
	content: '\f726';
}

.ph-caret-circle-double-right-bold::before {
	content: '\f727';
}

.ph-caret-circle-double-up-bold::before {
	content: '\f728';
}

.ph-caret-circle-down-bold::before {
	content: '\f729';
}

.ph-caret-circle-left-bold::before {
	content: '\f72a';
}

.ph-caret-circle-right-bold::before {
	content: '\f72b';
}

.ph-caret-circle-up-bold::before {
	content: '\f72c';
}

.ph-caret-double-down-bold::before {
	content: '\f72d';
}

.ph-caret-double-left-bold::before {
	content: '\f72e';
}

.ph-caret-double-right-bold::before {
	content: '\f72f';
}

.ph-caret-double-up-bold::before {
	content: '\f730';
}

.ph-caret-down-bold::before {
	content: '\f731';
}

.ph-caret-left-bold::before {
	content: '\f732';
}

.ph-caret-right-bold::before {
	content: '\f733';
}

.ph-caret-up-bold::before {
	content: '\f734';
}

.ph-cat-bold::before {
	content: '\f735';
}

.ph-cell-signal-full-bold::before {
	content: '\f736';
}

.ph-cell-signal-high-bold::before {
	content: '\f737';
}

.ph-cell-signal-low-bold::before {
	content: '\f738';
}

.ph-cell-signal-medium-bold::before {
	content: '\f739';
}

.ph-cell-signal-none-bold::before {
	content: '\f73a';
}

.ph-cell-signal-slash-bold::before {
	content: '\f73b';
}

.ph-cell-signal-x-bold::before {
	content: '\f73c';
}

.ph-chalkboard-bold::before {
	content: '\f73d';
}

.ph-chalkboard-simple-bold::before {
	content: '\f73e';
}

.ph-chalkboard-teacher-bold::before {
	content: '\f73f';
}

.ph-chart-bar-bold::before {
	content: '\f740';
}

.ph-chart-bar-horizontal-bold::before {
	content: '\f741';
}

.ph-chart-line-bold::before {
	content: '\f742';
}

.ph-chart-line-up-bold::before {
	content: '\f743';
}

.ph-chart-pie-bold::before {
	content: '\f744';
}

.ph-chart-pie-slice-bold::before {
	content: '\f745';
}

.ph-chat-bold::before {
	content: '\f746';
}

.ph-chat-centered-bold::before {
	content: '\f747';
}

.ph-chat-centered-dots-bold::before {
	content: '\f748';
}

.ph-chat-centered-text-bold::before {
	content: '\f749';
}

.ph-chat-circle-bold::before {
	content: '\f74a';
}

.ph-chat-circle-dots-bold::before {
	content: '\f74b';
}

.ph-chat-circle-text-bold::before {
	content: '\f74c';
}

.ph-chat-dots-bold::before {
	content: '\f74d';
}

.ph-chat-teardrop-bold::before {
	content: '\f74e';
}

.ph-chat-teardrop-dots-bold::before {
	content: '\f74f';
}

.ph-chat-teardrop-text-bold::before {
	content: '\f750';
}

.ph-chat-text-bold::before {
	content: '\f751';
}

.ph-chats-bold::before {
	content: '\f752';
}

.ph-chats-circle-bold::before {
	content: '\f753';
}

.ph-chats-teardrop-bold::before {
	content: '\f754';
}

.ph-check-bold::before {
	content: '\f755';
}

.ph-check-circle-bold::before {
	content: '\f756';
}

.ph-check-square-bold::before {
	content: '\f757';
}

.ph-check-square-offset-bold::before {
	content: '\f758';
}

.ph-checks-bold::before {
	content: '\f759';
}

.ph-circle-bold::before {
	content: '\f75a';
}

.ph-circle-dashed-bold::before {
	content: '\f75b';
}

.ph-circle-half-bold::before {
	content: '\f75c';
}

.ph-circle-half-tilt-bold::before {
	content: '\f75d';
}

.ph-circle-notch-bold::before {
	content: '\f75e';
}

.ph-circle-wavy-bold::before {
	content: '\f75f';
}

.ph-circle-wavy-check-bold::before {
	content: '\f760';
}

.ph-circle-wavy-question-bold::before {
	content: '\f761';
}

.ph-circle-wavy-warning-bold::before {
	content: '\f762';
}

.ph-circles-four-bold::before {
	content: '\f763';
}

.ph-circles-three-bold::before {
	content: '\f764';
}

.ph-circles-three-plus-bold::before {
	content: '\f765';
}

.ph-clipboard-bold::before {
	content: '\f766';
}

.ph-clipboard-text-bold::before {
	content: '\f767';
}

.ph-clock-afternoon-bold::before {
	content: '\f768';
}

.ph-clock-bold::before {
	content: '\f769';
}

.ph-clock-clockwise-bold::before {
	content: '\f76a';
}

.ph-clock-counter-clockwise-bold::before {
	content: '\f76b';
}

.ph-closed-captioning-bold::before {
	content: '\f76c';
}

.ph-cloud-arrow-down-bold::before {
	content: '\f76d';
}

.ph-cloud-arrow-up-bold::before {
	content: '\f76e';
}

.ph-cloud-bold::before {
	content: '\f76f';
}

.ph-cloud-check-bold::before {
	content: '\f770';
}

.ph-cloud-fog-bold::before {
	content: '\f771';
}

.ph-cloud-lightning-bold::before {
	content: '\f772';
}

.ph-cloud-moon-bold::before {
	content: '\f773';
}

.ph-cloud-rain-bold::before {
	content: '\f774';
}

.ph-cloud-slash-bold::before {
	content: '\f775';
}

.ph-cloud-snow-bold::before {
	content: '\f776';
}

.ph-cloud-sun-bold::before {
	content: '\f777';
}

.ph-club-bold::before {
	content: '\f778';
}

.ph-coat-hanger-bold::before {
	content: '\f779';
}

.ph-code-bold::before {
	content: '\f77a';
}

.ph-code-simple-bold::before {
	content: '\f77b';
}

.ph-codepen-logo-bold::before {
	content: '\f77c';
}

.ph-codesandbox-logo-bold::before {
	content: '\f77d';
}

.ph-coffee-bold::before {
	content: '\f77e';
}

.ph-coin-bold::before {
	content: '\f77f';
}

.ph-coin-vertical-bold::before {
	content: '\f780';
}

.ph-coins-bold::before {
	content: '\f781';
}

.ph-columns-bold::before {
	content: '\f782';
}

.ph-command-bold::before {
	content: '\f783';
}

.ph-compass-bold::before {
	content: '\f784';
}

.ph-computer-tower-bold::before {
	content: '\f785';
}

.ph-confetti-bold::before {
	content: '\f786';
}

.ph-cookie-bold::before {
	content: '\f787';
}

.ph-cooking-pot-bold::before {
	content: '\f788';
}

.ph-copy-bold::before {
	content: '\f789';
}

.ph-copy-simple-bold::before {
	content: '\f78a';
}

.ph-copyleft-bold::before {
	content: '\f78b';
}

.ph-copyright-bold::before {
	content: '\f78c';
}

.ph-corners-in-bold::before {
	content: '\f78d';
}

.ph-corners-out-bold::before {
	content: '\f78e';
}

.ph-cpu-bold::before {
	content: '\f78f';
}

.ph-credit-card-bold::before {
	content: '\f790';
}

.ph-crop-bold::before {
	content: '\f791';
}

.ph-crosshair-bold::before {
	content: '\f792';
}

.ph-crosshair-simple-bold::before {
	content: '\f793';
}

.ph-crown-bold::before {
	content: '\f794';
}

.ph-crown-simple-bold::before {
	content: '\f795';
}

.ph-cube-bold::before {
	content: '\f796';
}

.ph-currency-btc-bold::before {
	content: '\f797';
}

.ph-currency-circle-dollar-bold::before {
	content: '\f798';
}

.ph-currency-cny-bold::before {
	content: '\f799';
}

.ph-currency-dollar-bold::before {
	content: '\f79a';
}

.ph-currency-dollar-simple-bold::before {
	content: '\f79b';
}

.ph-currency-eth-bold::before {
	content: '\f79c';
}

.ph-currency-eur-bold::before {
	content: '\f79d';
}

.ph-currency-gbp-bold::before {
	content: '\f79e';
}

.ph-currency-inr-bold::before {
	content: '\f79f';
}

.ph-currency-jpy-bold::before {
	content: '\f7a0';
}

.ph-currency-krw-bold::before {
	content: '\f7a1';
}

.ph-currency-kzt-bold::before {
	content: '\f7a2';
}

.ph-currency-ngn-bold::before {
	content: '\f7a3';
}

.ph-currency-rub-bold::before {
	content: '\f7a4';
}

.ph-cursor-bold::before {
	content: '\f7a5';
}

.ph-cursor-text-bold::before {
	content: '\f7a6';
}

.ph-cylinder-bold::before {
	content: '\f7a7';
}

.ph-database-bold::before {
	content: '\f7a8';
}

.ph-desktop-bold::before {
	content: '\f7a9';
}

.ph-desktop-tower-bold::before {
	content: '\f7aa';
}

.ph-detective-bold::before {
	content: '\f7ab';
}

.ph-device-mobile-bold::before {
	content: '\f7ac';
}

.ph-device-mobile-camera-bold::before {
	content: '\f7ad';
}

.ph-device-mobile-speaker-bold::before {
	content: '\f7ae';
}

.ph-device-tablet-bold::before {
	content: '\f7af';
}

.ph-device-tablet-camera-bold::before {
	content: '\f7b0';
}

.ph-device-tablet-speaker-bold::before {
	content: '\f7b1';
}

.ph-diamond-bold::before {
	content: '\f7b2';
}

.ph-diamonds-four-bold::before {
	content: '\f7b3';
}

.ph-dice-five-bold::before {
	content: '\f7b4';
}

.ph-dice-four-bold::before {
	content: '\f7b5';
}

.ph-dice-one-bold::before {
	content: '\f7b6';
}

.ph-dice-six-bold::before {
	content: '\f7b7';
}

.ph-dice-three-bold::before {
	content: '\f7b8';
}

.ph-dice-two-bold::before {
	content: '\f7b9';
}

.ph-disc-bold::before {
	content: '\f7ba';
}

.ph-discord-logo-bold::before {
	content: '\f7bb';
}

.ph-divide-bold::before {
	content: '\f7bc';
}

.ph-dog-bold::before {
	content: '\f7bd';
}

.ph-door-bold::before {
	content: '\f7be';
}

.ph-dots-nine-bold::before {
	content: '\f7bf';
}

.ph-dots-six-bold::before {
	content: '\f7c0';
}

.ph-dots-six-vertical-bold::before {
	content: '\f7c1';
}

.ph-dots-three-bold::before {
	content: '\f7c2';
}

.ph-dots-three-circle-bold::before {
	content: '\f7c3';
}

.ph-dots-three-circle-vertical-bold::before {
	content: '\f7c4';
}

.ph-dots-three-outline-bold::before {
	content: '\f7c5';
}

.ph-dots-three-outline-vertical-bold::before {
	content: '\f7c6';
}

.ph-dots-three-vertical-bold::before {
	content: '\f7c7';
}

.ph-download-bold::before {
	content: '\f7c8';
}

.ph-download-simple-bold::before {
	content: '\f7c9';
}

.ph-dribbble-logo-bold::before {
	content: '\f7ca';
}

.ph-drop-bold::before {
	content: '\f7cb';
}

.ph-drop-half-bold::before {
	content: '\f7cc';
}

.ph-drop-half-bottom-bold::before {
	content: '\f7cd';
}

.ph-ear-bold::before {
	content: '\f7ce';
}

.ph-ear-slash-bold::before {
	content: '\f7cf';
}

.ph-egg-bold::before {
	content: '\f7d0';
}

.ph-egg-crack-bold::before {
	content: '\f7d1';
}

.ph-eject-bold::before {
	content: '\f7d2';
}

.ph-eject-simple-bold::before {
	content: '\f7d3';
}

.ph-envelope-bold::before {
	content: '\f7d4';
}

.ph-envelope-open-bold::before {
	content: '\f7d5';
}

.ph-envelope-simple-bold::before {
	content: '\f7d6';
}

.ph-envelope-simple-open-bold::before {
	content: '\f7d7';
}

.ph-equalizer-bold::before {
	content: '\f7d8';
}

.ph-equals-bold::before {
	content: '\f7d9';
}

.ph-eraser-bold::before {
	content: '\f7da';
}

.ph-exam-bold::before {
	content: '\f7db';
}

.ph-export-bold::before {
	content: '\f7dc';
}

.ph-eye-bold::before {
	content: '\f7dd';
}

.ph-eye-closed-bold::before {
	content: '\f7de';
}

.ph-eye-slash-bold::before {
	content: '\f7df';
}

.ph-eyedropper-bold::before {
	content: '\f7e0';
}

.ph-eyedropper-sample-bold::before {
	content: '\f7e1';
}

.ph-eyeglasses-bold::before {
	content: '\f7e2';
}

.ph-face-mask-bold::before {
	content: '\f7e3';
}

.ph-facebook-logo-bold::before {
	content: '\f7e4';
}

.ph-factory-bold::before {
	content: '\f7e5';
}

.ph-faders-bold::before {
	content: '\f7e6';
}

.ph-faders-horizontal-bold::before {
	content: '\f7e7';
}

.ph-fast-forward-bold::before {
	content: '\f7e8';
}

.ph-fast-forward-circle-bold::before {
	content: '\f7e9';
}

.ph-figma-logo-bold::before {
	content: '\f7ea';
}

.ph-file-arrow-down-bold::before {
	content: '\f7eb';
}

.ph-file-arrow-up-bold::before {
	content: '\f7ec';
}

.ph-file-audio-bold::before {
	content: '\f7ed';
}

.ph-file-bold::before {
	content: '\f7ee';
}

.ph-file-cloud-bold::before {
	content: '\f7ef';
}

.ph-file-code-bold::before {
	content: '\f7f0';
}

.ph-file-css-bold::before {
	content: '\f7f1';
}

.ph-file-csv-bold::before {
	content: '\f7f2';
}

.ph-file-doc-bold::before {
	content: '\f7f3';
}

.ph-file-dotted-bold::before {
	content: '\f7f4';
}

.ph-file-html-bold::before {
	content: '\f7f5';
}

.ph-file-image-bold::before {
	content: '\f7f6';
}

.ph-file-jpg-bold::before {
	content: '\f7f7';
}

.ph-file-js-bold::before {
	content: '\f7f8';
}

.ph-file-jsx-bold::before {
	content: '\f7f9';
}

.ph-file-lock-bold::before {
	content: '\f7fa';
}

.ph-file-minus-bold::before {
	content: '\f7fb';
}

.ph-file-pdf-bold::before {
	content: '\f7fc';
}

.ph-file-plus-bold::before {
	content: '\f7fd';
}

.ph-file-png-bold::before {
	content: '\f7fe';
}

.ph-file-ppt-bold::before {
	content: '\f7ff';
}

.ph-file-rs-bold::before {
	content: '\f800';
}

.ph-file-search-bold::before {
	content: '\f801';
}

.ph-file-text-bold::before {
	content: '\f802';
}

.ph-file-ts-bold::before {
	content: '\f803';
}

.ph-file-tsx-bold::before {
	content: '\f804';
}

.ph-file-video-bold::before {
	content: '\f805';
}

.ph-file-vue-bold::before {
	content: '\f806';
}

.ph-file-x-bold::before {
	content: '\f807';
}

.ph-file-xls-bold::before {
	content: '\f808';
}

.ph-file-zip-bold::before {
	content: '\f809';
}

.ph-files-bold::before {
	content: '\f80a';
}

.ph-film-script-bold::before {
	content: '\f80b';
}

.ph-film-slate-bold::before {
	content: '\f80c';
}

.ph-film-strip-bold::before {
	content: '\f80d';
}

.ph-fingerprint-bold::before {
	content: '\f80e';
}

.ph-fingerprint-simple-bold::before {
	content: '\f80f';
}

.ph-finn-the-human-bold::before {
	content: '\f810';
}

.ph-fire-bold::before {
	content: '\f811';
}

.ph-fire-simple-bold::before {
	content: '\f812';
}

.ph-first-aid-bold::before {
	content: '\f813';
}

.ph-first-aid-kit-bold::before {
	content: '\f814';
}

.ph-fish-bold::before {
	content: '\f815';
}

.ph-fish-simple-bold::before {
	content: '\f816';
}

.ph-flag-banner-bold::before {
	content: '\f817';
}

.ph-flag-bold::before {
	content: '\f818';
}

.ph-flag-checkered-bold::before {
	content: '\f819';
}

.ph-flame-bold::before {
	content: '\f81a';
}

.ph-flashlight-bold::before {
	content: '\f81b';
}

.ph-flask-bold::before {
	content: '\f81c';
}

.ph-floppy-disk-back-bold::before {
	content: '\f81d';
}

.ph-floppy-disk-bold::before {
	content: '\f81e';
}

.ph-flow-arrow-bold::before {
	content: '\f81f';
}

.ph-flower-bold::before {
	content: '\f820';
}

.ph-flower-lotus-bold::before {
	content: '\f821';
}

.ph-flying-saucer-bold::before {
	content: '\f822';
}

.ph-folder-bold::before {
	content: '\f823';
}

.ph-folder-dotted-bold::before {
	content: '\f824';
}

.ph-folder-lock-bold::before {
	content: '\f825';
}

.ph-folder-minus-bold::before {
	content: '\f826';
}

.ph-folder-notch-bold::before {
	content: '\f827';
}

.ph-folder-notch-minus-bold::before {
	content: '\f828';
}

.ph-folder-notch-open-bold::before {
	content: '\f829';
}

.ph-folder-notch-plus-bold::before {
	content: '\f82a';
}

.ph-folder-open-bold::before {
	content: '\f82b';
}

.ph-folder-plus-bold::before {
	content: '\f82c';
}

.ph-folder-simple-bold::before {
	content: '\f82d';
}

.ph-folder-simple-dotted-bold::before {
	content: '\f82e';
}

.ph-folder-simple-lock-bold::before {
	content: '\f82f';
}

.ph-folder-simple-minus-bold::before {
	content: '\f830';
}

.ph-folder-simple-plus-bold::before {
	content: '\f831';
}

.ph-folder-simple-star-bold::before {
	content: '\f832';
}

.ph-folder-simple-user-bold::before {
	content: '\f833';
}

.ph-folder-star-bold::before {
	content: '\f834';
}

.ph-folder-user-bold::before {
	content: '\f835';
}

.ph-folders-bold::before {
	content: '\f836';
}

.ph-football-bold::before {
	content: '\f837';
}

.ph-fork-knife-bold::before {
	content: '\f838';
}

.ph-frame-corners-bold::before {
	content: '\f839';
}

.ph-framer-logo-bold::before {
	content: '\f83a';
}

.ph-function-bold::before {
	content: '\f83b';
}

.ph-funnel-bold::before {
	content: '\f83c';
}

.ph-funnel-simple-bold::before {
	content: '\f83d';
}

.ph-game-controller-bold::before {
	content: '\f83e';
}

.ph-gas-pump-bold::before {
	content: '\f83f';
}

.ph-gauge-bold::before {
	content: '\f840';
}

.ph-gear-bold::before {
	content: '\f841';
}

.ph-gear-six-bold::before {
	content: '\f842';
}

.ph-gender-female-bold::before {
	content: '\f843';
}

.ph-gender-intersex-bold::before {
	content: '\f844';
}

.ph-gender-male-bold::before {
	content: '\f845';
}

.ph-gender-neuter-bold::before {
	content: '\f846';
}

.ph-gender-nonbinary-bold::before {
	content: '\f847';
}

.ph-gender-transgender-bold::before {
	content: '\f848';
}

.ph-ghost-bold::before {
	content: '\f849';
}

.ph-gif-bold::before {
	content: '\f84a';
}

.ph-gift-bold::before {
	content: '\f84b';
}

.ph-git-branch-bold::before {
	content: '\f84c';
}

.ph-git-commit-bold::before {
	content: '\f84d';
}

.ph-git-diff-bold::before {
	content: '\f84e';
}

.ph-git-fork-bold::before {
	content: '\f84f';
}

.ph-git-merge-bold::before {
	content: '\f850';
}

.ph-git-pull-request-bold::before {
	content: '\f851';
}

.ph-github-logo-bold::before {
	content: '\f852';
}

.ph-gitlab-logo-bold::before {
	content: '\f853';
}

.ph-gitlab-logo-simple-bold::before {
	content: '\f854';
}

.ph-globe-bold::before {
	content: '\f855';
}

.ph-globe-hemisphere-east-bold::before {
	content: '\f856';
}

.ph-globe-hemisphere-west-bold::before {
	content: '\f857';
}

.ph-globe-simple-bold::before {
	content: '\f858';
}

.ph-globe-stand-bold::before {
	content: '\f859';
}

.ph-google-chrome-logo-bold::before {
	content: '\f85a';
}

.ph-google-logo-bold::before {
	content: '\f85b';
}

.ph-google-photos-logo-bold::before {
	content: '\f85c';
}

.ph-google-play-logo-bold::before {
	content: '\f85d';
}

.ph-google-podcasts-logo-bold::before {
	content: '\f85e';
}

.ph-gradient-bold::before {
	content: '\f85f';
}

.ph-graduation-cap-bold::before {
	content: '\f860';
}

.ph-graph-bold::before {
	content: '\f861';
}

.ph-grid-four-bold::before {
	content: '\f862';
}

.ph-hamburger-bold::before {
	content: '\f863';
}

.ph-hand-bold::before {
	content: '\f864';
}

.ph-hand-eye-bold::before {
	content: '\f865';
}

.ph-hand-fist-bold::before {
	content: '\f866';
}

.ph-hand-grabbing-bold::before {
	content: '\f867';
}

.ph-hand-palm-bold::before {
	content: '\f868';
}

.ph-hand-pointing-bold::before {
	content: '\f869';
}

.ph-hand-soap-bold::before {
	content: '\f86a';
}

.ph-hand-waving-bold::before {
	content: '\f86b';
}

.ph-handbag-bold::before {
	content: '\f86c';
}

.ph-handbag-simple-bold::before {
	content: '\f86d';
}

.ph-hands-clapping-bold::before {
	content: '\f86e';
}

.ph-handshake-bold::before {
	content: '\f86f';
}

.ph-hard-drive-bold::before {
	content: '\f870';
}

.ph-hard-drives-bold::before {
	content: '\f871';
}

.ph-hash-bold::before {
	content: '\f872';
}

.ph-hash-straight-bold::before {
	content: '\f873';
}

.ph-headlights-bold::before {
	content: '\f874';
}

.ph-headphones-bold::before {
	content: '\f875';
}

.ph-headset-bold::before {
	content: '\f876';
}

.ph-heart-bold::before {
	content: '\f877';
}

.ph-heart-break-bold::before {
	content: '\f878';
}

.ph-heart-straight-bold::before {
	content: '\f879';
}

.ph-heart-straight-break-bold::before {
	content: '\f87a';
}

.ph-heartbeat-bold::before {
	content: '\f87b';
}

.ph-hexagon-bold::before {
	content: '\f87c';
}

.ph-highlighter-circle-bold::before {
	content: '\f87d';
}

.ph-horse-bold::before {
	content: '\f87e';
}

.ph-hourglass-bold::before {
	content: '\f87f';
}

.ph-hourglass-high-bold::before {
	content: '\f880';
}

.ph-hourglass-low-bold::before {
	content: '\f881';
}

.ph-hourglass-medium-bold::before {
	content: '\f882';
}

.ph-hourglass-simple-bold::before {
	content: '\f883';
}

.ph-hourglass-simple-high-bold::before {
	content: '\f884';
}

.ph-hourglass-simple-low-bold::before {
	content: '\f885';
}

.ph-hourglass-simple-medium-bold::before {
	content: '\f886';
}

.ph-house-bold::before {
	content: '\f887';
}

.ph-house-line-bold::before {
	content: '\f888';
}

.ph-house-simple-bold::before {
	content: '\f889';
}

.ph-identification-badge-bold::before {
	content: '\f88a';
}

.ph-identification-card-bold::before {
	content: '\f88b';
}

.ph-image-bold::before {
	content: '\f88c';
}

.ph-image-square-bold::before {
	content: '\f88d';
}

.ph-infinity-bold::before {
	content: '\f88e';
}

.ph-info-bold::before {
	content: '\f88f';
}

.ph-instagram-logo-bold::before {
	content: '\f890';
}

.ph-intersect-bold::before {
	content: '\f891';
}

.ph-jeep-bold::before {
	content: '\f892';
}

.ph-kanban-bold::before {
	content: '\f893';
}

.ph-key-bold::before {
	content: '\f894';
}

.ph-key-return-bold::before {
	content: '\f895';
}

.ph-keyboard-bold::before {
	content: '\f896';
}

.ph-keyhole-bold::before {
	content: '\f897';
}

.ph-knife-bold::before {
	content: '\f898';
}

.ph-ladder-bold::before {
	content: '\f899';
}

.ph-ladder-simple-bold::before {
	content: '\f89a';
}

.ph-lamp-bold::before {
	content: '\f89b';
}

.ph-laptop-bold::before {
	content: '\f89c';
}

.ph-layout-bold::before {
	content: '\f89d';
}

.ph-leaf-bold::before {
	content: '\f89e';
}

.ph-lifebuoy-bold::before {
	content: '\f89f';
}

.ph-lightbulb-bold::before {
	content: '\f8a0';
}

.ph-lightbulb-filament-bold::before {
	content: '\f8a1';
}

.ph-lightning-bold::before {
	content: '\f8a2';
}

.ph-lightning-slash-bold::before {
	content: '\f8a3';
}

.ph-line-segment-bold::before {
	content: '\f8a4';
}

.ph-line-segments-bold::before {
	content: '\f8a5';
}

.ph-link-bold::before {
	content: '\f8a6';
}

.ph-link-break-bold::before {
	content: '\f8a7';
}

.ph-link-simple-bold::before {
	content: '\f8a8';
}

.ph-link-simple-break-bold::before {
	content: '\f8a9';
}

.ph-link-simple-horizontal-bold::before {
	content: '\f8aa';
}

.ph-link-simple-horizontal-break-bold::before {
	content: '\f8ab';
}

.ph-linkedin-logo-bold::before {
	content: '\f8ac';
}

.ph-linux-logo-bold::before {
	content: '\f8ad';
}

.ph-list-bold::before {
	content: '\f8ae';
}

.ph-list-bullets-bold::before {
	content: '\f8af';
}

.ph-list-checks-bold::before {
	content: '\f8b0';
}

.ph-list-dashes-bold::before {
	content: '\f8b1';
}

.ph-list-numbers-bold::before {
	content: '\f8b2';
}

.ph-list-plus-bold::before {
	content: '\f8b3';
}

.ph-lock-bold::before {
	content: '\f8b4';
}

.ph-lock-key-bold::before {
	content: '\f8b5';
}

.ph-lock-key-open-bold::before {
	content: '\f8b6';
}

.ph-lock-laminated-bold::before {
	content: '\f8b7';
}

.ph-lock-laminated-open-bold::before {
	content: '\f8b8';
}

.ph-lock-open-bold::before {
	content: '\f8b9';
}

.ph-lock-simple-bold::before {
	content: '\f8ba';
}

.ph-lock-simple-open-bold::before {
	content: '\f8bb';
}

.ph-magic-wand-bold::before {
	content: '\f8bc';
}

.ph-magnet-bold::before {
	content: '\f8bd';
}

.ph-magnet-straight-bold::before {
	content: '\f8be';
}

.ph-magnifying-glass-bold::before {
	content: '\f8bf';
}

.ph-magnifying-glass-minus-bold::before {
	content: '\f8c0';
}

.ph-magnifying-glass-plus-bold::before {
	content: '\f8c1';
}

.ph-map-pin-bold::before {
	content: '\f8c2';
}

.ph-map-pin-line-bold::before {
	content: '\f8c3';
}

.ph-map-trifold-bold::before {
	content: '\f8c4';
}

.ph-marker-circle-bold::before {
	content: '\f8c5';
}

.ph-martini-bold::before {
	content: '\f8c6';
}

.ph-mask-happy-bold::before {
	content: '\f8c7';
}

.ph-mask-sad-bold::before {
	content: '\f8c8';
}

.ph-math-operations-bold::before {
	content: '\f8c9';
}

.ph-medal-bold::before {
	content: '\f8ca';
}

.ph-medium-logo-bold::before {
	content: '\f8cb';
}

.ph-megaphone-bold::before {
	content: '\f8cc';
}

.ph-megaphone-simple-bold::before {
	content: '\f8cd';
}

.ph-messenger-logo-bold::before {
	content: '\f8ce';
}

.ph-microphone-bold::before {
	content: '\f8cf';
}

.ph-microphone-slash-bold::before {
	content: '\f8d0';
}

.ph-microphone-stage-bold::before {
	content: '\f8d1';
}

.ph-microsoft-excel-logo-bold::before {
	content: '\f8d2';
}

.ph-microsoft-powerpoint-logo-bold::before {
	content: '\f8d3';
}

.ph-microsoft-teams-logo-bold::before {
	content: '\f8d4';
}

.ph-microsoft-word-logo-bold::before {
	content: '\f8d5';
}

.ph-minus-bold::before {
	content: '\f8d6';
}

.ph-minus-circle-bold::before {
	content: '\f8d7';
}

.ph-money-bold::before {
	content: '\f8d8';
}

.ph-monitor-bold::before {
	content: '\f8d9';
}

.ph-monitor-play-bold::before {
	content: '\f8da';
}

.ph-moon-bold::before {
	content: '\f8db';
}

.ph-moon-stars-bold::before {
	content: '\f8dc';
}

.ph-mountains-bold::before {
	content: '\f8dd';
}

.ph-mouse-bold::before {
	content: '\f8de';
}

.ph-mouse-simple-bold::before {
	content: '\f8df';
}

.ph-music-note-bold::before {
	content: '\f8e0';
}

.ph-music-note-simple-bold::before {
	content: '\f8e1';
}

.ph-music-notes-bold::before {
	content: '\f8e2';
}

.ph-music-notes-plus-bold::before {
	content: '\f8e3';
}

.ph-music-notes-simple-bold::before {
	content: '\f8e4';
}

.ph-navigation-arrow-bold::before {
	content: '\f8e5';
}

.ph-needle-bold::before {
	content: '\f8e6';
}

.ph-newspaper-bold::before {
	content: '\f8e7';
}

.ph-newspaper-clipping-bold::before {
	content: '\f8e8';
}

.ph-note-blank-bold::before {
	content: '\f8e9';
}

.ph-note-bold::before {
	content: '\f8ea';
}

.ph-note-pencil-bold::before {
	content: '\f8eb';
}

.ph-notebook-bold::before {
	content: '\f8ec';
}

.ph-notepad-bold::before {
	content: '\f8ed';
}

.ph-notification-bold::before {
	content: '\f8ee';
}

.ph-number-circle-eight-bold::before {
	content: '\f8ef';
}

.ph-number-circle-five-bold::before {
	content: '\f8f0';
}

.ph-number-circle-four-bold::before {
	content: '\f8f1';
}

.ph-number-circle-nine-bold::before {
	content: '\f8f2';
}

.ph-number-circle-one-bold::before {
	content: '\f8f3';
}

.ph-number-circle-seven-bold::before {
	content: '\f8f4';
}

.ph-number-circle-six-bold::before {
	content: '\f8f5';
}

.ph-number-circle-three-bold::before {
	content: '\f8f6';
}

.ph-number-circle-two-bold::before {
	content: '\f8f7';
}

.ph-number-circle-zero-bold::before {
	content: '\f8f8';
}

.ph-number-eight-bold::before {
	content: '\f8f9';
}

.ph-number-five-bold::before {
	content: '\f8fa';
}

.ph-number-four-bold::before {
	content: '\f8fb';
}

.ph-number-nine-bold::before {
	content: '\f8fc';
}

.ph-number-one-bold::before {
	content: '\f8fd';
}

.ph-number-seven-bold::before {
	content: '\f8fe';
}

.ph-number-six-bold::before {
	content: '\f8ff';
}

.ph-number-square-eight-bold::before {
	content: '\f900';
}

.ph-number-square-five-bold::before {
	content: '\f901';
}

.ph-number-square-four-bold::before {
	content: '\f902';
}

.ph-number-square-nine-bold::before {
	content: '\f903';
}

.ph-number-square-one-bold::before {
	content: '\f904';
}

.ph-number-square-seven-bold::before {
	content: '\f905';
}

.ph-number-square-six-bold::before {
	content: '\f906';
}

.ph-number-square-three-bold::before {
	content: '\f907';
}

.ph-number-square-two-bold::before {
	content: '\f908';
}

.ph-number-square-zero-bold::before {
	content: '\f909';
}

.ph-number-three-bold::before {
	content: '\f90a';
}

.ph-number-two-bold::before {
	content: '\f90b';
}

.ph-number-zero-bold::before {
	content: '\f90c';
}

.ph-nut-bold::before {
	content: '\f90d';
}

.ph-ny-times-logo-bold::before {
	content: '\f90e';
}

.ph-octagon-bold::before {
	content: '\f90f';
}

.ph-option-bold::before {
	content: '\f910';
}

.ph-package-bold::before {
	content: '\f911';
}

.ph-paint-brush-bold::before {
	content: '\f912';
}

.ph-paint-brush-broad-bold::before {
	content: '\f913';
}

.ph-paint-brush-household-bold::before {
	content: '\f914';
}

.ph-paint-bucket-bold::before {
	content: '\f915';
}

.ph-paint-roller-bold::before {
	content: '\f916';
}

.ph-palette-bold::before {
	content: '\f917';
}

.ph-paper-plane-bold::before {
	content: '\f918';
}

.ph-paper-plane-right-bold::before {
	content: '\f919';
}

.ph-paper-plane-tilt-bold::before {
	content: '\f91a';
}

.ph-paperclip-bold::before {
	content: '\f91b';
}

.ph-paperclip-horizontal-bold::before {
	content: '\f91c';
}

.ph-parachute-bold::before {
	content: '\f91d';
}

.ph-password-bold::before {
	content: '\f91e';
}

.ph-path-bold::before {
	content: '\f91f';
}

.ph-pause-bold::before {
	content: '\f920';
}

.ph-pause-circle-bold::before {
	content: '\f921';
}

.ph-paw-print-bold::before {
	content: '\f922';
}

.ph-peace-bold::before {
	content: '\f923';
}

.ph-pen-bold::before {
	content: '\f924';
}

.ph-pen-nib-bold::before {
	content: '\f925';
}

.ph-pen-nib-straight-bold::before {
	content: '\f926';
}

.ph-pencil-bold::before {
	content: '\f927';
}

.ph-pencil-circle-bold::before {
	content: '\f928';
}

.ph-pencil-line-bold::before {
	content: '\f929';
}

.ph-pencil-simple-bold::before {
	content: '\f92a';
}

.ph-pencil-simple-line-bold::before {
	content: '\f92b';
}

.ph-percent-bold::before {
	content: '\f92c';
}

.ph-person-bold::before {
	content: '\f92d';
}

.ph-person-simple-bold::before {
	content: '\f92e';
}

.ph-person-simple-run-bold::before {
	content: '\f92f';
}

.ph-person-simple-walk-bold::before {
	content: '\f930';
}

.ph-perspective-bold::before {
	content: '\f931';
}

.ph-phone-bold::before {
	content: '\f932';
}

.ph-phone-call-bold::before {
	content: '\f933';
}

.ph-phone-disconnect-bold::before {
	content: '\f934';
}

.ph-phone-incoming-bold::before {
	content: '\f935';
}

.ph-phone-outgoing-bold::before {
	content: '\f936';
}

.ph-phone-slash-bold::before {
	content: '\f937';
}

.ph-phone-x-bold::before {
	content: '\f938';
}

.ph-phosphor-logo-bold::before {
	content: '\f939';
}

.ph-piano-keys-bold::before {
	content: '\f93a';
}

.ph-picture-in-picture-bold::before {
	content: '\f93b';
}

.ph-pill-bold::before {
	content: '\f93c';
}

.ph-pinterest-logo-bold::before {
	content: '\f93d';
}

.ph-pinwheel-bold::before {
	content: '\f93e';
}

.ph-pizza-bold::before {
	content: '\f93f';
}

.ph-placeholder-bold::before {
	content: '\f940';
}

.ph-planet-bold::before {
	content: '\f941';
}

.ph-play-bold::before {
	content: '\f942';
}

.ph-play-circle-bold::before {
	content: '\f943';
}

.ph-playlist-bold::before {
	content: '\f944';
}

.ph-plug-bold::before {
	content: '\f945';
}

.ph-plugs-bold::before {
	content: '\f946';
}

.ph-plugs-connected-bold::before {
	content: '\f947';
}

.ph-plus-bold::before {
	content: '\f948';
}

.ph-plus-circle-bold::before {
	content: '\f949';
}

.ph-plus-minus-bold::before {
	content: '\f94a';
}

.ph-poker-chip-bold::before {
	content: '\f94b';
}

.ph-police-car-bold::before {
	content: '\f94c';
}

.ph-polygon-bold::before {
	content: '\f94d';
}

.ph-popcorn-bold::before {
	content: '\f94e';
}

.ph-power-bold::before {
	content: '\f94f';
}

.ph-prescription-bold::before {
	content: '\f950';
}

.ph-presentation-bold::before {
	content: '\f951';
}

.ph-presentation-chart-bold::before {
	content: '\f952';
}

.ph-printer-bold::before {
	content: '\f953';
}

.ph-prohibit-bold::before {
	content: '\f954';
}

.ph-prohibit-inset-bold::before {
	content: '\f955';
}

.ph-projector-screen-bold::before {
	content: '\f956';
}

.ph-projector-screen-chart-bold::before {
	content: '\f957';
}

.ph-push-pin-bold::before {
	content: '\f958';
}

.ph-push-pin-simple-bold::before {
	content: '\f959';
}

.ph-push-pin-simple-slash-bold::before {
	content: '\f95a';
}

.ph-push-pin-slash-bold::before {
	content: '\f95b';
}

.ph-puzzle-piece-bold::before {
	content: '\f95c';
}

.ph-qr-code-bold::before {
	content: '\f95d';
}

.ph-question-bold::before {
	content: '\f95e';
}

.ph-queue-bold::before {
	content: '\f95f';
}

.ph-quotes-bold::before {
	content: '\f960';
}

.ph-radical-bold::before {
	content: '\f961';
}

.ph-radio-bold::before {
	content: '\f962';
}

.ph-radio-button-bold::before {
	content: '\f963';
}

.ph-rainbow-bold::before {
	content: '\f964';
}

.ph-rainbow-cloud-bold::before {
	content: '\f965';
}

.ph-receipt-bold::before {
	content: '\f966';
}

.ph-record-bold::before {
	content: '\f967';
}

.ph-rectangle-bold::before {
	content: '\f968';
}

.ph-recycle-bold::before {
	content: '\f969';
}

.ph-reddit-logo-bold::before {
	content: '\f96a';
}

.ph-repeat-bold::before {
	content: '\f96b';
}

.ph-repeat-once-bold::before {
	content: '\f96c';
}

.ph-rewind-bold::before {
	content: '\f96d';
}

.ph-rewind-circle-bold::before {
	content: '\f96e';
}

.ph-robot-bold::before {
	content: '\f96f';
}

.ph-rocket-bold::before {
	content: '\f970';
}

.ph-rocket-launch-bold::before {
	content: '\f971';
}

.ph-rows-bold::before {
	content: '\f972';
}

.ph-rss-bold::before {
	content: '\f973';
}

.ph-rss-simple-bold::before {
	content: '\f974';
}

.ph-rug-bold::before {
	content: '\f975';
}

.ph-ruler-bold::before {
	content: '\f976';
}

.ph-scales-bold::before {
	content: '\f977';
}

.ph-scan-bold::before {
	content: '\f978';
}

.ph-scissors-bold::before {
	content: '\f979';
}

.ph-screencast-bold::before {
	content: '\f97a';
}

.ph-scribble-loop-bold::before {
	content: '\f97b';
}

.ph-scroll-bold::before {
	content: '\f97c';
}

.ph-selection-all-bold::before {
	content: '\f97d';
}

.ph-selection-background-bold::before {
	content: '\f97e';
}

.ph-selection-bold::before {
	content: '\f97f';
}

.ph-selection-foreground-bold::before {
	content: '\f980';
}

.ph-selection-inverse-bold::before {
	content: '\f981';
}

.ph-selection-plus-bold::before {
	content: '\f982';
}

.ph-selection-slash-bold::before {
	content: '\f983';
}

.ph-share-bold::before {
	content: '\f984';
}

.ph-share-network-bold::before {
	content: '\f985';
}

.ph-shield-bold::before {
	content: '\f986';
}

.ph-shield-check-bold::before {
	content: '\f987';
}

.ph-shield-checkered-bold::before {
	content: '\f988';
}

.ph-shield-chevron-bold::before {
	content: '\f989';
}

.ph-shield-plus-bold::before {
	content: '\f98a';
}

.ph-shield-slash-bold::before {
	content: '\f98b';
}

.ph-shield-star-bold::before {
	content: '\f98c';
}

.ph-shield-warning-bold::before {
	content: '\f98d';
}

.ph-shopping-bag-bold::before {
	content: '\f98e';
}

.ph-shopping-bag-open-bold::before {
	content: '\f98f';
}

.ph-shopping-cart-bold::before {
	content: '\f990';
}

.ph-shopping-cart-simple-bold::before {
	content: '\f991';
}

.ph-shower-bold::before {
	content: '\f992';
}

.ph-shuffle-angular-bold::before {
	content: '\f993';
}

.ph-shuffle-bold::before {
	content: '\f994';
}

.ph-shuffle-simple-bold::before {
	content: '\f995';
}

.ph-sidebar-bold::before {
	content: '\f996';
}

.ph-sidebar-simple-bold::before {
	content: '\f997';
}

.ph-sign-in-bold::before {
	content: '\f998';
}

.ph-sign-out-bold::before {
	content: '\f999';
}

.ph-signpost-bold::before {
	content: '\f99a';
}

.ph-sim-card-bold::before {
	content: '\f99b';
}

.ph-sketch-logo-bold::before {
	content: '\f99c';
}

.ph-skip-back-bold::before {
	content: '\f99d';
}

.ph-skip-back-circle-bold::before {
	content: '\f99e';
}

.ph-skip-forward-bold::before {
	content: '\f99f';
}

.ph-skip-forward-circle-bold::before {
	content: '\f9a0';
}

.ph-skull-bold::before {
	content: '\f9a1';
}

.ph-slack-logo-bold::before {
	content: '\f9a2';
}

.ph-sliders-bold::before {
	content: '\f9a3';
}

.ph-sliders-horizontal-bold::before {
	content: '\f9a4';
}

.ph-smiley-blank-bold::before {
	content: '\f9a5';
}

.ph-smiley-bold::before {
	content: '\f9a6';
}

.ph-smiley-meh-bold::before {
	content: '\f9a7';
}

.ph-smiley-nervous-bold::before {
	content: '\f9a8';
}

.ph-smiley-sad-bold::before {
	content: '\f9a9';
}

.ph-smiley-sticker-bold::before {
	content: '\f9aa';
}

.ph-smiley-wink-bold::before {
	content: '\f9ab';
}

.ph-smiley-x-eyes-bold::before {
	content: '\f9ac';
}

.ph-snapchat-logo-bold::before {
	content: '\f9ad';
}

.ph-snowflake-bold::before {
	content: '\f9ae';
}

.ph-soccer-ball-bold::before {
	content: '\f9af';
}

.ph-sort-ascending-bold::before {
	content: '\f9b0';
}

.ph-sort-descending-bold::before {
	content: '\f9b1';
}

.ph-spade-bold::before {
	content: '\f9b2';
}

.ph-sparkle-bold::before {
	content: '\f9b3';
}

.ph-speaker-high-bold::before {
	content: '\f9b4';
}

.ph-speaker-low-bold::before {
	content: '\f9b5';
}

.ph-speaker-none-bold::before {
	content: '\f9b6';
}

.ph-speaker-simple-high-bold::before {
	content: '\f9b7';
}

.ph-speaker-simple-low-bold::before {
	content: '\f9b8';
}

.ph-speaker-simple-none-bold::before {
	content: '\f9b9';
}

.ph-speaker-simple-slash-bold::before {
	content: '\f9ba';
}

.ph-speaker-simple-x-bold::before {
	content: '\f9bb';
}

.ph-speaker-slash-bold::before {
	content: '\f9bc';
}

.ph-speaker-x-bold::before {
	content: '\f9bd';
}

.ph-spinner-bold::before {
	content: '\f9be';
}

.ph-spinner-gap-bold::before {
	content: '\f9bf';
}

.ph-spiral-bold::before {
	content: '\f9c0';
}

.ph-spotify-logo-bold::before {
	content: '\f9c1';
}

.ph-square-bold::before {
	content: '\f9c2';
}

.ph-square-half-bold::before {
	content: '\f9c3';
}

.ph-square-half-bottom-bold::before {
	content: '\f9c4';
}

.ph-square-logo-bold::before {
	content: '\f9c5';
}

.ph-squares-four-bold::before {
	content: '\f9c6';
}

.ph-stack-bold::before {
	content: '\f9c7';
}

.ph-stack-overflow-logo-bold::before {
	content: '\f9c8';
}

.ph-stack-simple-bold::before {
	content: '\f9c9';
}

.ph-stamp-bold::before {
	content: '\f9ca';
}

.ph-star-bold::before {
	content: '\f9cb';
}

.ph-star-four-bold::before {
	content: '\f9cc';
}

.ph-star-half-bold::before {
	content: '\f9cd';
}

.ph-sticker-bold::before {
	content: '\f9ce';
}

.ph-stop-bold::before {
	content: '\f9cf';
}

.ph-stop-circle-bold::before {
	content: '\f9d0';
}

.ph-storefront-bold::before {
	content: '\f9d1';
}

.ph-strategy-bold::before {
	content: '\f9d2';
}

.ph-stripe-logo-bold::before {
	content: '\f9d3';
}

.ph-student-bold::before {
	content: '\f9d4';
}

.ph-suitcase-bold::before {
	content: '\f9d5';
}

.ph-suitcase-simple-bold::before {
	content: '\f9d6';
}

.ph-sun-bold::before {
	content: '\f9d7';
}

.ph-sun-dim-bold::before {
	content: '\f9d8';
}

.ph-sun-horizon-bold::before {
	content: '\f9d9';
}

.ph-sunglasses-bold::before {
	content: '\f9da';
}

.ph-swap-bold::before {
	content: '\f9db';
}

.ph-swatches-bold::before {
	content: '\f9dc';
}

.ph-sword-bold::before {
	content: '\f9dd';
}

.ph-syringe-bold::before {
	content: '\f9de';
}

.ph-t-shirt-bold::before {
	content: '\f9df';
}

.ph-table-bold::before {
	content: '\f9e0';
}

.ph-tabs-bold::before {
	content: '\f9e1';
}

.ph-tag-bold::before {
	content: '\f9e2';
}

.ph-tag-chevron-bold::before {
	content: '\f9e3';
}

.ph-tag-simple-bold::before {
	content: '\f9e4';
}

.ph-target-bold::before {
	content: '\f9e5';
}

.ph-taxi-bold::before {
	content: '\f9e6';
}

.ph-telegram-logo-bold::before {
	content: '\f9e7';
}

.ph-television-bold::before {
	content: '\f9e8';
}

.ph-television-simple-bold::before {
	content: '\f9e9';
}

.ph-tennis-ball-bold::before {
	content: '\f9ea';
}

.ph-terminal-bold::before {
	content: '\f9eb';
}

.ph-terminal-window-bold::before {
	content: '\f9ec';
}

.ph-test-tube-bold::before {
	content: '\f9ed';
}

.ph-text-aa-bold::before {
	content: '\f9ee';
}

.ph-text-align-center-bold::before {
	content: '\f9ef';
}

.ph-text-align-justify-bold::before {
	content: '\f9f0';
}

.ph-text-align-left-bold::before {
	content: '\f9f1';
}

.ph-text-align-right-bold::before {
	content: '\f9f2';
}

.ph-text-bolder-bold::before {
	content: '\f9f3';
}

.ph-text-h-bold::before {
	content: '\f9f4';
}

.ph-text-h-five-bold::before {
	content: '\f9f5';
}

.ph-text-h-four-bold::before {
	content: '\f9f6';
}

.ph-text-h-one-bold::before {
	content: '\f9f7';
}

.ph-text-h-six-bold::before {
	content: '\f9f8';
}

.ph-text-h-three-bold::before {
	content: '\f9f9';
}

.ph-text-h-two-bold::before {
	content: '\f9fa';
}

.ph-text-indent-bold::before {
	content: '\f9fb';
}

.ph-text-italic-bold::before {
	content: '\f9fc';
}

.ph-text-outdent-bold::before {
	content: '\f9fd';
}

.ph-text-strikethrough-bold::before {
	content: '\f9fe';
}

.ph-text-t-bold::before {
	content: '\f9ff';
}

.ph-text-underline-bold::before {
	content: '\fa00';
}

.ph-textbox-bold::before {
	content: '\fa01';
}

.ph-thermometer-bold::before {
	content: '\fa02';
}

.ph-thermometer-cold-bold::before {
	content: '\fa03';
}

.ph-thermometer-hot-bold::before {
	content: '\fa04';
}

.ph-thermometer-simple-bold::before {
	content: '\fa05';
}

.ph-thumbs-down-bold::before {
	content: '\fa06';
}

.ph-thumbs-up-bold::before {
	content: '\fa07';
}

.ph-ticket-bold::before {
	content: '\fa08';
}

.ph-tiktok-logo-bold::before {
	content: '\fa09';
}

.ph-timer-bold::before {
	content: '\fa0a';
}

.ph-toggle-left-bold::before {
	content: '\fa0b';
}

.ph-toggle-right-bold::before {
	content: '\fa0c';
}

.ph-toilet-bold::before {
	content: '\fa0d';
}

.ph-toilet-paper-bold::before {
	content: '\fa0e';
}

.ph-tote-bold::before {
	content: '\fa0f';
}

.ph-tote-simple-bold::before {
	content: '\fa10';
}

.ph-trademark-registered-bold::before {
	content: '\fa11';
}

.ph-traffic-cone-bold::before {
	content: '\fa12';
}

.ph-traffic-sign-bold::before {
	content: '\fa13';
}

.ph-traffic-signal-bold::before {
	content: '\fa14';
}

.ph-train-bold::before {
	content: '\fa15';
}

.ph-train-regional-bold::before {
	content: '\fa16';
}

.ph-train-simple-bold::before {
	content: '\fa17';
}

.ph-translate-bold::before {
	content: '\fa18';
}

.ph-trash-bold::before {
	content: '\fa19';
}

.ph-trash-simple-bold::before {
	content: '\fa1a';
}

.ph-tray-bold::before {
	content: '\fa1b';
}

.ph-tree-bold::before {
	content: '\fa1c';
}

.ph-tree-evergreen-bold::before {
	content: '\fa1d';
}

.ph-tree-structure-bold::before {
	content: '\fa1e';
}

.ph-trend-down-bold::before {
	content: '\fa1f';
}

.ph-trend-up-bold::before {
	content: '\fa20';
}

.ph-triangle-bold::before {
	content: '\fa21';
}

.ph-trophy-bold::before {
	content: '\fa22';
}

.ph-truck-bold::before {
	content: '\fa23';
}

.ph-twitch-logo-bold::before {
	content: '\fa24';
}

.ph-twitter-logo-bold::before {
	content: '\fa25';
}

.ph-umbrella-bold::before {
	content: '\fa26';
}

.ph-umbrella-simple-bold::before {
	content: '\fa27';
}

.ph-upload-bold::before {
	content: '\fa28';
}

.ph-upload-simple-bold::before {
	content: '\fa29';
}

.ph-user-bold::before {
	content: '\fa2a';
}

.ph-user-circle-bold::before {
	content: '\fa2b';
}

.ph-user-circle-gear-bold::before {
	content: '\fa2c';
}

.ph-user-circle-minus-bold::before {
	content: '\fa2d';
}

.ph-user-circle-plus-bold::before {
	content: '\fa2e';
}

.ph-user-focus-bold::before {
	content: '\fa2f';
}

.ph-user-gear-bold::before {
	content: '\fa30';
}

.ph-user-list-bold::before {
	content: '\fa31';
}

.ph-user-minus-bold::before {
	content: '\fa32';
}

.ph-user-plus-bold::before {
	content: '\fa33';
}

.ph-user-rectangle-bold::before {
	content: '\fa34';
}

.ph-user-square-bold::before {
	content: '\fa35';
}

.ph-user-switch-bold::before {
	content: '\fa36';
}

.ph-users-bold::before {
	content: '\fa37';
}

.ph-users-four-bold::before {
	content: '\fa38';
}

.ph-users-three-bold::before {
	content: '\fa39';
}

.ph-vault-bold::before {
	content: '\fa3a';
}

.ph-vibrate-bold::before {
	content: '\fa3b';
}

.ph-video-camera-bold::before {
	content: '\fa3c';
}

.ph-video-camera-slash-bold::before {
	content: '\fa3d';
}

.ph-vignette-bold::before {
	content: '\fa3e';
}

.ph-voicemail-bold::before {
	content: '\fa3f';
}

.ph-volleyball-bold::before {
	content: '\fa40';
}

.ph-wall-bold::before {
	content: '\fa41';
}

.ph-wallet-bold::before {
	content: '\fa42';
}

.ph-warning-bold::before {
	content: '\fa43';
}

.ph-warning-circle-bold::before {
	content: '\fa44';
}

.ph-warning-octagon-bold::before {
	content: '\fa45';
}

.ph-watch-bold::before {
	content: '\fa46';
}

.ph-wave-sawtooth-bold::before {
	content: '\fa47';
}

.ph-wave-sine-bold::before {
	content: '\fa48';
}

.ph-wave-square-bold::before {
	content: '\fa49';
}

.ph-wave-triangle-bold::before {
	content: '\fa4a';
}

.ph-waves-bold::before {
	content: '\fa4b';
}

.ph-webcam-bold::before {
	content: '\fa4c';
}

.ph-whatsapp-logo-bold::before {
	content: '\fa4d';
}

.ph-wheelchair-bold::before {
	content: '\fa4e';
}

.ph-wifi-high-bold::before {
	content: '\fa4f';
}

.ph-wifi-low-bold::before {
	content: '\fa50';
}

.ph-wifi-medium-bold::before {
	content: '\fa51';
}

.ph-wifi-none-bold::before {
	content: '\fa52';
}

.ph-wifi-slash-bold::before {
	content: '\fa53';
}

.ph-wifi-x-bold::before {
	content: '\fa54';
}

.ph-wind-bold::before {
	content: '\fa55';
}

.ph-windows-logo-bold::before {
	content: '\fa56';
}

.ph-wine-bold::before {
	content: '\fa57';
}

.ph-wrench-bold::before {
	content: '\fa58';
}

.ph-x-bold::before {
	content: '\fa59';
}

.ph-x-circle-bold::before {
	content: '\fa5a';
}

.ph-x-square-bold::before {
	content: '\fa5b';
}

.ph-yin-yang-bold::before {
	content: '\fa5c';
}

.ph-youtube-logo-bold::before {
	content: '\fa5d';
}

.ph-activity-fill::before {
	content: '\fa5e';
}

.ph-address-book-fill::before {
	content: '\fa5f';
}

.ph-airplane-fill::before {
	content: '\fa60';
}

.ph-airplane-in-flight-fill::before {
	content: '\fa61';
}

.ph-airplane-landing-fill::before {
	content: '\fa62';
}

.ph-airplane-takeoff-fill::before {
	content: '\fa63';
}

.ph-airplane-tilt-fill::before {
	content: '\fa64';
}

.ph-airplay-fill::before {
	content: '\fa65';
}

.ph-alarm-fill::before {
	content: '\fa66';
}

.ph-alien-fill::before {
	content: '\fa67';
}

.ph-align-bottom-fill::before {
	content: '\fa68';
}

.ph-align-bottom-simple-fill::before {
	content: '\fa69';
}

.ph-align-center-horizontal-fill::before {
	content: '\fa6a';
}

.ph-align-center-horizontal-simple-fill::before {
	content: '\fa6b';
}

.ph-align-center-vertical-fill::before {
	content: '\fa6c';
}

.ph-align-center-vertical-simple-fill::before {
	content: '\fa6d';
}

.ph-align-left-fill::before {
	content: '\fa6e';
}

.ph-align-left-simple-fill::before {
	content: '\fa6f';
}

.ph-align-right-fill::before {
	content: '\fa70';
}

.ph-align-right-simple-fill::before {
	content: '\fa71';
}

.ph-align-top-fill::before {
	content: '\fa72';
}

.ph-align-top-simple-fill::before {
	content: '\fa73';
}

.ph-anchor-fill::before {
	content: '\fa74';
}

.ph-anchor-simple-fill::before {
	content: '\fa75';
}

.ph-android-logo-fill::before {
	content: '\fa76';
}

.ph-angular-logo-fill::before {
	content: '\fa77';
}

.ph-aperture-fill::before {
	content: '\fa78';
}

.ph-app-store-logo-fill::before {
	content: '\fa79';
}

.ph-app-window-fill::before {
	content: '\fa7a';
}

.ph-apple-logo-fill::before {
	content: '\fa7b';
}

.ph-apple-podcasts-logo-fill::before {
	content: '\fa7c';
}

.ph-archive-box-fill::before {
	content: '\fa7d';
}

.ph-archive-fill::before {
	content: '\fa7e';
}

.ph-archive-tray-fill::before {
	content: '\fa7f';
}

.ph-armchair-fill::before {
	content: '\fa80';
}

.ph-arrow-arc-left-fill::before {
	content: '\fa81';
}

.ph-arrow-arc-right-fill::before {
	content: '\fa82';
}

.ph-arrow-bend-double-up-left-fill::before {
	content: '\fa83';
}

.ph-arrow-bend-double-up-right-fill::before {
	content: '\fa84';
}

.ph-arrow-bend-down-left-fill::before {
	content: '\fa85';
}

.ph-arrow-bend-down-right-fill::before {
	content: '\fa86';
}

.ph-arrow-bend-left-down-fill::before {
	content: '\fa87';
}

.ph-arrow-bend-left-up-fill::before {
	content: '\fa88';
}

.ph-arrow-bend-right-down-fill::before {
	content: '\fa89';
}

.ph-arrow-bend-right-up-fill::before {
	content: '\fa8a';
}

.ph-arrow-bend-up-left-fill::before {
	content: '\fa8b';
}

.ph-arrow-bend-up-right-fill::before {
	content: '\fa8c';
}

.ph-arrow-circle-down-fill::before {
	content: '\fa8d';
}

.ph-arrow-circle-down-left-fill::before {
	content: '\fa8e';
}

.ph-arrow-circle-down-right-fill::before {
	content: '\fa8f';
}

.ph-arrow-circle-left-fill::before {
	content: '\fa90';
}

.ph-arrow-circle-right-fill::before {
	content: '\fa91';
}

.ph-arrow-circle-up-fill::before {
	content: '\fa92';
}

.ph-arrow-circle-up-left-fill::before {
	content: '\fa93';
}

.ph-arrow-circle-up-right-fill::before {
	content: '\fa94';
}

.ph-arrow-clockwise-fill::before {
	content: '\fa95';
}

.ph-arrow-counter-clockwise-fill::before {
	content: '\fa96';
}

.ph-arrow-down-fill::before {
	content: '\fa97';
}

.ph-arrow-down-left-fill::before {
	content: '\fa98';
}

.ph-arrow-down-right-fill::before {
	content: '\fa99';
}

.ph-arrow-elbow-down-left-fill::before {
	content: '\fa9a';
}

.ph-arrow-elbow-down-right-fill::before {
	content: '\fa9b';
}

.ph-arrow-elbow-left-down-fill::before {
	content: '\fa9c';
}

.ph-arrow-elbow-left-fill::before {
	content: '\fa9d';
}

.ph-arrow-elbow-left-up-fill::before {
	content: '\fa9e';
}

.ph-arrow-elbow-right-down-fill::before {
	content: '\fa9f';
}

.ph-arrow-elbow-right-fill::before {
	content: '\faa0';
}

.ph-arrow-elbow-right-up-fill::before {
	content: '\faa1';
}

.ph-arrow-elbow-up-left-fill::before {
	content: '\faa2';
}

.ph-arrow-elbow-up-right-fill::before {
	content: '\faa3';
}

.ph-arrow-fat-down-fill::before {
	content: '\faa4';
}

.ph-arrow-fat-left-fill::before {
	content: '\faa5';
}

.ph-arrow-fat-line-down-fill::before {
	content: '\faa6';
}

.ph-arrow-fat-line-left-fill::before {
	content: '\faa7';
}

.ph-arrow-fat-line-right-fill::before {
	content: '\faa8';
}

.ph-arrow-fat-line-up-fill::before {
	content: '\faa9';
}

.ph-arrow-fat-lines-down-fill::before {
	content: '\faaa';
}

.ph-arrow-fat-lines-left-fill::before {
	content: '\faab';
}

.ph-arrow-fat-lines-right-fill::before {
	content: '\faac';
}

.ph-arrow-fat-lines-up-fill::before {
	content: '\faad';
}

.ph-arrow-fat-right-fill::before {
	content: '\faae';
}

.ph-arrow-fat-up-fill::before {
	content: '\faaf';
}

.ph-arrow-left-fill::before {
	content: '\fab0';
}

.ph-arrow-line-down-fill::before {
	content: '\fab1';
}

.ph-arrow-line-down-left-fill::before {
	content: '\fab2';
}

.ph-arrow-line-down-right-fill::before {
	content: '\fab3';
}

.ph-arrow-line-left-fill::before {
	content: '\fab4';
}

.ph-arrow-line-right-fill::before {
	content: '\fab5';
}

.ph-arrow-line-up-fill::before {
	content: '\fab6';
}

.ph-arrow-line-up-left-fill::before {
	content: '\fab7';
}

.ph-arrow-line-up-right-fill::before {
	content: '\fab8';
}

.ph-arrow-right-fill::before {
	content: '\fab9';
}

.ph-arrow-square-down-fill::before {
	content: '\faba';
}

.ph-arrow-square-down-left-fill::before {
	content: '\fabb';
}

.ph-arrow-square-down-right-fill::before {
	content: '\fabc';
}

.ph-arrow-square-in-fill::before {
	content: '\fabd';
}

.ph-arrow-square-left-fill::before {
	content: '\fabe';
}

.ph-arrow-square-out-fill::before {
	content: '\fabf';
}

.ph-arrow-square-right-fill::before {
	content: '\fac0';
}

.ph-arrow-square-up-fill::before {
	content: '\fac1';
}

.ph-arrow-square-up-left-fill::before {
	content: '\fac2';
}

.ph-arrow-square-up-right-fill::before {
	content: '\fac3';
}

.ph-arrow-u-down-left-fill::before {
	content: '\fac4';
}

.ph-arrow-u-down-right-fill::before {
	content: '\fac5';
}

.ph-arrow-u-left-down-fill::before {
	content: '\fac6';
}

.ph-arrow-u-left-up-fill::before {
	content: '\fac7';
}

.ph-arrow-u-right-down-fill::before {
	content: '\fac8';
}

.ph-arrow-u-right-up-fill::before {
	content: '\fac9';
}

.ph-arrow-u-up-left-fill::before {
	content: '\faca';
}

.ph-arrow-u-up-right-fill::before {
	content: '\facb';
}

.ph-arrow-up-fill::before {
	content: '\facc';
}

.ph-arrow-up-left-fill::before {
	content: '\facd';
}

.ph-arrow-up-right-fill::before {
	content: '\face';
}

.ph-arrows-clockwise-fill::before {
	content: '\facf';
}

.ph-arrows-counter-clockwise-fill::before {
	content: '\fad0';
}

.ph-arrows-down-up-fill::before {
	content: '\fad1';
}

.ph-arrows-horizontal-fill::before {
	content: '\fad2';
}

.ph-arrows-in-cardinal-fill::before {
	content: '\fad3';
}

.ph-arrows-in-fill::before {
	content: '\fad4';
}

.ph-arrows-in-line-horizontal-fill::before {
	content: '\fad5';
}

.ph-arrows-in-line-vertical-fill::before {
	content: '\fad6';
}

.ph-arrows-in-simple-fill::before {
	content: '\fad7';
}

.ph-arrows-left-right-fill::before {
	content: '\fad8';
}

.ph-arrows-out-cardinal-fill::before {
	content: '\fad9';
}

.ph-arrows-out-fill::before {
	content: '\fada';
}

.ph-arrows-out-line-horizontal-fill::before {
	content: '\fadb';
}

.ph-arrows-out-line-vertical-fill::before {
	content: '\fadc';
}

.ph-arrows-out-simple-fill::before {
	content: '\fadd';
}

.ph-arrows-vertical-fill::before {
	content: '\fade';
}

.ph-article-fill::before {
	content: '\fadf';
}

.ph-article-medium-fill::before {
	content: '\fae0';
}

.ph-article-ny-times-fill::before {
	content: '\fae1';
}

.ph-asterisk-fill::before {
	content: '\fae2';
}

.ph-asterisk-simple-fill::before {
	content: '\fae3';
}

.ph-at-fill::before {
	content: '\fae4';
}

.ph-atom-fill::before {
	content: '\fae5';
}

.ph-baby-fill::before {
	content: '\fae6';
}

.ph-backpack-fill::before {
	content: '\fae7';
}

.ph-backspace-fill::before {
	content: '\fae8';
}

.ph-bag-fill::before {
	content: '\fae9';
}

.ph-bag-simple-fill::before {
	content: '\faea';
}

.ph-balloon-fill::before {
	content: '\faeb';
}

.ph-bandaids-fill::before {
	content: '\faec';
}

.ph-bank-fill::before {
	content: '\faed';
}

.ph-barbell-fill::before {
	content: '\faee';
}

.ph-barcode-fill::before {
	content: '\faef';
}

.ph-barricade-fill::before {
	content: '\faf0';
}

.ph-baseball-fill::before {
	content: '\faf1';
}

.ph-basketball-fill::before {
	content: '\faf2';
}

.ph-bathtub-fill::before {
	content: '\faf3';
}

.ph-battery-charging-fill::before {
	content: '\faf4';
}

.ph-battery-charging-vertical-fill::before {
	content: '\faf5';
}

.ph-battery-empty-fill::before {
	content: '\faf6';
}

.ph-battery-full-fill::before {
	content: '\faf7';
}

.ph-battery-high-fill::before {
	content: '\faf8';
}

.ph-battery-low-fill::before {
	content: '\faf9';
}

.ph-battery-medium-fill::before {
	content: '\fafa';
}

.ph-battery-plus-fill::before {
	content: '\fafb';
}

.ph-battery-warning-fill::before {
	content: '\fafc';
}

.ph-battery-warning-vertical-fill::before {
	content: '\fafd';
}

.ph-bed-fill::before {
	content: '\fafe';
}

.ph-beer-bottle-fill::before {
	content: '\faff';
}

.ph-behance-logo-fill::before {
	content: '\fb00';
}

.ph-bell-fill::before {
	content: '\fb01';
}

.ph-bell-ringing-fill::before {
	content: '\fb02';
}

.ph-bell-simple-fill::before {
	content: '\fb03';
}

.ph-bell-simple-ringing-fill::before {
	content: '\fb04';
}

.ph-bell-simple-slash-fill::before {
	content: '\fb05';
}

.ph-bell-simple-z-fill::before {
	content: '\fb06';
}

.ph-bell-slash-fill::before {
	content: '\fb07';
}

.ph-bell-z-fill::before {
	content: '\fb08';
}

.ph-bezier-curve-fill::before {
	content: '\fb09';
}

.ph-bicycle-fill::before {
	content: '\fb0a';
}

.ph-binoculars-fill::before {
	content: '\fb0b';
}

.ph-bird-fill::before {
	content: '\fb0c';
}

.ph-bluetooth-connected-fill::before {
	content: '\fb0d';
}

.ph-bluetooth-fill::before {
	content: '\fb0e';
}

.ph-bluetooth-slash-fill::before {
	content: '\fb0f';
}

.ph-bluetooth-x-fill::before {
	content: '\fb10';
}

.ph-boat-fill::before {
	content: '\fb11';
}

.ph-book-bookmark-fill::before {
	content: '\fb12';
}

.ph-book-fill::before {
	content: '\fb13';
}

.ph-book-open-fill::before {
	content: '\fb14';
}

.ph-bookmark-fill::before {
	content: '\fb15';
}

.ph-bookmark-simple-fill::before {
	content: '\fb16';
}

.ph-bookmarks-fill::before {
	content: '\fb17';
}

.ph-bookmarks-simple-fill::before {
	content: '\fb18';
}

.ph-books-fill::before {
	content: '\fb19';
}

.ph-bounding-box-fill::before {
	content: '\fb1a';
}

.ph-brackets-angle-fill::before {
	content: '\fb1b';
}

.ph-brackets-curly-fill::before {
	content: '\fb1c';
}

.ph-brackets-round-fill::before {
	content: '\fb1d';
}

.ph-brackets-square-fill::before {
	content: '\fb1e';
}

.ph-brain-fill::before {
	content: '\fb1f';
}

.ph-brandy-fill::before {
	content: '\fb20';
}

.ph-briefcase-fill::before {
	content: '\fb21';
}

.ph-briefcase-metal-fill::before {
	content: '\fb22';
}

.ph-broadcast-fill::before {
	content: '\fb23';
}

.ph-browser-fill::before {
	content: '\fb24';
}

.ph-browsers-fill::before {
	content: '\fb25';
}

.ph-bug-beetle-fill::before {
	content: '\fb26';
}

.ph-bug-droid-fill::before {
	content: '\fb27';
}

.ph-bug-fill::before {
	content: '\fb28';
}

.ph-buildings-fill::before {
	content: '\fb29';
}

.ph-bus-fill::before {
	content: '\fb2a';
}

.ph-butterfly-fill::before {
	content: '\fb2b';
}

.ph-cactus-fill::before {
	content: '\fb2c';
}

.ph-cake-fill::before {
	content: '\fb2d';
}

.ph-calculator-fill::before {
	content: '\fb2e';
}

.ph-calendar-blank-fill::before {
	content: '\fb2f';
}

.ph-calendar-check-fill::before {
	content: '\fb30';
}

.ph-calendar-fill::before {
	content: '\fb31';
}

.ph-calendar-plus-fill::before {
	content: '\fb32';
}

.ph-calendar-x-fill::before {
	content: '\fb33';
}

.ph-camera-fill::before {
	content: '\fb34';
}

.ph-camera-rotate-fill::before {
	content: '\fb35';
}

.ph-camera-slash-fill::before {
	content: '\fb36';
}

.ph-campfire-fill::before {
	content: '\fb37';
}

.ph-car-fill::before {
	content: '\fb38';
}

.ph-car-simple-fill::before {
	content: '\fb39';
}

.ph-cardholder-fill::before {
	content: '\fb3a';
}

.ph-cards-fill::before {
	content: '\fb3b';
}

.ph-caret-circle-double-down-fill::before {
	content: '\fb3c';
}

.ph-caret-circle-double-left-fill::before {
	content: '\fb3d';
}

.ph-caret-circle-double-right-fill::before {
	content: '\fb3e';
}

.ph-caret-circle-double-up-fill::before {
	content: '\fb3f';
}

.ph-caret-circle-down-fill::before {
	content: '\fb40';
}

.ph-caret-circle-left-fill::before {
	content: '\fb41';
}

.ph-caret-circle-right-fill::before {
	content: '\fb42';
}

.ph-caret-circle-up-fill::before {
	content: '\fb43';
}

.ph-caret-double-down-fill::before {
	content: '\fb44';
}

.ph-caret-double-left-fill::before {
	content: '\fb45';
}

.ph-caret-double-right-fill::before {
	content: '\fb46';
}

.ph-caret-double-up-fill::before {
	content: '\fb47';
}

.ph-caret-down-fill::before {
	content: '\fb48';
}

.ph-caret-left-fill::before {
	content: '\fb49';
}

.ph-caret-right-fill::before {
	content: '\fb4a';
}

.ph-caret-up-fill::before {
	content: '\fb4b';
}

.ph-cat-fill::before {
	content: '\fb4c';
}

.ph-cell-signal-full-fill::before {
	content: '\fb4d';
}

.ph-cell-signal-high-fill::before {
	content: '\fb4e';
}

.ph-cell-signal-low-fill::before {
	content: '\fb4f';
}

.ph-cell-signal-medium-fill::before {
	content: '\fb50';
}

.ph-cell-signal-none-fill::before {
	content: '\fb51';
}

.ph-cell-signal-slash-fill::before {
	content: '\fb52';
}

.ph-cell-signal-x-fill::before {
	content: '\fb53';
}

.ph-chalkboard-fill::before {
	content: '\fb54';
}

.ph-chalkboard-simple-fill::before {
	content: '\fb55';
}

.ph-chalkboard-teacher-fill::before {
	content: '\fb56';
}

.ph-chart-bar-fill::before {
	content: '\fb57';
}

.ph-chart-bar-horizontal-fill::before {
	content: '\fb58';
}

.ph-chart-line-fill::before {
	content: '\fb59';
}

.ph-chart-line-up-fill::before {
	content: '\fb5a';
}

.ph-chart-pie-fill::before {
	content: '\fb5b';
}

.ph-chart-pie-slice-fill::before {
	content: '\fb5c';
}

.ph-chat-centered-dots-fill::before {
	content: '\fb5d';
}

.ph-chat-centered-fill::before {
	content: '\fb5e';
}

.ph-chat-centered-text-fill::before {
	content: '\fb5f';
}

.ph-chat-circle-dots-fill::before {
	content: '\fb60';
}

.ph-chat-circle-fill::before {
	content: '\fb61';
}

.ph-chat-circle-text-fill::before {
	content: '\fb62';
}

.ph-chat-dots-fill::before {
	content: '\fb63';
}

.ph-chat-fill::before {
	content: '\fb64';
}

.ph-chat-teardrop-dots-fill::before {
	content: '\fb65';
}

.ph-chat-teardrop-fill::before {
	content: '\fb66';
}

.ph-chat-teardrop-text-fill::before {
	content: '\fb67';
}

.ph-chat-text-fill::before {
	content: '\fb68';
}

.ph-chats-circle-fill::before {
	content: '\fb69';
}

.ph-chats-fill::before {
	content: '\fb6a';
}

.ph-chats-teardrop-fill::before {
	content: '\fb6b';
}

.ph-check-circle-fill::before {
	content: '\fb6c';
}

.ph-check-fill::before {
	content: '\fb6d';
}

.ph-check-square-fill::before {
	content: '\fb6e';
}

.ph-check-square-offset-fill::before {
	content: '\fb6f';
}

.ph-checks-fill::before {
	content: '\fb70';
}

.ph-circle-dashed-fill::before {
	content: '\fb71';
}

.ph-circle-fill::before {
	content: '\fb72';
}

.ph-circle-half-fill::before {
	content: '\fb73';
}

.ph-circle-half-tilt-fill::before {
	content: '\fb74';
}

.ph-circle-notch-fill::before {
	content: '\fb75';
}

.ph-circle-wavy-check-fill::before {
	content: '\fb76';
}

.ph-circle-wavy-fill::before {
	content: '\fb77';
}

.ph-circle-wavy-question-fill::before {
	content: '\fb78';
}

.ph-circle-wavy-warning-fill::before {
	content: '\fb79';
}

.ph-circles-four-fill::before {
	content: '\fb7a';
}

.ph-circles-three-fill::before {
	content: '\fb7b';
}

.ph-circles-three-plus-fill::before {
	content: '\fb7c';
}

.ph-clipboard-fill::before {
	content: '\fb7d';
}

.ph-clipboard-text-fill::before {
	content: '\fb7e';
}

.ph-clock-afternoon-fill::before {
	content: '\fb7f';
}

.ph-clock-clockwise-fill::before {
	content: '\fb80';
}

.ph-clock-counter-clockwise-fill::before {
	content: '\fb81';
}

.ph-clock-fill::before {
	content: '\fb82';
}

.ph-closed-captioning-fill::before {
	content: '\fb83';
}

.ph-cloud-arrow-down-fill::before {
	content: '\fb84';
}

.ph-cloud-arrow-up-fill::before {
	content: '\fb85';
}

.ph-cloud-check-fill::before {
	content: '\fb86';
}

.ph-cloud-fill::before {
	content: '\fb87';
}

.ph-cloud-fog-fill::before {
	content: '\fb88';
}

.ph-cloud-lightning-fill::before {
	content: '\fb89';
}

.ph-cloud-moon-fill::before {
	content: '\fb8a';
}

.ph-cloud-rain-fill::before {
	content: '\fb8b';
}

.ph-cloud-slash-fill::before {
	content: '\fb8c';
}

.ph-cloud-snow-fill::before {
	content: '\fb8d';
}

.ph-cloud-sun-fill::before {
	content: '\fb8e';
}

.ph-club-fill::before {
	content: '\fb8f';
}

.ph-coat-hanger-fill::before {
	content: '\fb90';
}

.ph-code-fill::before {
	content: '\fb91';
}

.ph-code-simple-fill::before {
	content: '\fb92';
}

.ph-codepen-logo-fill::before {
	content: '\fb93';
}

.ph-codesandbox-logo-fill::before {
	content: '\fb94';
}

.ph-coffee-fill::before {
	content: '\fb95';
}

.ph-coin-fill::before {
	content: '\fb96';
}

.ph-coin-vertical-fill::before {
	content: '\fb97';
}

.ph-coins-fill::before {
	content: '\fb98';
}

.ph-columns-fill::before {
	content: '\fb99';
}

.ph-command-fill::before {
	content: '\fb9a';
}

.ph-compass-fill::before {
	content: '\fb9b';
}

.ph-computer-tower-fill::before {
	content: '\fb9c';
}

.ph-confetti-fill::before {
	content: '\fb9d';
}

.ph-cookie-fill::before {
	content: '\fb9e';
}

.ph-cooking-pot-fill::before {
	content: '\fb9f';
}

.ph-copy-fill::before {
	content: '\fba0';
}

.ph-copy-simple-fill::before {
	content: '\fba1';
}

.ph-copyleft-fill::before {
	content: '\fba2';
}

.ph-copyright-fill::before {
	content: '\fba3';
}

.ph-corners-in-fill::before {
	content: '\fba4';
}

.ph-corners-out-fill::before {
	content: '\fba5';
}

.ph-cpu-fill::before {
	content: '\fba6';
}

.ph-credit-card-fill::before {
	content: '\fba7';
}

.ph-crop-fill::before {
	content: '\fba8';
}

.ph-crosshair-fill::before {
	content: '\fba9';
}

.ph-crosshair-simple-fill::before {
	content: '\fbaa';
}

.ph-crown-fill::before {
	content: '\fbab';
}

.ph-crown-simple-fill::before {
	content: '\fbac';
}

.ph-cube-fill::before {
	content: '\fbad';
}

.ph-currency-btc-fill::before {
	content: '\fbae';
}

.ph-currency-circle-dollar-fill::before {
	content: '\fbaf';
}

.ph-currency-cny-fill::before {
	content: '\fbb0';
}

.ph-currency-dollar-fill::before {
	content: '\fbb1';
}

.ph-currency-dollar-simple-fill::before {
	content: '\fbb2';
}

.ph-currency-eth-fill::before {
	content: '\fbb3';
}

.ph-currency-eur-fill::before {
	content: '\fbb4';
}

.ph-currency-gbp-fill::before {
	content: '\fbb5';
}

.ph-currency-inr-fill::before {
	content: '\fbb6';
}

.ph-currency-jpy-fill::before {
	content: '\fbb7';
}

.ph-currency-krw-fill::before {
	content: '\fbb8';
}

.ph-currency-kzt-fill::before {
	content: '\fbb9';
}

.ph-currency-ngn-fill::before {
	content: '\fbba';
}

.ph-currency-rub-fill::before {
	content: '\fbbb';
}

.ph-cursor-fill::before {
	content: '\fbbc';
}

.ph-cursor-text-fill::before {
	content: '\fbbd';
}

.ph-cylinder-fill::before {
	content: '\fbbe';
}

.ph-database-fill::before {
	content: '\fbbf';
}

.ph-desktop-fill::before {
	content: '\fbc0';
}

.ph-desktop-tower-fill::before {
	content: '\fbc1';
}

.ph-detective-fill::before {
	content: '\fbc2';
}

.ph-device-mobile-camera-fill::before {
	content: '\fbc3';
}

.ph-device-mobile-fill::before {
	content: '\fbc4';
}

.ph-device-mobile-speaker-fill::before {
	content: '\fbc5';
}

.ph-device-tablet-camera-fill::before {
	content: '\fbc6';
}

.ph-device-tablet-fill::before {
	content: '\fbc7';
}

.ph-device-tablet-speaker-fill::before {
	content: '\fbc8';
}

.ph-diamond-fill::before {
	content: '\fbc9';
}

.ph-diamonds-four-fill::before {
	content: '\fbca';
}

.ph-dice-five-fill::before {
	content: '\fbcb';
}

.ph-dice-four-fill::before {
	content: '\fbcc';
}

.ph-dice-one-fill::before {
	content: '\fbcd';
}

.ph-dice-six-fill::before {
	content: '\fbce';
}

.ph-dice-three-fill::before {
	content: '\fbcf';
}

.ph-dice-two-fill::before {
	content: '\fbd0';
}

.ph-disc-fill::before {
	content: '\fbd1';
}

.ph-discord-logo-fill::before {
	content: '\fbd2';
}

.ph-divide-fill::before {
	content: '\fbd3';
}

.ph-dog-fill::before {
	content: '\fbd4';
}

.ph-door-fill::before {
	content: '\fbd5';
}

.ph-dots-nine-fill::before {
	content: '\fbd6';
}

.ph-dots-six-fill::before {
	content: '\fbd7';
}

.ph-dots-six-vertical-fill::before {
	content: '\fbd8';
}

.ph-dots-three-circle-fill::before {
	content: '\fbd9';
}

.ph-dots-three-circle-vertical-fill::before {
	content: '\fbda';
}

.ph-dots-three-fill::before {
	content: '\fbdb';
}

.ph-dots-three-outline-fill::before {
	content: '\fbdc';
}

.ph-dots-three-outline-vertical-fill::before {
	content: '\fbdd';
}

.ph-dots-three-vertical-fill::before {
	content: '\fbde';
}

.ph-download-fill::before {
	content: '\fbdf';
}

.ph-download-simple-fill::before {
	content: '\fbe0';
}

.ph-dribbble-logo-fill::before {
	content: '\fbe1';
}

.ph-drop-fill::before {
	content: '\fbe2';
}

.ph-drop-half-bottom-fill::before {
	content: '\fbe3';
}

.ph-drop-half-fill::before {
	content: '\fbe4';
}

.ph-ear-fill::before {
	content: '\fbe5';
}

.ph-ear-slash-fill::before {
	content: '\fbe6';
}

.ph-egg-crack-fill::before {
	content: '\fbe7';
}

.ph-egg-fill::before {
	content: '\fbe8';
}

.ph-eject-fill::before {
	content: '\fbe9';
}

.ph-eject-simple-fill::before {
	content: '\fbea';
}

.ph-envelope-fill::before {
	content: '\fbeb';
}

.ph-envelope-open-fill::before {
	content: '\fbec';
}

.ph-envelope-simple-fill::before {
	content: '\fbed';
}

.ph-envelope-simple-open-fill::before {
	content: '\fbee';
}

.ph-equalizer-fill::before {
	content: '\fbef';
}

.ph-equals-fill::before {
	content: '\fbf0';
}

.ph-eraser-fill::before {
	content: '\fbf1';
}

.ph-exam-fill::before {
	content: '\fbf2';
}

.ph-export-fill::before {
	content: '\fbf3';
}

.ph-eye-closed-fill::before {
	content: '\fbf4';
}

.ph-eye-fill::before {
	content: '\fbf5';
}

.ph-eye-slash-fill::before {
	content: '\fbf6';
}

.ph-eyedropper-fill::before {
	content: '\fbf7';
}

.ph-eyedropper-sample-fill::before {
	content: '\fbf8';
}

.ph-eyeglasses-fill::before {
	content: '\fbf9';
}

.ph-face-mask-fill::before {
	content: '\fbfa';
}

.ph-facebook-logo-fill::before {
	content: '\fbfb';
}

.ph-factory-fill::before {
	content: '\fbfc';
}

.ph-faders-fill::before {
	content: '\fbfd';
}

.ph-faders-horizontal-fill::before {
	content: '\fbfe';
}

.ph-fast-forward-circle-fill::before {
	content: '\fbff';
}

.ph-fast-forward-fill::before {
	content: '\fc00';
}

.ph-figma-logo-fill::before {
	content: '\fc01';
}

.ph-file-arrow-down-fill::before {
	content: '\fc02';
}

.ph-file-arrow-up-fill::before {
	content: '\fc03';
}

.ph-file-audio-fill::before {
	content: '\fc04';
}

.ph-file-cloud-fill::before {
	content: '\fc05';
}

.ph-file-code-fill::before {
	content: '\fc06';
}

.ph-file-css-fill::before {
	content: '\fc07';
}

.ph-file-csv-fill::before {
	content: '\fc08';
}

.ph-file-doc-fill::before {
	content: '\fc09';
}

.ph-file-dotted-fill::before {
	content: '\fc0a';
}

.ph-file-fill::before {
	content: '\fc0b';
}

.ph-file-html-fill::before {
	content: '\fc0c';
}

.ph-file-image-fill::before {
	content: '\fc0d';
}

.ph-file-jpg-fill::before {
	content: '\fc0e';
}

.ph-file-js-fill::before {
	content: '\fc0f';
}

.ph-file-jsx-fill::before {
	content: '\fc10';
}

.ph-file-lock-fill::before {
	content: '\fc11';
}

.ph-file-minus-fill::before {
	content: '\fc12';
}

.ph-file-pdf-fill::before {
	content: '\fc13';
}

.ph-file-plus-fill::before {
	content: '\fc14';
}

.ph-file-png-fill::before {
	content: '\fc15';
}

.ph-file-ppt-fill::before {
	content: '\fc16';
}

.ph-file-rs-fill::before {
	content: '\fc17';
}

.ph-file-search-fill::before {
	content: '\fc18';
}

.ph-file-text-fill::before {
	content: '\fc19';
}

.ph-file-ts-fill::before {
	content: '\fc1a';
}

.ph-file-tsx-fill::before {
	content: '\fc1b';
}

.ph-file-video-fill::before {
	content: '\fc1c';
}

.ph-file-vue-fill::before {
	content: '\fc1d';
}

.ph-file-x-fill::before {
	content: '\fc1e';
}

.ph-file-xls-fill::before {
	content: '\fc1f';
}

.ph-file-zip-fill::before {
	content: '\fc20';
}

.ph-files-fill::before {
	content: '\fc21';
}

.ph-film-script-fill::before {
	content: '\fc22';
}

.ph-film-slate-fill::before {
	content: '\fc23';
}

.ph-film-strip-fill::before {
	content: '\fc24';
}

.ph-fingerprint-fill::before {
	content: '\fc25';
}

.ph-fingerprint-simple-fill::before {
	content: '\fc26';
}

.ph-finn-the-human-fill::before {
	content: '\fc27';
}

.ph-fire-fill::before {
	content: '\fc28';
}

.ph-fire-simple-fill::before {
	content: '\fc29';
}

.ph-first-aid-fill::before {
	content: '\fc2a';
}

.ph-first-aid-kit-fill::before {
	content: '\fc2b';
}

.ph-fish-fill::before {
	content: '\fc2c';
}

.ph-fish-simple-fill::before {
	content: '\fc2d';
}

.ph-flag-banner-fill::before {
	content: '\fc2e';
}

.ph-flag-checkered-fill::before {
	content: '\fc2f';
}

.ph-flag-fill::before {
	content: '\fc30';
}

.ph-flame-fill::before {
	content: '\fc31';
}

.ph-flashlight-fill::before {
	content: '\fc32';
}

.ph-flask-fill::before {
	content: '\fc33';
}

.ph-floppy-disk-back-fill::before {
	content: '\fc34';
}

.ph-floppy-disk-fill::before {
	content: '\fc35';
}

.ph-flow-arrow-fill::before {
	content: '\fc36';
}

.ph-flower-fill::before {
	content: '\fc37';
}

.ph-flower-lotus-fill::before {
	content: '\fc38';
}

.ph-flying-saucer-fill::before {
	content: '\fc39';
}

.ph-folder-dotted-fill::before {
	content: '\fc3a';
}

.ph-folder-fill::before {
	content: '\fc3b';
}

.ph-folder-lock-fill::before {
	content: '\fc3c';
}

.ph-folder-minus-fill::before {
	content: '\fc3d';
}

.ph-folder-notch-fill::before {
	content: '\fc3e';
}

.ph-folder-notch-minus-fill::before {
	content: '\fc3f';
}

.ph-folder-notch-open-fill::before {
	content: '\fc40';
}

.ph-folder-notch-plus-fill::before {
	content: '\fc41';
}

.ph-folder-open-fill::before {
	content: '\fc42';
}

.ph-folder-plus-fill::before {
	content: '\fc43';
}

.ph-folder-simple-dotted-fill::before {
	content: '\fc44';
}

.ph-folder-simple-fill::before {
	content: '\fc45';
}

.ph-folder-simple-lock-fill::before {
	content: '\fc46';
}

.ph-folder-simple-minus-fill::before {
	content: '\fc47';
}

.ph-folder-simple-plus-fill::before {
	content: '\fc48';
}

.ph-folder-simple-star-fill::before {
	content: '\fc49';
}

.ph-folder-simple-user-fill::before {
	content: '\fc4a';
}

.ph-folder-star-fill::before {
	content: '\fc4b';
}

.ph-folder-user-fill::before {
	content: '\fc4c';
}

.ph-folders-fill::before {
	content: '\fc4d';
}

.ph-football-fill::before {
	content: '\fc4e';
}

.ph-fork-knife-fill::before {
	content: '\fc4f';
}

.ph-frame-corners-fill::before {
	content: '\fc50';
}

.ph-framer-logo-fill::before {
	content: '\fc51';
}

.ph-function-fill::before {
	content: '\fc52';
}

.ph-funnel-fill::before {
	content: '\fc53';
}

.ph-funnel-simple-fill::before {
	content: '\fc54';
}

.ph-game-controller-fill::before {
	content: '\fc55';
}

.ph-gas-pump-fill::before {
	content: '\fc56';
}

.ph-gauge-fill::before {
	content: '\fc57';
}

.ph-gear-fill::before {
	content: '\fc58';
}

.ph-gear-six-fill::before {
	content: '\fc59';
}

.ph-gender-female-fill::before {
	content: '\fc5a';
}

.ph-gender-intersex-fill::before {
	content: '\fc5b';
}

.ph-gender-male-fill::before {
	content: '\fc5c';
}

.ph-gender-neuter-fill::before {
	content: '\fc5d';
}

.ph-gender-nonbinary-fill::before {
	content: '\fc5e';
}

.ph-gender-transgender-fill::before {
	content: '\fc5f';
}

.ph-ghost-fill::before {
	content: '\fc60';
}

.ph-gif-fill::before {
	content: '\fc61';
}

.ph-gift-fill::before {
	content: '\fc62';
}

.ph-git-branch-fill::before {
	content: '\fc63';
}

.ph-git-commit-fill::before {
	content: '\fc64';
}

.ph-git-diff-fill::before {
	content: '\fc65';
}

.ph-git-fork-fill::before {
	content: '\fc66';
}

.ph-git-merge-fill::before {
	content: '\fc67';
}

.ph-git-pull-request-fill::before {
	content: '\fc68';
}

.ph-github-logo-fill::before {
	content: '\fc69';
}

.ph-gitlab-logo-fill::before {
	content: '\fc6a';
}

.ph-gitlab-logo-simple-fill::before {
	content: '\fc6b';
}

.ph-globe-fill::before {
	content: '\fc6c';
}

.ph-globe-hemisphere-east-fill::before {
	content: '\fc6d';
}

.ph-globe-hemisphere-west-fill::before {
	content: '\fc6e';
}

.ph-globe-simple-fill::before {
	content: '\fc6f';
}

.ph-globe-stand-fill::before {
	content: '\fc70';
}

.ph-google-chrome-logo-fill::before {
	content: '\fc71';
}

.ph-google-logo-fill::before {
	content: '\fc72';
}

.ph-google-photos-logo-fill::before {
	content: '\fc73';
}

.ph-google-play-logo-fill::before {
	content: '\fc74';
}

.ph-google-podcasts-logo-fill::before {
	content: '\fc75';
}

.ph-gradient-fill::before {
	content: '\fc76';
}

.ph-graduation-cap-fill::before {
	content: '\fc77';
}

.ph-graph-fill::before {
	content: '\fc78';
}

.ph-grid-four-fill::before {
	content: '\fc79';
}

.ph-hamburger-fill::before {
	content: '\fc7a';
}

.ph-hand-eye-fill::before {
	content: '\fc7b';
}

.ph-hand-fill::before {
	content: '\fc7c';
}

.ph-hand-fist-fill::before {
	content: '\fc7d';
}

.ph-hand-grabbing-fill::before {
	content: '\fc7e';
}

.ph-hand-palm-fill::before {
	content: '\fc7f';
}

.ph-hand-pointing-fill::before {
	content: '\fc80';
}

.ph-hand-soap-fill::before {
	content: '\fc81';
}

.ph-hand-waving-fill::before {
	content: '\fc82';
}

.ph-handbag-fill::before {
	content: '\fc83';
}

.ph-handbag-simple-fill::before {
	content: '\fc84';
}

.ph-hands-clapping-fill::before {
	content: '\fc85';
}

.ph-handshake-fill::before {
	content: '\fc86';
}

.ph-hard-drive-fill::before {
	content: '\fc87';
}

.ph-hard-drives-fill::before {
	content: '\fc88';
}

.ph-hash-fill::before {
	content: '\fc89';
}

.ph-hash-straight-fill::before {
	content: '\fc8a';
}

.ph-headlights-fill::before {
	content: '\fc8b';
}

.ph-headphones-fill::before {
	content: '\fc8c';
}

.ph-headset-fill::before {
	content: '\fc8d';
}

.ph-heart-break-fill::before {
	content: '\fc8e';
}

.ph-heart-fill::before {
	content: '\fc8f';
}

.ph-heart-straight-break-fill::before {
	content: '\fc90';
}

.ph-heart-straight-fill::before {
	content: '\fc91';
}

.ph-heartbeat-fill::before {
	content: '\fc92';
}

.ph-hexagon-fill::before {
	content: '\fc93';
}

.ph-highlighter-circle-fill::before {
	content: '\fc94';
}

.ph-horse-fill::before {
	content: '\fc95';
}

.ph-hourglass-fill::before {
	content: '\fc96';
}

.ph-hourglass-high-fill::before {
	content: '\fc97';
}

.ph-hourglass-low-fill::before {
	content: '\fc98';
}

.ph-hourglass-medium-fill::before {
	content: '\fc99';
}

.ph-hourglass-simple-fill::before {
	content: '\fc9a';
}

.ph-hourglass-simple-high-fill::before {
	content: '\fc9b';
}

.ph-hourglass-simple-low-fill::before {
	content: '\fc9c';
}

.ph-hourglass-simple-medium-fill::before {
	content: '\fc9d';
}

.ph-house-fill::before {
	content: '\fc9e';
}

.ph-house-line-fill::before {
	content: '\fc9f';
}

.ph-house-simple-fill::before {
	content: '\fca0';
}

.ph-identification-badge-fill::before {
	content: '\fca1';
}

.ph-identification-card-fill::before {
	content: '\fca2';
}

.ph-image-fill::before {
	content: '\fca3';
}

.ph-image-square-fill::before {
	content: '\fca4';
}

.ph-infinity-fill::before {
	content: '\fca5';
}

.ph-info-fill::before {
	content: '\fca6';
}

.ph-instagram-logo-fill::before {
	content: '\fca7';
}

.ph-intersect-fill::before {
	content: '\fca8';
}

.ph-jeep-fill::before {
	content: '\fca9';
}

.ph-kanban-fill::before {
	content: '\fcaa';
}

.ph-key-fill::before {
	content: '\fcab';
}

.ph-key-return-fill::before {
	content: '\fcac';
}

.ph-keyboard-fill::before {
	content: '\fcad';
}

.ph-keyhole-fill::before {
	content: '\fcae';
}

.ph-knife-fill::before {
	content: '\fcaf';
}

.ph-ladder-fill::before {
	content: '\fcb0';
}

.ph-ladder-simple-fill::before {
	content: '\fcb1';
}

.ph-lamp-fill::before {
	content: '\fcb2';
}

.ph-laptop-fill::before {
	content: '\fcb3';
}

.ph-layout-fill::before {
	content: '\fcb4';
}

.ph-leaf-fill::before {
	content: '\fcb5';
}

.ph-lifebuoy-fill::before {
	content: '\fcb6';
}

.ph-lightbulb-filament-fill::before {
	content: '\fcb7';
}

.ph-lightbulb-fill::before {
	content: '\fcb8';
}

.ph-lightning-fill::before {
	content: '\fcb9';
}

.ph-lightning-slash-fill::before {
	content: '\fcba';
}

.ph-line-segment-fill::before {
	content: '\fcbb';
}

.ph-line-segments-fill::before {
	content: '\fcbc';
}

.ph-link-break-fill::before {
	content: '\fcbd';
}

.ph-link-fill::before {
	content: '\fcbe';
}

.ph-link-simple-break-fill::before {
	content: '\fcbf';
}

.ph-link-simple-fill::before {
	content: '\fcc0';
}

.ph-link-simple-horizontal-break-fill::before {
	content: '\fcc1';
}

.ph-link-simple-horizontal-fill::before {
	content: '\fcc2';
}

.ph-linkedin-logo-fill::before {
	content: '\fcc3';
}

.ph-linux-logo-fill::before {
	content: '\fcc4';
}

.ph-list-bullets-fill::before {
	content: '\fcc5';
}

.ph-list-checks-fill::before {
	content: '\fcc6';
}

.ph-list-dashes-fill::before {
	content: '\fcc7';
}

.ph-list-fill::before {
	content: '\fcc8';
}

.ph-list-numbers-fill::before {
	content: '\fcc9';
}

.ph-list-plus-fill::before {
	content: '\fcca';
}

.ph-lock-fill::before {
	content: '\fccb';
}

.ph-lock-key-fill::before {
	content: '\fccc';
}

.ph-lock-key-open-fill::before {
	content: '\fccd';
}

.ph-lock-laminated-fill::before {
	content: '\fcce';
}

.ph-lock-laminated-open-fill::before {
	content: '\fccf';
}

.ph-lock-open-fill::before {
	content: '\fcd0';
}

.ph-lock-simple-fill::before {
	content: '\fcd1';
}

.ph-lock-simple-open-fill::before {
	content: '\fcd2';
}

.ph-magic-wand-fill::before {
	content: '\fcd3';
}

.ph-magnet-fill::before {
	content: '\fcd4';
}

.ph-magnet-straight-fill::before {
	content: '\fcd5';
}

.ph-magnifying-glass-fill::before {
	content: '\fcd6';
}

.ph-magnifying-glass-minus-fill::before {
	content: '\fcd7';
}

.ph-magnifying-glass-plus-fill::before {
	content: '\fcd8';
}

.ph-map-pin-fill::before {
	content: '\fcd9';
}

.ph-map-pin-line-fill::before {
	content: '\fcda';
}

.ph-map-trifold-fill::before {
	content: '\fcdb';
}

.ph-marker-circle-fill::before {
	content: '\fcdc';
}

.ph-martini-fill::before {
	content: '\fcdd';
}

.ph-mask-happy-fill::before {
	content: '\fcde';
}

.ph-mask-sad-fill::before {
	content: '\fcdf';
}

.ph-math-operations-fill::before {
	content: '\fce0';
}

.ph-medal-fill::before {
	content: '\fce1';
}

.ph-medium-logo-fill::before {
	content: '\fce2';
}

.ph-megaphone-fill::before {
	content: '\fce3';
}

.ph-megaphone-simple-fill::before {
	content: '\fce4';
}

.ph-messenger-logo-fill::before {
	content: '\fce5';
}

.ph-microphone-fill::before {
	content: '\fce6';
}

.ph-microphone-slash-fill::before {
	content: '\fce7';
}

.ph-microphone-stage-fill::before {
	content: '\fce8';
}

.ph-microsoft-excel-logo-fill::before {
	content: '\fce9';
}

.ph-microsoft-powerpoint-logo-fill::before {
	content: '\fcea';
}

.ph-microsoft-teams-logo-fill::before {
	content: '\fceb';
}

.ph-microsoft-word-logo-fill::before {
	content: '\fcec';
}

.ph-minus-circle-fill::before {
	content: '\fced';
}

.ph-minus-fill::before {
	content: '\fcee';
}

.ph-money-fill::before {
	content: '\fcef';
}

.ph-monitor-fill::before {
	content: '\fcf0';
}

.ph-monitor-play-fill::before {
	content: '\fcf1';
}

.ph-moon-fill::before {
	content: '\fcf2';
}

.ph-moon-stars-fill::before {
	content: '\fcf3';
}

.ph-mountains-fill::before {
	content: '\fcf4';
}

.ph-mouse-fill::before {
	content: '\fcf5';
}

.ph-mouse-simple-fill::before {
	content: '\fcf6';
}

.ph-music-note-fill::before {
	content: '\fcf7';
}

.ph-music-note-simple-fill::before {
	content: '\fcf8';
}

.ph-music-notes-fill::before {
	content: '\fcf9';
}

.ph-music-notes-plus-fill::before {
	content: '\fcfa';
}

.ph-music-notes-simple-fill::before {
	content: '\fcfb';
}

.ph-navigation-arrow-fill::before {
	content: '\fcfc';
}

.ph-needle-fill::before {
	content: '\fcfd';
}

.ph-newspaper-clipping-fill::before {
	content: '\fcfe';
}

.ph-newspaper-fill::before {
	content: '\fcff';
}

.ph-note-blank-fill::before {
	content: '\fd00';
}

.ph-note-fill::before {
	content: '\fd01';
}

.ph-note-pencil-fill::before {
	content: '\fd02';
}

.ph-notebook-fill::before {
	content: '\fd03';
}

.ph-notepad-fill::before {
	content: '\fd04';
}

.ph-notification-fill::before {
	content: '\fd05';
}

.ph-number-circle-eight-fill::before {
	content: '\fd06';
}

.ph-number-circle-five-fill::before {
	content: '\fd07';
}

.ph-number-circle-four-fill::before {
	content: '\fd08';
}

.ph-number-circle-nine-fill::before {
	content: '\fd09';
}

.ph-number-circle-one-fill::before {
	content: '\fd0a';
}

.ph-number-circle-seven-fill::before {
	content: '\fd0b';
}

.ph-number-circle-six-fill::before {
	content: '\fd0c';
}

.ph-number-circle-three-fill::before {
	content: '\fd0d';
}

.ph-number-circle-two-fill::before {
	content: '\fd0e';
}

.ph-number-circle-zero-fill::before {
	content: '\fd0f';
}

.ph-number-eight-fill::before {
	content: '\fd10';
}

.ph-number-five-fill::before {
	content: '\fd11';
}

.ph-number-four-fill::before {
	content: '\fd12';
}

.ph-number-nine-fill::before {
	content: '\fd13';
}

.ph-number-one-fill::before {
	content: '\fd14';
}

.ph-number-seven-fill::before {
	content: '\fd15';
}

.ph-number-six-fill::before {
	content: '\fd16';
}

.ph-number-square-eight-fill::before {
	content: '\fd17';
}

.ph-number-square-five-fill::before {
	content: '\fd18';
}

.ph-number-square-four-fill::before {
	content: '\fd19';
}

.ph-number-square-nine-fill::before {
	content: '\fd1a';
}

.ph-number-square-one-fill::before {
	content: '\fd1b';
}

.ph-number-square-seven-fill::before {
	content: '\fd1c';
}

.ph-number-square-six-fill::before {
	content: '\fd1d';
}

.ph-number-square-three-fill::before {
	content: '\fd1e';
}

.ph-number-square-two-fill::before {
	content: '\fd1f';
}

.ph-number-square-zero-fill::before {
	content: '\fd20';
}

.ph-number-three-fill::before {
	content: '\fd21';
}

.ph-number-two-fill::before {
	content: '\fd22';
}

.ph-number-zero-fill::before {
	content: '\fd23';
}

.ph-nut-fill::before {
	content: '\fd24';
}

.ph-ny-times-logo-fill::before {
	content: '\fd25';
}

.ph-octagon-fill::before {
	content: '\fd26';
}

.ph-option-fill::before {
	content: '\fd27';
}

.ph-package-fill::before {
	content: '\fd28';
}

.ph-paint-brush-broad-fill::before {
	content: '\fd29';
}

.ph-paint-brush-fill::before {
	content: '\fd2a';
}

.ph-paint-brush-household-fill::before {
	content: '\fd2b';
}

.ph-paint-bucket-fill::before {
	content: '\fd2c';
}

.ph-paint-roller-fill::before {
	content: '\fd2d';
}

.ph-palette-fill::before {
	content: '\fd2e';
}

.ph-paper-plane-fill::before {
	content: '\fd2f';
}

.ph-paper-plane-right-fill::before {
	content: '\fd30';
}

.ph-paper-plane-tilt-fill::before {
	content: '\fd31';
}

.ph-paperclip-fill::before {
	content: '\fd32';
}

.ph-paperclip-horizontal-fill::before {
	content: '\fd33';
}

.ph-parachute-fill::before {
	content: '\fd34';
}

.ph-password-fill::before {
	content: '\fd35';
}

.ph-path-fill::before {
	content: '\fd36';
}

.ph-pause-circle-fill::before {
	content: '\fd37';
}

.ph-pause-fill::before {
	content: '\fd38';
}

.ph-paw-print-fill::before {
	content: '\fd39';
}

.ph-peace-fill::before {
	content: '\fd3a';
}

.ph-pen-fill::before {
	content: '\fd3b';
}

.ph-pen-nib-fill::before {
	content: '\fd3c';
}

.ph-pen-nib-straight-fill::before {
	content: '\fd3d';
}

.ph-pencil-circle-fill::before {
	content: '\fd3e';
}

.ph-pencil-fill::before {
	content: '\fd3f';
}

.ph-pencil-line-fill::before {
	content: '\fd40';
}

.ph-pencil-simple-fill::before {
	content: '\fd41';
}

.ph-pencil-simple-line-fill::before {
	content: '\fd42';
}

.ph-percent-fill::before {
	content: '\fd43';
}

.ph-person-fill::before {
	content: '\fd44';
}

.ph-person-simple-fill::before {
	content: '\fd45';
}

.ph-person-simple-run-fill::before {
	content: '\fd46';
}

.ph-person-simple-walk-fill::before {
	content: '\fd47';
}

.ph-perspective-fill::before {
	content: '\fd48';
}

.ph-phone-call-fill::before {
	content: '\fd49';
}

.ph-phone-disconnect-fill::before {
	content: '\fd4a';
}

.ph-phone-fill::before {
	content: '\fd4b';
}

.ph-phone-incoming-fill::before {
	content: '\fd4c';
}

.ph-phone-outgoing-fill::before {
	content: '\fd4d';
}

.ph-phone-slash-fill::before {
	content: '\fd4e';
}

.ph-phone-x-fill::before {
	content: '\fd4f';
}

.ph-phosphor-logo-fill::before {
	content: '\fd50';
}

.ph-piano-keys-fill::before {
	content: '\fd51';
}

.ph-picture-in-picture-fill::before {
	content: '\fd52';
}

.ph-pill-fill::before {
	content: '\fd53';
}

.ph-pinterest-logo-fill::before {
	content: '\fd54';
}

.ph-pinwheel-fill::before {
	content: '\fd55';
}

.ph-pizza-fill::before {
	content: '\fd56';
}

.ph-placeholder-fill::before {
	content: '\fd57';
}

.ph-planet-fill::before {
	content: '\fd58';
}

.ph-play-circle-fill::before {
	content: '\fd59';
}

.ph-play-fill::before {
	content: '\fd5a';
}

.ph-playlist-fill::before {
	content: '\fd5b';
}

.ph-plug-fill::before {
	content: '\fd5c';
}

.ph-plugs-connected-fill::before {
	content: '\fd5d';
}

.ph-plugs-fill::before {
	content: '\fd5e';
}

.ph-plus-circle-fill::before {
	content: '\fd5f';
}

.ph-plus-fill::before {
	content: '\fd60';
}

.ph-plus-minus-fill::before {
	content: '\fd61';
}

.ph-poker-chip-fill::before {
	content: '\fd62';
}

.ph-police-car-fill::before {
	content: '\fd63';
}

.ph-polygon-fill::before {
	content: '\fd64';
}

.ph-popcorn-fill::before {
	content: '\fd65';
}

.ph-power-fill::before {
	content: '\fd66';
}

.ph-prescription-fill::before {
	content: '\fd67';
}

.ph-presentation-chart-fill::before {
	content: '\fd68';
}

.ph-presentation-fill::before {
	content: '\fd69';
}

.ph-printer-fill::before {
	content: '\fd6a';
}

.ph-prohibit-fill::before {
	content: '\fd6b';
}

.ph-prohibit-inset-fill::before {
	content: '\fd6c';
}

.ph-projector-screen-chart-fill::before {
	content: '\fd6d';
}

.ph-projector-screen-fill::before {
	content: '\fd6e';
}

.ph-push-pin-fill::before {
	content: '\fd6f';
}

.ph-push-pin-simple-fill::before {
	content: '\fd70';
}

.ph-push-pin-simple-slash-fill::before {
	content: '\fd71';
}

.ph-push-pin-slash-fill::before {
	content: '\fd72';
}

.ph-puzzle-piece-fill::before {
	content: '\fd73';
}

.ph-qr-code-fill::before {
	content: '\fd74';
}

.ph-question-fill::before {
	content: '\fd75';
}

.ph-queue-fill::before {
	content: '\fd76';
}

.ph-quotes-fill::before {
	content: '\fd77';
}

.ph-radical-fill::before {
	content: '\fd78';
}

.ph-radio-button-fill::before {
	content: '\fd79';
}

.ph-radio-fill::before {
	content: '\fd7a';
}

.ph-rainbow-cloud-fill::before {
	content: '\fd7b';
}

.ph-rainbow-fill::before {
	content: '\fd7c';
}

.ph-receipt-fill::before {
	content: '\fd7d';
}

.ph-record-fill::before {
	content: '\fd7e';
}

.ph-rectangle-fill::before {
	content: '\fd7f';
}

.ph-recycle-fill::before {
	content: '\fd80';
}

.ph-reddit-logo-fill::before {
	content: '\fd81';
}

.ph-repeat-fill::before {
	content: '\fd82';
}

.ph-repeat-once-fill::before {
	content: '\fd83';
}

.ph-rewind-circle-fill::before {
	content: '\fd84';
}

.ph-rewind-fill::before {
	content: '\fd85';
}

.ph-robot-fill::before {
	content: '\fd86';
}

.ph-rocket-fill::before {
	content: '\fd87';
}

.ph-rocket-launch-fill::before {
	content: '\fd88';
}

.ph-rows-fill::before {
	content: '\fd89';
}

.ph-rss-fill::before {
	content: '\fd8a';
}

.ph-rss-simple-fill::before {
	content: '\fd8b';
}

.ph-rug-fill::before {
	content: '\fd8c';
}

.ph-ruler-fill::before {
	content: '\fd8d';
}

.ph-scales-fill::before {
	content: '\fd8e';
}

.ph-scan-fill::before {
	content: '\fd8f';
}

.ph-scissors-fill::before {
	content: '\fd90';
}

.ph-screencast-fill::before {
	content: '\fd91';
}

.ph-scribble-loop-fill::before {
	content: '\fd92';
}

.ph-scroll-fill::before {
	content: '\fd93';
}

.ph-selection-all-fill::before {
	content: '\fd94';
}

.ph-selection-background-fill::before {
	content: '\fd95';
}

.ph-selection-fill::before {
	content: '\fd96';
}

.ph-selection-foreground-fill::before {
	content: '\fd97';
}

.ph-selection-inverse-fill::before {
	content: '\fd98';
}

.ph-selection-plus-fill::before {
	content: '\fd99';
}

.ph-selection-slash-fill::before {
	content: '\fd9a';
}

.ph-share-fill::before {
	content: '\fd9b';
}

.ph-share-network-fill::before {
	content: '\fd9c';
}

.ph-shield-check-fill::before {
	content: '\fd9d';
}

.ph-shield-checkered-fill::before {
	content: '\fd9e';
}

.ph-shield-chevron-fill::before {
	content: '\fd9f';
}

.ph-shield-fill::before {
	content: '\fda0';
}

.ph-shield-plus-fill::before {
	content: '\fda1';
}

.ph-shield-slash-fill::before {
	content: '\fda2';
}

.ph-shield-star-fill::before {
	content: '\fda3';
}

.ph-shield-warning-fill::before {
	content: '\fda4';
}

.ph-shopping-bag-fill::before {
	content: '\fda5';
}

.ph-shopping-bag-open-fill::before {
	content: '\fda6';
}

.ph-shopping-cart-fill::before {
	content: '\fda7';
}

.ph-shopping-cart-simple-fill::before {
	content: '\fda8';
}

.ph-shower-fill::before {
	content: '\fda9';
}

.ph-shuffle-angular-fill::before {
	content: '\fdaa';
}

.ph-shuffle-fill::before {
	content: '\fdab';
}

.ph-shuffle-simple-fill::before {
	content: '\fdac';
}

.ph-sidebar-fill::before {
	content: '\fdad';
}

.ph-sidebar-simple-fill::before {
	content: '\fdae';
}

.ph-sign-in-fill::before {
	content: '\fdaf';
}

.ph-sign-out-fill::before {
	content: '\fdb0';
}

.ph-signpost-fill::before {
	content: '\fdb1';
}

.ph-sim-card-fill::before {
	content: '\fdb2';
}

.ph-sketch-logo-fill::before {
	content: '\fdb3';
}

.ph-skip-back-circle-fill::before {
	content: '\fdb4';
}

.ph-skip-back-fill::before {
	content: '\fdb5';
}

.ph-skip-forward-circle-fill::before {
	content: '\fdb6';
}

.ph-skip-forward-fill::before {
	content: '\fdb7';
}

.ph-skull-fill::before {
	content: '\fdb8';
}

.ph-slack-logo-fill::before {
	content: '\fdb9';
}

.ph-sliders-fill::before {
	content: '\fdba';
}

.ph-sliders-horizontal-fill::before {
	content: '\fdbb';
}

.ph-smiley-blank-fill::before {
	content: '\fdbc';
}

.ph-smiley-fill::before {
	content: '\fdbd';
}

.ph-smiley-meh-fill::before {
	content: '\fdbe';
}

.ph-smiley-nervous-fill::before {
	content: '\fdbf';
}

.ph-smiley-sad-fill::before {
	content: '\fdc0';
}

.ph-smiley-sticker-fill::before {
	content: '\fdc1';
}

.ph-smiley-wink-fill::before {
	content: '\fdc2';
}

.ph-smiley-x-eyes-fill::before {
	content: '\fdc3';
}

.ph-snapchat-logo-fill::before {
	content: '\fdc4';
}

.ph-snowflake-fill::before {
	content: '\fdc5';
}

.ph-soccer-ball-fill::before {
	content: '\fdc6';
}

.ph-sort-ascending-fill::before {
	content: '\fdc7';
}

.ph-sort-descending-fill::before {
	content: '\fdc8';
}

.ph-spade-fill::before {
	content: '\fdc9';
}

.ph-sparkle-fill::before {
	content: '\fdca';
}

.ph-speaker-high-fill::before {
	content: '\fdcb';
}

.ph-speaker-low-fill::before {
	content: '\fdcc';
}

.ph-speaker-none-fill::before {
	content: '\fdcd';
}

.ph-speaker-simple-high-fill::before {
	content: '\fdce';
}

.ph-speaker-simple-low-fill::before {
	content: '\fdcf';
}

.ph-speaker-simple-none-fill::before {
	content: '\fdd0';
}

.ph-speaker-simple-slash-fill::before {
	content: '\fdd1';
}

.ph-speaker-simple-x-fill::before {
	content: '\fdd2';
}

.ph-speaker-slash-fill::before {
	content: '\fdd3';
}

.ph-speaker-x-fill::before {
	content: '\fdd4';
}

.ph-spinner-fill::before {
	content: '\fdd5';
}

.ph-spinner-gap-fill::before {
	content: '\fdd6';
}

.ph-spiral-fill::before {
	content: '\fdd7';
}

.ph-spotify-logo-fill::before {
	content: '\fdd8';
}

.ph-square-fill::before {
	content: '\fdd9';
}

.ph-square-half-bottom-fill::before {
	content: '\fdda';
}

.ph-square-half-fill::before {
	content: '\fddb';
}

.ph-square-logo-fill::before {
	content: '\fddc';
}

.ph-squares-four-fill::before {
	content: '\fddd';
}

.ph-stack-fill::before {
	content: '\fdde';
}

.ph-stack-overflow-logo-fill::before {
	content: '\fddf';
}

.ph-stack-simple-fill::before {
	content: '\fde0';
}

.ph-stamp-fill::before {
	content: '\fde1';
}

.ph-star-fill::before {
	content: '\fde2';
}

.ph-star-four-fill::before {
	content: '\fde3';
}

.ph-star-half-fill::before {
	content: '\fde4';
}

.ph-sticker-fill::before {
	content: '\fde5';
}

.ph-stop-circle-fill::before {
	content: '\fde6';
}

.ph-stop-fill::before {
	content: '\fde7';
}

.ph-storefront-fill::before {
	content: '\fde8';
}

.ph-strategy-fill::before {
	content: '\fde9';
}

.ph-stripe-logo-fill::before {
	content: '\fdea';
}

.ph-student-fill::before {
	content: '\fdeb';
}

.ph-suitcase-fill::before {
	content: '\fdec';
}

.ph-suitcase-simple-fill::before {
	content: '\fded';
}

.ph-sun-dim-fill::before {
	content: '\fdee';
}

.ph-sun-fill::before {
	content: '\fdef';
}

.ph-sun-horizon-fill::before {
	content: '\fdf0';
}

.ph-sunglasses-fill::before {
	content: '\fdf1';
}

.ph-swap-fill::before {
	content: '\fdf2';
}

.ph-swatches-fill::before {
	content: '\fdf3';
}

.ph-sword-fill::before {
	content: '\fdf4';
}

.ph-syringe-fill::before {
	content: '\fdf5';
}

.ph-t-shirt-fill::before {
	content: '\fdf6';
}

.ph-table-fill::before {
	content: '\fdf7';
}

.ph-tabs-fill::before {
	content: '\fdf8';
}

.ph-tag-chevron-fill::before {
	content: '\fdf9';
}

.ph-tag-fill::before {
	content: '\fdfa';
}

.ph-tag-simple-fill::before {
	content: '\fdfb';
}

.ph-target-fill::before {
	content: '\fdfc';
}

.ph-taxi-fill::before {
	content: '\fdfd';
}

.ph-telegram-logo-fill::before {
	content: '\fdfe';
}

.ph-television-fill::before {
	content: '\fdff';
}

.ph-television-simple-fill::before {
	content: '\fe00';
}

.ph-tennis-ball-fill::before {
	content: '\fe01';
}

.ph-terminal-fill::before {
	content: '\fe02';
}

.ph-terminal-window-fill::before {
	content: '\fe03';
}

.ph-test-tube-fill::before {
	content: '\fe04';
}

.ph-text-aa-fill::before {
	content: '\fe05';
}

.ph-text-align-center-fill::before {
	content: '\fe06';
}

.ph-text-align-justify-fill::before {
	content: '\fe07';
}

.ph-text-align-left-fill::before {
	content: '\fe08';
}

.ph-text-align-right-fill::before {
	content: '\fe09';
}

.ph-text-bolder-fill::before {
	content: '\fe0a';
}

.ph-text-h-fill::before {
	content: '\fe0b';
}

.ph-text-h-five-fill::before {
	content: '\fe0c';
}

.ph-text-h-four-fill::before {
	content: '\fe0d';
}

.ph-text-h-one-fill::before {
	content: '\fe0e';
}

.ph-text-h-six-fill::before {
	content: '\fe0f';
}

.ph-text-h-three-fill::before {
	content: '\fe10';
}

.ph-text-h-two-fill::before {
	content: '\fe11';
}

.ph-text-indent-fill::before {
	content: '\fe12';
}

.ph-text-italic-fill::before {
	content: '\fe13';
}

.ph-text-outdent-fill::before {
	content: '\fe14';
}

.ph-text-strikethrough-fill::before {
	content: '\fe15';
}

.ph-text-t-fill::before {
	content: '\fe16';
}

.ph-text-underline-fill::before {
	content: '\fe17';
}

.ph-textbox-fill::before {
	content: '\fe18';
}

.ph-thermometer-cold-fill::before {
	content: '\fe19';
}

.ph-thermometer-fill::before {
	content: '\fe1a';
}

.ph-thermometer-hot-fill::before {
	content: '\fe1b';
}

.ph-thermometer-simple-fill::before {
	content: '\fe1c';
}

.ph-thumbs-down-fill::before {
	content: '\fe1d';
}

.ph-thumbs-up-fill::before {
	content: '\fe1e';
}

.ph-ticket-fill::before {
	content: '\fe1f';
}

.ph-tiktok-logo-fill::before {
	content: '\fe20';
}

.ph-timer-fill::before {
	content: '\fe21';
}

.ph-toggle-left-fill::before {
	content: '\fe22';
}

.ph-toggle-right-fill::before {
	content: '\fe23';
}

.ph-toilet-fill::before {
	content: '\fe24';
}

.ph-toilet-paper-fill::before {
	content: '\fe25';
}

.ph-tote-fill::before {
	content: '\fe26';
}

.ph-tote-simple-fill::before {
	content: '\fe27';
}

.ph-trademark-registered-fill::before {
	content: '\fe28';
}

.ph-traffic-cone-fill::before {
	content: '\fe29';
}

.ph-traffic-sign-fill::before {
	content: '\fe2a';
}

.ph-traffic-signal-fill::before {
	content: '\fe2b';
}

.ph-train-fill::before {
	content: '\fe2c';
}

.ph-train-regional-fill::before {
	content: '\fe2d';
}

.ph-train-simple-fill::before {
	content: '\fe2e';
}

.ph-translate-fill::before {
	content: '\fe2f';
}

.ph-trash-fill::before {
	content: '\fe30';
}

.ph-trash-simple-fill::before {
	content: '\fe31';
}

.ph-tray-fill::before {
	content: '\fe32';
}

.ph-tree-evergreen-fill::before {
	content: '\fe33';
}

.ph-tree-fill::before {
	content: '\fe34';
}

.ph-tree-structure-fill::before {
	content: '\fe35';
}

.ph-trend-down-fill::before {
	content: '\fe36';
}

.ph-trend-up-fill::before {
	content: '\fe37';
}

.ph-triangle-fill::before {
	content: '\fe38';
}

.ph-trophy-fill::before {
	content: '\fe39';
}

.ph-truck-fill::before {
	content: '\fe3a';
}

.ph-twitch-logo-fill::before {
	content: '\fe3b';
}

.ph-twitter-logo-fill::before {
	content: '\fe3c';
}

.ph-umbrella-fill::before {
	content: '\fe3d';
}

.ph-umbrella-simple-fill::before {
	content: '\fe3e';
}

.ph-upload-fill::before {
	content: '\fe3f';
}

.ph-upload-simple-fill::before {
	content: '\fe40';
}

.ph-user-circle-fill::before {
	content: '\fe41';
}

.ph-user-circle-gear-fill::before {
	content: '\fe42';
}

.ph-user-circle-minus-fill::before {
	content: '\fe43';
}

.ph-user-circle-plus-fill::before {
	content: '\fe44';
}

.ph-user-fill::before {
	content: '\fe45';
}

.ph-user-focus-fill::before {
	content: '\fe46';
}

.ph-user-gear-fill::before {
	content: '\fe47';
}

.ph-user-list-fill::before {
	content: '\fe48';
}

.ph-user-minus-fill::before {
	content: '\fe49';
}

.ph-user-plus-fill::before {
	content: '\fe4a';
}

.ph-user-rectangle-fill::before {
	content: '\fe4b';
}

.ph-user-square-fill::before {
	content: '\fe4c';
}

.ph-user-switch-fill::before {
	content: '\fe4d';
}

.ph-users-fill::before {
	content: '\fe4e';
}

.ph-users-four-fill::before {
	content: '\fe4f';
}

.ph-users-three-fill::before {
	content: '\fe50';
}

.ph-vault-fill::before {
	content: '\fe51';
}

.ph-vibrate-fill::before {
	content: '\fe52';
}

.ph-video-camera-fill::before {
	content: '\fe53';
}

.ph-video-camera-slash-fill::before {
	content: '\fe54';
}

.ph-vignette-fill::before {
	content: '\fe55';
}

.ph-voicemail-fill::before {
	content: '\fe56';
}

.ph-volleyball-fill::before {
	content: '\fe57';
}

.ph-wall-fill::before {
	content: '\fe58';
}

.ph-wallet-fill::before {
	content: '\fe59';
}

.ph-warning-circle-fill::before {
	content: '\fe5a';
}

.ph-warning-fill::before {
	content: '\fe5b';
}

.ph-warning-octagon-fill::before {
	content: '\fe5c';
}

.ph-watch-fill::before {
	content: '\fe5d';
}

.ph-wave-sawtooth-fill::before {
	content: '\fe5e';
}

.ph-wave-sine-fill::before {
	content: '\fe5f';
}

.ph-wave-square-fill::before {
	content: '\fe60';
}

.ph-wave-triangle-fill::before {
	content: '\fe61';
}

.ph-waves-fill::before {
	content: '\fe62';
}

.ph-webcam-fill::before {
	content: '\fe63';
}

.ph-whatsapp-logo-fill::before {
	content: '\fe64';
}

.ph-wheelchair-fill::before {
	content: '\fe65';
}

.ph-wifi-high-fill::before {
	content: '\fe66';
}

.ph-wifi-low-fill::before {
	content: '\fe67';
}

.ph-wifi-medium-fill::before {
	content: '\fe68';
}

.ph-wifi-none-fill::before {
	content: '\fe69';
}

.ph-wifi-slash-fill::before {
	content: '\fe6a';
}

.ph-wifi-x-fill::before {
	content: '\fe6b';
}

.ph-wind-fill::before {
	content: '\fe6c';
}

.ph-windows-logo-fill::before {
	content: '\fe6d';
}

.ph-wine-fill::before {
	content: '\fe6e';
}

.ph-wrench-fill::before {
	content: '\fe6f';
}

.ph-x-circle-fill::before {
	content: '\fe70';
}

.ph-x-fill::before {
	content: '\fe71';
}

.ph-x-square-fill::before {
	content: '\fe72';
}

.ph-yin-yang-fill::before {
	content: '\fe73';
}

.ph-youtube-logo-fill::before {
	content: '\fe74';
}
