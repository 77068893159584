@import '../../assets/scss/variables.scss';

.Loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 99999;
	background-color: rgba($color: $neutral-10, $alpha: 1);
	display: flex;
	justify-content: center;
	align-items: center;
}
