@import '../../../assets/scss/variables.scss';

.Slider {
	flex-basis: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	input[type='range'] {
		-webkit-appearance: none;
		width: 100%;
		height: 8px;
		background: $primary-bg;
		border-radius: 5px;
		border: 1px solid $primary-border;
		background-image: linear-gradient($primary-main, $primary-main);
		background-repeat: no-repeat;
	}

	input[type='range']::-webkit-slider-thumb {
		-webkit-appearance: none;
		height: 24px;
		width: 24px;
		border-radius: 50%;
		background: $primary-main;
		cursor: pointer;
		box-shadow: 0 0 2px 0 $neutral-30;
	}

	.label {
		color: $neutral-80;
		text-align: right;
		margin-bottom: 8px;
	}
}
