@import "../assets/scss/variables.scss";

.Dashboard {

  .Loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: $neutral-10;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    padding-left: 5%;
    padding-right: 5%;

    @media (max-width: 1279px) {
      height: auto;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .logo-wrapper {
      width: 8%;

      @media (max-width: 1279px) {
        width: 26%;
      }

      .logo {
        width: 100%;
      }
    }

    .buttons {
      display: flex;
      gap: 16px;
    }
  }

  .content {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-wrap: wrap;
    gap: 1.25%;
    margin-top: 30px;

    @media (max-width: 1279px) {
      justify-content: center;
      gap: 20px;
      margin-bottom: 20px;
    }

    .card {
      border: 1px solid $neutral-50;
      border-radius: 5px;
      padding: 15px;
      flex-basis: 32.5%;
      margin-bottom: 1.25%;

      @media (max-width: 1279px) {
        width: 100%;
        flex-basis: auto;
      }
    }

    .card-img {
      width: 20%;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .card-title {
      margin-top: 20px;
    }

    .card-buttons {
      display: flex;
      gap: 15px;
      margin-top: 20px;
    }
  }

  .dashboard-img-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dashboard-img {
    width: 30%;

    @media (max-width: 1279px) {
      width: 75%;
    }
  }

  .dashboard-empty {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      padding: 0 10px;
      text-align: center;
      margin-top: 30px;
    }
  }
}
