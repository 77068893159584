@import '../../../assets/scss/variables.scss';

.InfoIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px;
	margin-left: -10px;
	position: relative;

	.icon::before {
		font-size: 20px;
		color: $neutral-60;
	}

	.tooltip {
		visibility: hidden;
		width: 120px;
		background-color: #555;
		color: #fff;
		text-align: center;
		padding: 8px 0;
		border-radius: 6px;

		position: absolute;
		z-index: 1;
		bottom: 125%;
		left: 50%;
		margin-left: -60px;

		opacity: 0;
		transition: opacity 0.3s;
	}

	.tooltip::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: #555 transparent transparent transparent;
	}

	&:hover .tooltip {
		visibility: visible;
		opacity: 1;
	}
}
