/* Fonts */
@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-Bold.woff2') format('woff2'),
		url('./assets/fonts/Inter-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-SemiBold.woff2') format('woff2'),
		url('./assets/fonts/Inter-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-Medium.woff2') format('woff2'),
		url('./assets/fonts/Inter-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-Regular.woff2') format('woff2'),
		url('./assets/fonts/Inter-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: 'Inter', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
