@import '../assets/scss/variables.scss';

.Registration {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 1279px) {
		min-height: auto;
	}

	.form-box {
		padding: 40px;
		border-radius: 10px;
		width: 450px;
		background-color: $neutral-10;
		box-shadow: $shadow-8;

		@media (max-width: 1279px) {
			margin-top: 100px;
			width: 90%;
			padding-left: 30px;
			padding-right: 30px;
		}

		.form-inner {
			display: flex;
			flex-direction: column;
			gap: 20px;

			.reg-btn .textcontent {
				margin: 0 auto;
			}
		}

		.logo-wrapper {
			width: 35%;
			margin: 0 auto 20px auto;

			.logo {
				width: 100%;
			}
		}

		input {
			width: 100%;
			padding: 10px 12px;
			border-radius: 4px;
			border: 1px solid $neutral-50;
			color: $neutral-90;
			background-color: $primary-bg;

			&::placeholder {
				color: $neutral-70;
			}

			&:hover {
				border-color: $primary-hover;
			}
			&:focus,
			&:focus-visible {
				outline: 2px solid $primary-focus;
				border-color: $primary-focus;
			}
		}
		.label {
			margin-bottom: 4px;
			color: $neutral-90;
		}
	}
}
