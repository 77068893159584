@import '../../assets/scss/variables.scss';

.Form {
	background-color: $neutral-10;
	width: 100%;
	max-width: 100%;
	flex: 1 1 0%;
	height: 100%;

	.inner {
		flex: 1 1 0%;
		padding-left: 30px;
		padding-right: 30px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (max-width: 1279px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}
