@import '../../../assets/scss/variables.scss';

.Select {
	flex-basis: 50%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;

	&::after {
		position: absolute;
		bottom: 12px;
		right: 10px;
		font: normal normal normal 1em/1 'Phosphor';
		content: '\f31a';
		display: block;
		color: $neutral-70;
	}

	select {
		appearance: none;
		width: 100%;
		padding: 10px 12px;
		border-radius: 4px;
		border: 1px solid $neutral-50;
		color: $neutral-90;
		background-color: $primary-bg;

		&::-ms-expand {
			display: none;
		}

		&::placeholder {
			color: $neutral-70;
		}

		&:hover {
			border-color: $primary-hover;
		}
		&:focus,
		&:focus-visible {
			outline: 2px solid $primary-focus;
			border-color: $primary-focus;
		}

		option {
			padding: 24px;
		}
	}
	.label {
		margin-bottom: 4px;
		color: $neutral-90;
	}
}
