@import "../../assets/scss/variables.scss";

.AdditionalSections {
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 1279px) {
    margin-bottom: 100px;
  }

  .buttons-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 20px;
  }
}
