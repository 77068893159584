@import '../../../assets/scss/variables.scss';

.TextAreaField {
	flex-basis: 50%;

	textarea {
		width: 100%;
		padding: 10px 12px;
		border-radius: 4px;
		border: 1px solid $neutral-50;
		color: $neutral-90;
		background-color: $primary-bg;

		&::placeholder {
			color: $neutral-70;
		}

		&:hover {
			border-color: $primary-hover;
		}
		&:focus,
		&:focus-visible {
			outline: 2px solid $primary-focus;
			border-color: $primary-focus;
		}
	}
	.label {
		margin-bottom: 4px;
		color: $neutral-90;
	}
}
